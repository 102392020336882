
import { Component, Vue } from 'nuxt-property-decorator';
import { MessageReactionType } from '../../../services';

interface Reaction {
  class: string;
  type: MessageReactionType;
}

@Component({})
export default class ReactionPicker extends Vue {
  reactions: Reaction[] = [
    { class: 'twa-thumbs-up', type: MessageReactionType.ThumbsUp },
    { class: 'twa-thumbs-down', type: MessageReactionType.ThumbsDown },
    { class: 'twa-clapping-hands', type: MessageReactionType.ClappingHands },
    { class: 'twa-grinning-face-with-smiling-eyes', type: MessageReactionType.GrinningFaceWithBigEyes },
    { class: 'twa-face-with-tears-of-joy', type: MessageReactionType.FaceWithTearsOfJoy },
    { class: 'twa-face-with-open-mouth', type: MessageReactionType.FaceWithOpenMouth },
    { class: 'twa-red-question-mark', type: MessageReactionType.RedQuestionMark },
  ];
}
