
import { Component, Vue, Prop } from 'nuxt-property-decorator';

@Component({})
export default class ProgressSteps extends Vue {
  @Prop({ type: Array, required: true }) readonly steps!: string[];
  @Prop({ type: Number, required: true }) readonly currentStep!: number;

  get stepsCount() {
    return this.steps.length;
  }

  public getStepModifier(index: number) {
    return {
      '-active js-active-step': index === this.currentStep,
      '-completed': index < this.currentStep,
    };
  }
}
