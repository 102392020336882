import { Role, SettingsDto } from '../models/SettingsDto';

const organizerDashboardRoles = [Role.Organizer, Role.Chair, Role.Administrator];
const participantDashboardRoles = [Role.Participant, Role.Submitter, Role.Author];
const reviewerDashboardRoles = [Role.Reviewer];

function hasEventRole(settings: SettingsDto, eventId: string, checkupRoles: Role[]) {
  const eventRole = settings.userRoles.events.find(event => event.id === eventId);
  return eventRole?.roles.some(role => checkupRoles.includes(role)) || false;
}

export function hasPermission(settings: SettingsDto, eventId: string, role: Role) {
  const rolesToCheck = [role];
  if (role === Role.Chair) rolesToCheck.push(Role.Organizer);

  if (hasEventRole(settings, eventId, rolesToCheck)) return true;

  const organizationId = settings.eventOrganizationMapping[eventId];
  if (!organizationId) return false;
  return hasOrganizationRole(settings, organizationId, Role.Administrator);
}

export function hasOrganizationRole(settings: SettingsDto, organizationId: string, role: Role) {
  const rolesToCheck = [role];
  const organizationRole = settings.userRoles.organizations.find(org => org.id === organizationId);
  return organizationRole?.roles.some(role => rolesToCheck.includes(role)) || false;
}

export function isOrganizationMember(settings: SettingsDto, organizationId: string) {
  // check direct organization permission
  const organizationRole = settings.userRoles.organizations.find(({ id }) => id === organizationId);
  if (organizationRole && organizationRole.roles.includes(Role.Administrator)) return true;

  // check related event roles
  return Object.entries(settings.eventOrganizationMapping)
    .filter(([_, orgId]) => orgId === organizationId)
    .map(([eventId]) => eventId)
    .some(eventId => hasPermission(settings, eventId, Role.Organizer) || hasPermission(settings, eventId, Role.Chair));
}

export function isMemberOfAnyOrganization(settings: SettingsDto) {
  if (settings.userRoles.organizations.some(({ roles }) => roles.includes(Role.Administrator))) return true;

  const events = settings.userRoles.events.filter(
    ({ roles }) => roles.includes(Role.Organizer) || roles.includes(Role.Chair),
  );
  return events.some(({ id }) => settings.eventOrganizationMapping[id]);
}

export function getDefaultUserDashboardRole(settings: SettingsDto): Role {
  if (settings.userRoles.organizations.some(({ roles }) => roles.includes(Role.Administrator))) return Role.Organizer;

  if (settings.userRoles.events.some(({ roles }) => roles.some(role => organizerDashboardRoles.includes(role))))
    return Role.Organizer;
  if (settings.userRoles.events.some(({ roles }) => roles.some(role => participantDashboardRoles.includes(role))))
    return Role.Participant;
  if (settings.userRoles.events.some(({ roles }) => roles.some(role => reviewerDashboardRoles.includes(role))))
    return Role.Reviewer;

  return Role.Organizer;
}
