export type TrackColor = {
  id: string;
  bgColor: string;
  borderColor: string;
  textColor: string;
};

/*
 * (WARNING) Any changes will require back-end adaptation.
 */
export const trackColorsList: TrackColor[] = [
  {
    id: 'cyan-50',
    bgColor: 'rgb(236, 253, 255)',
    borderColor: 'rgb(207, 249, 254)',
    textColor: 'rgb(14, 112, 144)',
  },
  {
    id: 'blue-50',
    bgColor: 'rgb(239, 248, 255)',
    borderColor: 'rgb(209, 233, 255)',
    textColor: 'rgb(23, 92, 211)',
  },
  {
    id: 'indigo-50',
    bgColor: 'rgb(238, 244, 255)',
    borderColor: 'rgb(224, 234, 255)',
    textColor: 'rgb(53, 56, 205)',
  },
  {
    id: 'violet-50',
    bgColor: 'rgb(245, 243, 255)',
    borderColor: 'rgb(236, 233, 254)',
    textColor: 'rgb(105, 39, 218)',
  },
  {
    id: 'fuchsia-50',
    bgColor: 'rgb(253, 244, 255)',
    borderColor: 'rgb(251, 232, 255)',
    textColor: 'rgb(159, 26, 177)',
  },
  { id: 'pink-50', bgColor: 'rgb(253, 242, 250)', borderColor: 'rgb(252, 231, 246)', textColor: 'rgb(193, 21, 116)' },
  {
    id: 'orange-dark-50',
    bgColor: 'rgb(255, 244, 237)',
    borderColor: 'rgb(255, 230, 213)',
    textColor: 'rgb(188, 27, 6)',
  },
  {
    id: 'yellow-50',
    bgColor: 'rgb(254, 251, 232)',
    borderColor: 'rgb(254, 247, 195)',
    textColor: 'rgb(161, 92, 7)',
  },
  {
    id: 'green-50',
    bgColor: 'rgb(237, 252, 242)',
    borderColor: 'rgb(211, 248, 223)',
    textColor: 'rgb(8, 116, 67)',
  },
  {
    id: 'moss-50',
    bgColor: 'rgb(245, 251, 238)',
    borderColor: 'rgb(230, 244, 215)',
    textColor: 'rgb(63, 98, 26)',
  },
  {
    id: 'cyan-200',
    bgColor: 'rgb(165, 240, 252)',
    borderColor: 'rgb(103, 227, 249)',
    textColor: 'rgb(22, 76, 99)',
  },
  {
    id: 'blue-200',
    bgColor: 'rgb(178, 221, 255)',
    borderColor: 'rgb(132, 202, 255)',
    textColor: 'rgb(25, 65, 133)',
  },
  {
    id: 'indigo-200',
    bgColor: 'rgb(199, 215, 254)',
    borderColor: 'rgb(164, 188, 253)',
    textColor: 'rgb(45, 50, 130)',
  },
  {
    id: 'violet-200',
    bgColor: 'rgb(221, 214, 254)',
    borderColor: 'rgb(195, 181, 253)',
    textColor: 'rgb(73, 28, 150)',
  },
  {
    id: 'fuchsia-200',
    bgColor: 'rgb(246, 208, 254)',
    borderColor: 'rgb(238, 170, 253)',
    textColor: 'rgb(111, 24, 119)',
  },
  { id: 'pink-200', bgColor: 'rgb(252, 206, 238)', borderColor: 'rgb(250, 167, 224)', textColor: 'rgb(133, 22, 81)' },
  {
    id: 'orange-dark-200',
    bgColor: 'rgb(255, 214, 174)',
    borderColor: 'rgb(255, 184, 146)',
    textColor: 'rgb(119, 26, 13)',
  },
  {
    id: 'yellow-200',
    bgColor: 'rgb(254, 238, 149)',
    borderColor: 'rgb(253, 226, 114)',
    textColor: 'rgb(113, 59, 18)',
  },
  {
    id: 'moss-200',
    bgColor: 'rgb(206, 234, 176)',
    borderColor: 'rgb(172, 220, 121)',
    textColor: 'rgb(43, 66, 18)',
  },
  {
    id: 'green-200',
    bgColor: 'rgb(170, 240, 196)',
    borderColor: 'rgb(115, 226, 163)',
    textColor: 'rgb(8, 76, 46)',
  },
];
