
import { Editor } from '@tiptap/vue-2';
import { Component, Prop, Ref, Vue } from 'nuxt-property-decorator';
import { TiptapExtension, TiptapMenuButton } from '../../types';
import InputText from '../design-system/input/input-text/InputText.vue';
import TiptapDropdownButton from './TiptapDropdownButton.vue';

@Component({
  components: {
    TiptapDropdownButton,
    InputText,
  },
})
export default class TiptapVideoButton extends Vue {
  @Prop({ required: true }) readonly editor!: Editor;
  @Prop({ required: true }) readonly button!: TiptapMenuButton;

  @Ref() readonly tiptapDropdownButton?: TiptapDropdownButton;
  @Ref() readonly inputText?: InputText;

  readonly TiptapExtension = TiptapExtension;

  link = '';

  public applyLink() {
    if (!this.link) return this.clearLink();

    this.editor.chain().focus().extendMarkRange('link').setLink({ href: this.link }).run();
    this.tiptapDropdownButton?.closeMenu();
  }

  public clearLink() {
    this.editor.chain().focus().extendMarkRange('link').unsetLink().run();
    this.tiptapDropdownButton?.closeMenu();
  }

  public onShow() {
    this.link = this.editor.getAttributes('link').href || '';

    setTimeout(() => {
      this.inputText?.focus();
    }, 125);
  }
}
