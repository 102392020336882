
import { Component, Prop, Vue, Inject } from 'nuxt-property-decorator';
import { Validator } from 'vee-validate';
import {
  loadScript,
  type OnApproveActions,
  type OnApproveData,
  type OnCancelledActions,
  type PayPalButtonsComponent,
  type OnInitActions,
} from '@paypal/paypal-js';
import InputText from '../design-system/input/input-text/InputText.vue';
import { BillingInfoDto, isCivicAddressValid, isSuccess, isValidationError, QuoteDto } from '../../services';

@Component({ components: { InputText } })
export default class PayPalButtons extends Vue {
  @Inject() $validator!: Validator;

  @Prop({ type: String, required: true }) readonly paypalMerchantId!: string;
  @Prop({ type: String, required: true }) readonly currency!: string;
  @Prop({ type: Object, required: true }) readonly quote!: QuoteDto;
  @Prop({ type: String, required: true }) readonly eventId!: string;
  @Prop({ required: true }) billingInfo!: BillingInfoDto;

  buttonsContainer = 'paypal-button-container';
  buttons: PayPalButtonsComponent | null = null;
  orderId: string | null = null;

  async mounted() {
    const paypalNamespace = await loadScript({
      clientId: this.$config.paypalClientId,
      dataPartnerAttributionId: this.$config.paypalBnCode,
      environment: this.$config.paypalEnvironment,
      merchantId: this.paypalMerchantId,
      intent: 'capture',
      commit: false,
      vault: false,
      integrationDate: '2020-07-01',
      currency: this.currency,
      locale: this.$i18n.locale === 'en' ? 'en_US' : 'fr_CA',
      components: ['card-fields', 'buttons'],
    });

    if (!paypalNamespace || !paypalNamespace.Buttons) return;

    this.buttons = paypalNamespace.Buttons({
      style: {
        layout: 'vertical',
        color: 'gold',
        shape: 'rect',
        label: 'pay',
        height: 40,
      },
      onInit: (data, actions) => this.onInit(data, actions),
      onClick: () => this.onButtonClick(),
      createOrder: (data, actions) => this.createOrder(data, actions),
      onApprove: (data, actions) => this.onApprove(data, actions),
      onCancel: (data, actions) => this.onCancel(data, actions),
      onError: error => this.onError(error),
    });

    // necessary if you add and remove a 100% coupon code, the portal is not always in DOM
    setTimeout(() => this.buttons?.render(`#${this.buttonsContainer}`), 500);
  }

  public onInit(_: Record<string, unknown>, actions: OnInitActions) {
    if (isCivicAddressValid(this.billingInfo.civicAddress)) {
      actions.enable();
    } else {
      actions.disable();
    }

    this.$watch(
      'errors',
      (_, newErrors) => {
        if (!newErrors) return;
        if (this.errors.any()) actions.disable();
        else actions.enable();
      },
      { deep: true, immediate: true },
    );
  }

  public async createOrder(_: Record<string, unknown>, _2: Record<string, unknown>) {
    const response = await this.$api.createPaypalOrder({
      paypalMerchantId: this.paypalMerchantId,
      eventId: this.eventId,
      totalAmount: this.quote.total,
      currency: this.currency,
      source: 'buttons',
    });

    if (isSuccess(response)) {
      this.orderId = response;
      return response;
    } else if (isValidationError(response)) {
      this.feedErrorBag(response);
      return '';
    } else {
      this.$toast.error(this.$t('shared.error.server_validation.paypal.order_error') as string);
      return '';
    }
  }

  public onButtonClick() {
    this.$validator.validateAll();
  }

  public async onApprove(data: OnApproveData, _: OnApproveActions) {
    this.$emit('onPaymentGatewayTokenFetch', data.orderID);
    await Promise.resolve();
  }

  public onCancel(_1: Record<string, unknown>, _2: OnCancelledActions) {
    this.orderId = null;
  }

  public onError(_: Record<string, unknown>) {
    this.orderId = null;
  }
}
