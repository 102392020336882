
import { Editor } from '@tiptap/vue-2';
import { Component, Inject, Prop, Ref, Vue } from 'nuxt-property-decorator';
import { TiptapExtension, TiptapMenuButton } from '../../types';
import InputText from '../design-system/input/input-text/InputText.vue';
import TiptapDropdownButton from './TiptapDropdownButton.vue';

@Component({
  components: {
    TiptapDropdownButton,
    InputText,
  },
})
export default class TiptapVideoButton extends Vue {
  @Inject() $validator;

  @Prop({ required: true }) readonly editor!: Editor;
  @Prop({ required: true }) readonly button!: TiptapMenuButton;

  @Ref() readonly tiptapDropdownButton?: TiptapDropdownButton;
  @Ref() readonly inputText?: InputText;

  readonly TiptapExtension = TiptapExtension;

  videoUrl = '';

  public async setVideo() {
    if (!(await this.$validator.validate('video-url'))) return;

    if (this.videoUrl.includes('youtube.com') || this.videoUrl.includes('youtu.be')) {
      this.editor.commands.setYoutubeVideo({ src: this.videoUrl });
    } else if (this.videoUrl.includes('vimeo.com')) {
      this.editor.commands.setVimeoVideo({ src: this.videoUrl });
    }

    this.tiptapDropdownButton?.closeMenu();
  }

  public onShow() {
    this.videoUrl = '';
    this.$validator.errors.clear();

    setTimeout(() => {
      this.inputText?.inputElement?.focus();
    }, 125);
  }
}
