import { MessageDto, MessageThreadType, MessageReactionType } from '../services';
import { BaseChannel, BaseChannelParams } from './BaseChannel';

type OnMessageAddedCallbackFn = (token: string, message: MessageDto) => void;
type OnMessageUpdatedCallbackFn = (message: MessageDto) => void;
type OnMessageDeletedCallbackFn = (messageId: string, parentId?: string) => void;
type OnMessagesDeletedCallbackFn = (token: string) => void;
type OnMessageUpvotedCallbackFn = (messageId: string, userId: string) => void;
type OnMessageUpvoteDeletedCallbackFn = (messageId: string, userId: string) => void;
type OnMessageReactionAddedCallbackFn = (messageId: string, type: MessageReactionType, userId: string) => void;
type OnMessageReactionDeletedCallbackFn = (messageId: string, type: MessageReactionType, userId: string) => void;

export type MessagingChannelParams = BaseChannelParams & {
  objectId: string;
  type: MessageThreadType;
  onMessageAddedCallback: OnMessageAddedCallbackFn;
  onMessageUpdatedCallback: OnMessageUpdatedCallbackFn;
  onMessageDeletedCallback: OnMessageDeletedCallbackFn;
  onMessagesDeletedCallback: OnMessagesDeletedCallbackFn;
  onMessageReactionAddedCallback: OnMessageReactionAddedCallbackFn;
  onMessageReactionDeletedCallback: OnMessageReactionDeletedCallbackFn;
  onMessageUpvotedCallback?: OnMessageUpvotedCallbackFn;
  onMessageUpvoteDeletedCallback?: OnMessageUpvoteDeletedCallbackFn;
};

export class MessagingChannel extends BaseChannel {
  public objectId: string;
  public type: MessageThreadType;
  private params: MessagingChannelParams;

  private get channel(): string {
    return `${this.objectId}.${this.type}`;
  }

  constructor(params: MessagingChannelParams) {
    super(params.connection);
    this.objectId = params.objectId;
    this.type = params.type;
    this.params = params;
  }

  subscribe() {
    if (this.isSubscribed) return;
    this.connection.on('MessageAdded', this.params.onMessageAddedCallback);
    this.connection.on('MessageUpdated', this.params.onMessageUpdatedCallback);
    this.connection.on('MessageDeleted', this.params.onMessageDeletedCallback);
    this.connection.on('MessagesDeleted', this.params.onMessagesDeletedCallback);
    this.connection.on('MessageReactionAdded', this.params.onMessageReactionAddedCallback);
    this.connection.on('MessageReactionDeleted', this.params.onMessageReactionDeletedCallback);
    this.connection.on('MessageUpvoted', this.params.onMessageUpvotedCallback || this.noop);
    this.connection.on('MessageUpvoteDeleted', this.params.onMessageUpvoteDeletedCallback || this.noop);
    this.connection.invoke('Subscribe', this.channel);
    this.connection.onreconnected(() => this.connection?.invoke('Subscribe', this.channel));
    this.isSubscribed = true;
  }

  unsubscribe() {
    if (!this.isSubscribed) return;
    this.connection.invoke('Unsubscribe', this.channel);
    this.connection.off('MessageAdded', this.params.onMessageAddedCallback);
    this.connection.off('MessageUpdated', this.params.onMessageUpdatedCallback);
    this.connection.off('MessageDeleted', this.params.onMessageDeletedCallback);
    this.connection.off('MessagesDeleted', this.params.onMessagesDeletedCallback);
    this.connection.off('MessageReactionAdded', this.params.onMessageReactionAddedCallback);
    this.connection.off('MessageReactionDeleted', this.params.onMessageReactionDeletedCallback);
    this.connection.off('MessageUpvoted', this.params.onMessageUpvotedCallback || this.noop);
    this.connection.off('MessageUpvoteDeleted', this.params.onMessageUpvoteDeletedCallback || this.noop);
    this.isSubscribed = false;
  }

  noop() {}
}
