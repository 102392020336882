
import { Vue, Component, namespace } from 'nuxt-property-decorator';
import { TranslateResult } from '../../types/i18n';
import { EventDto } from '../../services';

export interface ErrorContent {
  title: string | TranslateResult;
  subtitle: string | TranslateResult;
  linkLabel: string | TranslateResult;
  illustrationPath: string | null;
}

const EventsModule = namespace('events');
const supportedError = ['401', '403', '404'];

@Component({})
export default class ErrorPage extends Vue {
  @EventsModule.State currentEvent!: EventDto;
  @EventsModule.State currentTime!: string;

  get errorCode() {
    return this.$route?.params.code || null;
  }

  get errorContent(): ErrorContent {
    return this.errorCode && supportedError.includes(this.errorCode)
      ? this.errorMap[this.errorCode]
      : this.errorMap.default;
  }

  get errorMap(): Record<string, ErrorContent> {
    return {
      401: {
        title: this.$t('shared.error.page.title.401'),
        subtitle: this.$t('shared.error.page.subtitle.401'),
        linkLabel: this.$t('shared.error.page.cta.401'),
        illustrationPath: '/images/401-illu.png',
      },
      403: {
        title: this.$t('shared.error.page.title.403'),
        subtitle: this.$t('shared.error.page.subtitle.403'),
        linkLabel: this.$t('shared.error.page.cta.403'),
        illustrationPath: '/images/403-illu.png',
      },
      404: {
        title: this.$t('shared.error.page.title.404'),
        subtitle: this.$t('shared.error.page.subtitle.404'),
        linkLabel: this.$t('shared.error.page.cta.404'),
        illustrationPath: null,
      },
      default: {
        title: this.$t('shared.error.page.title.default'),
        subtitle: this.$t('shared.error.page.subtitle.default'),
        linkLabel: this.$t('shared.error.page.cta.default'),
        illustrationPath: null,
      },
    };
  }

  get canRegister() {
    const now = this.$moment(this.currentTime);
    return now.isBetween(
      this.$moment(this.currentEvent.registrationStartDate),
      this.$moment(this.currentEvent.registrationEndDate),
      undefined,
      '[]',
    );
  }

  get hasCta() {
    return !(this.errorCode === '403' && !this.canRegister);
  }
}
