import Vue from 'vue';
import matchMedia from 'matchmediaquery';
import { DeviceQueries, DeviceQueryDefinitions } from '~/types/device-queries';

const em = (px: number) => `${px / 16}em`;

export const mediaqueries: DeviceQueryDefinitions = {
  onlyPhoneSm: `(max-width: ${em(567)})`,
  phoneLg: `(min-width: ${em(568)})`,
  onlyPhone: `(max-width: ${em(739)})`,
  tablet: `(min-width: ${em(740)})`,
  onlyMobile: `(max-width: ${em(1023)})`,
  laptop: `(min-width: ${em(1024)})`,
  laptopLg: `(min-width: ${em(1160)})`,
  desktop: `(min-width: ${em(1360)})`,
  desktopLg: `(min-width: ${em(1440)})`,
  monitor: `(min-width: ${em(1680)})`,
  ultrawide: `(min-width: ${em(2032)})`,
};

export default (_, inject) => {
  const deviceVm = new Vue({
    data() {
      return {
        devices: {},
      };
    },
    created() {
      Object.entries(mediaqueries).forEach(([name, query]) => {
        const mq = matchMedia(query, { width: 1440 });
        this.$set(this.devices, name, mq.matches);

        mq.addListener(({ matches }) => {
          this.devices[name] = matches;
        });
      });
    },
  }) as { devices: DeviceQueries };

  inject('device', deviceVm.devices);
};
