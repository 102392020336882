
import { Component, Vue, Prop } from 'nuxt-property-decorator';
import { Size, Color, IconTheme, Saturation } from '../../../types';
import { rem } from '../../../helpers';

@Component
export default class FeaturedIcon extends Vue {
  @Prop({ type: String, required: true }) readonly icon!: string;
  @Prop({ type: String, default: Color.Gray }) readonly color!: Color;
  @Prop({ type: String, default: 'md' }) readonly size!: string;
  @Prop({ type: String, default: IconTheme.Square }) readonly theme!: IconTheme;
  @Prop({ type: String, default: Saturation.None }) readonly saturation!: Saturation;

  Sizes = Size;

  get isCircle() {
    return this.theme === IconTheme.Circle || this.theme === IconTheme.CircleOutline;
  }

  get isOutline() {
    return this.theme === IconTheme.CircleOutline;
  }

  get sizeMap() {
    return {
      sm: {
        '--height': this.isOutline ? rem(36) : rem(32),
        '--width': this.isOutline ? rem(36) : rem(32),
        '--icon-size': rem(16),
        '--border-size': this.isOutline ? rem(4) : rem(1),
        '--radius': this.isCircle ? rem(28) : rem(6),
      },
      md: {
        '--height': this.isOutline ? rem(44) : rem(40),
        '--width': this.isOutline ? rem(44) : rem(40),
        '--icon-size': rem(20),
        '--border-size': this.isOutline ? rem(6) : rem(1),
        '--radius': this.isCircle ? rem(28) : rem(8),
      },
      lg: {
        '--height': this.isOutline ? rem(54) : rem(48),
        '--width': this.isOutline ? rem(54) : rem(48),
        '--icon-size': rem(24),
        '--border-size': this.isOutline ? rem(8) : rem(1),
        '--radius': this.isCircle ? rem(28) : rem(10),
      },
    };
  }

  get sizingProperties() {
    return this.sizeMap[this.size];
  }

  get elementClassNames() {
    return {
      '-circle': this.isCircle,
      '-outline': this.isOutline,
      '-bg-white': this.saturation === Saturation.None,
      '-dark': this.saturation === Saturation.Dark,
    };
  }

  get rootClassNames() {
    return [this.elementClassNames, `-${this.color} -${this.Sizes[this.size]}`];
  }
}
