import { ValidationError } from './ValidationError';

type Result<T> = T | Error | ValidationError;
export type TypeOrError<T> = Result<T>;

export function isValidationError<T>(result: Result<T>): result is ValidationError {
  const validationErr = <ValidationError>result;
  /* eslint-disable-next-line */
  return validationErr.hasOwnProperty('isSuccess') && validationErr.isSuccess === false;
}

export function validateResponse<T>(result: TypeOrError<T>) {
  return isValidationError(result) || result instanceof Error ? false : result;
}

export function isError<T>(result: Result<T>): result is Error {
  return result instanceof Error;
}

export function isSuccess<T>(result: Result<T>): result is T {
  return !isError(result) && !isValidationError(result);
}
