import { Dictionary } from '../core';
import { ValidationError, ErrorWithCode } from '../services';

export interface ValidatorMixins {
  clearErrorBag(): void;
  feedErrorBag(errors: ValidationError, options?: { key?: string; payload?: Dictionary<any> }): void;
  handleGlobalError(error: ErrorWithCode): void;
  handleFieldError(key: string, error: ErrorWithCode, options: any): void;
}

export const validatorMixins: ValidatorMixins = {
  clearErrorBag() {
    if (this.$validator) this.$validator.errors.clear();
  },

  feedErrorBag(errors, options = {}) {
    if (!errors || !errors.errors) return;

    Object.keys(errors.errors).forEach(key => {
      errors.errors[key].forEach(error => {
        if (key === '') this.handleGlobalError(error);
        else this.handleFieldError(key, error, options);
      });
    });
  },

  handleGlobalError(error: ErrorWithCode) {
    const code = error.code.toLowerCase();
    const locale = `shared.error.server_validation.${code}`;
    if (this.$te(locale)) {
      this.$toast.error(this.$t(locale));
    } else {
      this.$toast.error(code);
    }
  },

  handleFieldError(key: string, error: ErrorWithCode, options: any) {
    const locale = `shared.error.server_validation.${error.code.toLowerCase()}`;
    const message = this.$te(locale) ? this.$t(locale, options.payload) : error.code;
    const fieldId = options.key || key;
    this.$validator.errors.add({
      id: fieldId,
      field: fieldId,
      msg: message,
      error_message: error.message,
    });
  },
};
