export interface CreatePaypalOrderDto {
  paypalMerchantId: string;
  eventId: string;
  totalAmount: number;
  currency: string;
  source: 'buttons' | 'cardfields';
}

interface CardFieldsSubmitError {
  name: string;
  details: CardFieldsSubmitErrorDetail[];
  message: string;
  debug_id: string;
  links: CardFieldsSubmitErrorLink[];
}

interface CardFieldsSubmitErrorDetail {
  field: string;
  location: string;
  issue: string;
  description: string;
}

interface CardFieldsSubmitErrorLink {
  href: string;
  rel: string;
  method: string;
}

export function parseCardFieldsSubmitError(error: unknown): string | null {
  try {
    // Extract the JSON part of the error string
    const stringifiedError = (error as any).toString();
    const jsonStartIndex = stringifiedError.indexOf('{"name":');
    const jsonString = stringifiedError.substring(jsonStartIndex);

    const parsedError = JSON.parse(jsonString) as CardFieldsSubmitError;

    if (parsedError.details?.length) return parsedError.details[0].description;
    return parsedError.message?.length ? parsedError.message : null;
  } catch (_) {
    return null;
  }
}
