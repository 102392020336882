
import { Component, Prop, mixins, Watch } from 'nuxt-property-decorator';
import { InputOption } from '../../../../types';
import InputValidationMixin from '../InputValidationMixin';

type InputOptionValue = InputOption['id'];

@Component({})
export default class InputSelectNative extends mixins(InputValidationMixin) {
  @Prop({ required: true }) readonly value!: InputOptionValue | null;
  @Prop({ required: true }) readonly options!: InputOption[];
  @Prop({ type: String, required: true }) readonly groupId!: string;
  @Prop({ type: String, default: null }) readonly labelId!: string | null;
  @Prop({ type: String, default: null }) readonly placeholder!: string | null;
  @Prop({ default: Object }) readonly validation!: object | string | null;
  @Prop(Boolean) readonly disabled!: boolean;

  innerValue: InputOptionValue | null = null;

  get placeholderFallback() {
    return this.placeholder || this.$t('shared.inputs.select.placeholder');
  }

  get nativeSelectClassName() {
    return {
      '-destructive': this.hasValidationError,
      '-placeholder': !this.value,
    };
  }

  @Watch('value', { immediate: true })
  onValueChange() {
    if (this.innerValue === this.value) return;
    this.innerValue = this.value;
  }

  @Watch('innerValue')
  onInnerValueChange() {
    this.$emit('change', this.innerValue);
    this.$emit('input', this.innerValue);
  }

  public isChecked(optionId: InputOptionValue) {
    return this.value === optionId;
  }
}
