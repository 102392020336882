import { Activity } from './activity';

export const storageKey = 'activities';

export abstract class ActivityStore {
  abstract add(activity: Activity): void;
  abstract getAll(): Activity[];
  abstract clearAll(): void;
}

export class LocalStorageStore extends ActivityStore {
  /**
   * Checks if localStorage is available.
   * An error 'NS_ERROR_FILE_CORRUPTED' can occur on Firefox. We cannot assume that localStorage is not null as a solution.
   */
  static isAvailable(): boolean {
    try {
      localStorage.getItem('isAvailable');
      return true;
    } catch (error) {
      return false;
    }
  }

  add(activity: Activity): void {
    if (!activity) {
      console.error('User activity is null');
      return;
    }

    const activities = this.getAll();
    activities.push(activity);
    localStorage.setItem(storageKey, JSON.stringify(activities));
  }

  getAll(): Activity[] {
    const json = localStorage.getItem(storageKey);
    return json ? JSON.parse(json) : [];
  }

  clearAll(): void {
    localStorage.removeItem(storageKey);
  }
}

export class InMemoryStore extends ActivityStore {
  private activities: Activity[] = [];

  static isAvailable(): boolean {
    return true;
  }

  add(activity: Activity): void {
    if (!activity) {
      console.error('User activity is null');
      return;
    }

    this.activities.push(activity);
  }

  getAll(): Activity[] {
    return this.activities;
  }

  clearAll(): void {
    this.activities = [];
  }
}
