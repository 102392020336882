
import { Component, Prop, Watch, Vue, Inject } from 'nuxt-property-decorator';
import DynamicElement from '../DynamicElement';

type DynamicClassNameList = string | Record<string, any> | (string | Record<string, any>)[];

@Component({
  components: {
    DynamicElement,
  },
})
export default class Collapsible extends Vue {
  @Prop(Boolean) readonly isOpen!: boolean;
  @Prop({ type: String, default: 'div' }) readonly tag!: string;
  @Prop({ type: String, default: 'div' }) readonly contentTag!: string;
  @Prop({ type: [String, Array, Object], default: Array }) readonly contentClass!: DynamicClassNameList;
  @Prop({ type: [String, Number], default: 'auto' }) readonly openHeight!: string | number;
  @Prop({ type: [String, Number, Array, Object], default: null }) readonly transitionKey!: unknown;
  @Prop({ type: Number, default: 0 }) readonly minHeight!: number;
  @Prop({ type: Number, default: null }) readonly zIndex!: number | null;
  @Prop(Boolean) readonly relative!: boolean;

  @Inject() $validator;

  $refs!: {
    content: HTMLElement;
  };

  height: string | number = 'auto';
  contentHeight = 0;
  muteNextTransitionEnd = false;

  get style() {
    const style: { height: number | string; overflow?: string } = {
      height: !Number(this.height) ? this.height : `${this.height}px`,
    };

    if (this.height === this.openHeight) style.overflow = 'visible';
    if (this.zIndex) style['--z-index'] = this.zIndex;
    return style;
  }

  get hasMore() {
    return this.minHeight > 0 && this.contentHeight > this.minHeight;
  }

  get minHeightValue() {
    if (!this.minHeight) return 0;
    return this.hasMore ? `${this.minHeight}px` : 'auto';
  }

  get rootClassName() {
    return {
      '-open': this.isOpen,
      '-show-more': this.minHeight > 0,
      '-has-more': this.hasMore,
    };
  }

  get contentClassName() {
    const contentClass = Array.isArray(this.contentClass) ? this.contentClass : [this.contentClass];

    return [
      {
        '-relative': this.relative,
      },
      ...contentClass,
    ];
  }

  @Watch('isOpen')
  onIsOpenChange(isOpen: boolean, wasOpen: boolean) {
    this.contentHeight = this.$refs.content.clientHeight;
    if (isOpen === wasOpen) return;
    if (wasOpen) this.height = this.contentHeight;
    else this.resetAfterTransition();
    this.setHeight(isOpen);
  }

  created() {
    this.height = this.isOpen ? this.openHeight : this.minHeightValue;
  }

  async mounted() {
    await this.$nextTick();
    if (!this.$refs.content) return;
    this.contentHeight = this.$refs.content.clientHeight;
    this.height = this.isOpen ? this.openHeight : this.minHeightValue;

    setTimeout(() => {
      this.$watch('transitionKey', (to, from) => {
        if (to === from || !this.isOpen) return;
        this.height = this.$refs.content.clientHeight;
        this.resetAfterTransition();
        this.setHeight(this.isOpen);
      });
    }, 100);
  }

  public async setHeight(isOpen: boolean) {
    await this.$nextTick();

    setTimeout(() => {
      const openHeight = this.$refs.content ? this.$refs.content.clientHeight : 'auto';
      const height = isOpen ? openHeight : this.minHeightValue;
      this.$emit('before-transition', height);
      this.height = height;
    }, 10);
  }

  public onTransitionEnd() {
    if (this.muteNextTransitionEnd) {
      this.muteNextTransitionEnd = false;
      return;
    }

    const stateEvent = this.isOpen ? 'open-transition-end' : 'close-transition-end';
    this.$emit('transition-end');
    this.$emit(stateEvent);
  }

  public resetAfterTransition() {
    this.$once('transition-end', () => {
      this.muteNextTransitionEnd = true;
      if (this.isOpen) this.height = this.openHeight;
    });
  }
}
