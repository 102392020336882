
import { Component, Prop, Ref, Vue } from 'nuxt-property-decorator';
import { debounce } from 'lodash-es';
import DynamicElement from '../DynamicElement';

type DynamicClassNameList = string | Record<string, any> | (string | Record<string, any>)[];

@Component({
  components: {
    DynamicElement,
  },
})
export default class HeightTransition extends Vue {
  @Prop({ type: String, default: 'div' }) readonly tag!: string;
  @Prop({ type: String, default: 'hidden' }) readonly overflow!: string;
  @Prop({ type: String, default: 'div' }) readonly contentTag!: string;
  @Prop({ type: [String, Array, Object], default: Array }) readonly contentClass!: DynamicClassNameList;

  @Ref() wrapperRef?: HTMLElement;
  @Ref() contentRef?: HTMLElement;

  height: string | number = 'auto';
  mutationObserver: MutationObserver | null = null;

  get style() {
    return {
      '--overflow': this.overflow,
      '--height': !Number(this.height) ? this.height : `${this.height}px`,
    };
  }

  created() {
    this.mutationObserver = new MutationObserver(debounce(this.setHeight, 10));
  }

  async mounted() {
    await this.$nextTick();
    if (!this.wrapperRef || !this.mutationObserver) return;
    await this.setHeight();
    this.mutationObserver.observe(this.wrapperRef, { childList: true, subtree: true });
  }

  beforeDestroy() {
    if (!this.mutationObserver) return;
    this.mutationObserver.disconnect();
  }

  public async setHeight() {
    await this.$nextTick();
    if (!this.contentRef) return;
    this.height = Math.ceil(this.contentRef.getBoundingClientRect().height);
  }
}
