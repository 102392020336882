
import { Component, Prop, Vue, namespace } from 'nuxt-property-decorator';

const EventsModule = namespace('events');

@Component({})
export default class TimeFromNow extends Vue {
  @EventsModule.State currentTime!: string;
  @Prop({ required: true }) dateTime!: string;

  get timeFromNow(): string {
    return this.$moment(this.dateTime).from(this.currentTime);
  }
}
