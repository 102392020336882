
import { Component, Vue, Prop, Ref } from 'nuxt-property-decorator';
import InputText from '../design-system/input/input-text/InputText.vue';
import InputTextArea from '../design-system/input/input-text/InputTextArea.vue';
import Modal from '../modals/Modal.vue';
import { isValidationError, isSuccess, FormEntryDto, getSubmissionTitle, FormDto } from '../../../src/services';

@Component({
  $_veeValidate: {
    validator: 'new',
  },
  components: { InputText, InputTextArea, Modal },
})
export default class ContactPresenterModal extends Vue {
  @Prop({ required: true }) presentation!: FormEntryDto;
  @Prop({ required: true }) form!: FormDto;
  @Ref() readonly modal?: Modal;

  subject = '';
  body = '';

  get isWaiting() {
    return this.$wait.is('send');
  }

  public triggerVisibility(eventCurrentTarget?: HTMLButtonElement | EventTarget | null) {
    this.subject = getSubmissionTitle(this.presentation, this.form);
    this.modal?.triggerVisibility(eventCurrentTarget);
  }

  public async sendEmail() {
    if (!(await this.$validator.validateAll())) return;

    this.$wait.start('send');
    const successMessage = this.$t('shared.presentations.contact_presenter_modal.success_message') as string;
    const response = await this.$api.contactPresenter(this.presentation.id, this.subject, this.body);
    if (isSuccess(response)) {
      this.$toast.success(successMessage);
      this.triggerVisibility();
      this.body = '';
    } else if (isValidationError(response)) {
      this.feedErrorBag(response);
    }
    this.$wait.end('send');
  }
}
