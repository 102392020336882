
import { Component, Prop, Vue } from 'nuxt-property-decorator';
import { TranslateResult } from '../../types/i18n';

@Component
export default class UserActionButton extends Vue {
  @Prop(Boolean) readonly isActive!: boolean;
  @Prop({ type: String, required: true }) readonly ariaLabel!: TranslateResult;
  @Prop({ type: String, required: true }) readonly icon!: string;
  @Prop({ type: String, default: null }) readonly activeIcon!: string | null;
  @Prop({ type: String, default: null }) readonly iconClass!: string | null;

  get iconName() {
    return this.isActive && this.activeIcon ? this.activeIcon : this.icon;
  }

  get iconClassName() {
    return [this.iconClass, { '-active': this.isActive }];
  }
}
