
import { Prop, Component, Model, mixins } from 'nuxt-property-decorator';
import { v4 as uuid } from 'uuid';
import { InputOptionWithLink } from '../../../../types';
import Badge from '../../../Badge.vue';
import { TranslateResult } from '../../../../types/i18n';
import Collapsible from '../../../layout/Collapsible.vue';
import RawHtml from '../../../layout/RawHtml.vue';
import InputValidationMixin from '../InputValidationMixin';
import InputRadio from './InputRadio.vue';

@Component({ components: { InputRadio, Badge, Collapsible, RawHtml } })
export default class InputRadioGroup extends mixins(InputValidationMixin) {
  @Prop({ required: true }) readonly options!: InputOptionWithLink[];
  @Prop({ required: true }) readonly groupId!: string;
  @Prop({ type: String, default: null }) readonly label!: string | TranslateResult;
  @Prop({ type: String, default: null }) readonly hint!: string | TranslateResult;
  @Prop(Boolean) readonly disabled!: boolean;
  @Prop(Boolean) readonly accordion!: boolean;
  @Prop({ type: Array, default: Array }) readonly accordionOptionIds!: (string | boolean | number)[];

  @Model('change', { type: [String, Boolean, Number], default: null }) readonly value!: string | boolean | number;

  get innerValue() {
    return this.value;
  }

  set innerValue(value: string | boolean | number) {
    this.$emit('change', value);
  }

  get uniqueId() {
    return uuid();
  }

  public isChecked(optionId: string | number | boolean): boolean {
    return this.innerValue === optionId;
  }

  public getOptionWrapperClassNames(option: InputOptionWithLink) {
    const classNames = ['input-option -accordion-wrapper', this.getOptionModifierClassNames(option)];
    return this.accordion ? classNames : null;
  }

  public getOptionModifierClassNames(option: InputOptionWithLink) {
    return {
      '-destructive': this.hasValidationError,
      '-disabled': this.disabled || option.disabled || option.unavailable,
      '-checked': this.isChecked(option.id),
    };
  }
}
