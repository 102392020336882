
import { Component, Vue, Model, Watch } from 'nuxt-property-decorator';
import { isEqual } from 'lodash-es';
import InputTextArea from './InputTextArea.vue';

@Component({
  components: {
    InputTextArea,
  },
})
export default class DelimiterTextArea extends Vue {
  @Model('input', { type: Array, default: Array }) readonly value!: string[];

  innerValue = '';

  get innerValueAsArray() {
    return this.getDelimitedValue(this.innerValue);
  }

  @Watch('value', { immediate: true })
  onValueChange() {
    if (isEqual(this.value, this.innerValueAsArray)) return;
    this.innerValue = this.value.join(', ') || '';
  }

  public onInput() {
    this.$emit('input', this.innerValueAsArray);
  }
}
