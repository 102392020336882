import Vue from 'vue';

export default Vue.extend({
  name: 'DynamicElement',
  functional: true,
  props: {
    tag: { type: String, default: 'div' },
  },
  render(h, { children, data, props }) {
    return h(props.tag, { class: [data.staticClass, data.class] }, children);
  },
});
