import en from 'vee-validate/dist/locale/en';
import fr from 'vee-validate/dist/locale/fr';
import { Validator } from 'vee-validate';
import {
  validationRules as sharedValidationRules,
  CustomValidationRule,
} from 'fourwaves-shared/src/plugins/validation-rules';

export default function ({ app }) {
  // Loading base files
  Validator.localize('en', en);
  Validator.localize('fr', fr);

  // Extend the validator with custom rules
  Object.entries<CustomValidationRule>({ ...sharedValidationRules }).forEach(([ruleName, rule]) => {
    Validator.extend(ruleName, rule.validate, rule.options ? rule.options : undefined);

    if (rule.localize)
      Validator.localize({
        fr: { messages: { [ruleName]: rule.localize.fr } },
        en: { messages: { [ruleName]: rule.localize.en } },
      });
  });

  // Setup custom messages
  Validator.localize({
    fr: {
      messages: {
        required: () => app.i18n.t('validation.required'),
        is: () => app.i18n.t('validation.is'),
        email: () => app.i18n.t('validation.email'),
        min: (field, [length]) => `Ce champ doit être composé d'au moins ${length} caractères.`,
        max: (field, max) => `Ce champ ne peut pas contenir plus de ${max} caractères.`,
        min_value: (field, [min]) => `Ce champ doit avoir une valeur de ${min} ou plus.`,
        max_value: (field, [max]) => `Ce champ doit avoir une valeur de ${max} ou moins.`,
        numeric: () => `Ce champ ne peut contenir que des chiffres.`,
        alpha_num: () => `Ce champ doit contenir uniquemment des caractères alphanumériques.`,
        date_format: () => `Ce champ doit avoir un format de date valide.`,
        after: (field, [target]) => `Ce champ doit être postérieur au précédent (${target}).`,
        integer: () => `Ce champ doit être un entier`,
        decimal: () => `Ce champ doit être un nombre`,
        duplicate_author_email: () => app.i18n.t('shared.error.server_validation.duplicate_author_email'),
      },
    },
    en: {
      messages: {
        required: () => app.i18n.t('validation.required'),
        is: () => app.i18n.t('validation.is'),
        email: () => app.i18n.t('validation.email'),
        min: (field, [length]) => `The field must be at least ${length} characters.`,
        max: (field, max) => `The field may not be greater than ${max} characters.`,
        min_value: (field, [min]) => `The field must be ${min} or more.`,
        max_value: (field, [max]) => `The field must be ${max} or less.`,
        numeric: () => `The field may only contain numeric characters.`,
        alpha_num: () => `This field must contain alphanumeric characters.`,
        date_format: () => `This field must contain valid date format.`,
        after: (field, [target]) => `This field must be after the previous one (${target}).`,
        integer: () => `This field must be an integer.`,
        decimal: () => `This field must be a number.`,
        duplicate_author_email: () => app.i18n.t('shared.error.server_validation.duplicate_author_email'),
      },
    },
  });

  // Localizing the app when user refresh or access a localized link
  Validator.localize(app.i18n.locale);

  // Called everytime language change
  app.i18n.onBeforeLanguageSwitch = (oldLocale, newLocale, isInitialSetup) => {
    if (!isInitialSetup) {
      Validator.localize(newLocale);
    }
  };
}
