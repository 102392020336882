
import { Component, Vue, Prop } from 'nuxt-property-decorator';
import { TranslateResult } from '../../types/i18n';

@Component({})
export default class NoResults extends Vue {
  @Prop({ required: true }) path!: string;
  @Prop({ required: true }) text!: TranslateResult;
  @Prop(Boolean) readonly isDarkMode!: boolean;
}
