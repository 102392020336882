
import { Editor } from '@tiptap/vue-2';
import { Component, Emit, Prop, Vue } from 'nuxt-property-decorator';
import { TiptapMenuButton } from '../../types';
import Tooltip from '../Tooltip.vue';

@Component({
  components: {
    Tooltip,
  },
})
export default class TiptapDropdownButton extends Vue {
  @Prop({ required: true }) readonly editor!: Editor;
  @Prop({ required: true }) readonly button!: TiptapMenuButton;

  @Emit() show() {}

  isOpened = false;

  public closeMenu() {
    this.isOpened = false;
  }
}
