export default {
  about: 'À propos',
  modal_title: 'Contacter le participant',
  modal_send_email: 'Envoyer le courriel',
  modal_success_message: 'Courriel envoyé.',
  aria_participant_link: 'Voir le profil de {name}',
  presentations: {
    title: 'Présentations',
    presented_by: 'Présenté par ',
    schedule_presentation: 'Programmé dans {count} session | Programmé dans {count} sessions',
    presenting_now: 'Présentation en cours',
  },
  search_placeholder: 'Rechercher par nom ou affiliation',
  contact_by_mail: 'Contacter par courriel',
  contact_by_dr: 'Contacter par message direct',
  go_to_twitter: 'Aller au compte Twitter de',
  go_to_linkedin: 'Aller au compte LinkedIn de',
};
