export default {
  title: 'Clavardage',
  textarea_placeholder: 'Saisir un message',
  textarea_label: 'Message',
  aria_label_send: 'Envoyer',
  aria_label_toggle_emoji: 'Basculer la liste emoji',
  aria_label_profile: 'Le profil de {fullName}',
  title_toggle_emoji: 'Réagir',
  no_results: 'Soyez le premier à écrire un message.',
};
