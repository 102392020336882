import Vue from 'vue';

// Plugins
import PanZoom from 'vue-panzoom';
import FloatingVue from 'floating-vue';

// Shared components
import { Icon, Hint, Modal, ActionLink, ActionButton, Spinner } from 'fourwaves-shared/components';

Vue.use(PanZoom, { componentName: 'PanZoom' });
Vue.use(FloatingVue);
Vue.component('Spinner', Spinner);
Vue.component('Modal', Modal);
Vue.component('Hint', Hint);
Vue.component('Icon', Icon);
Vue.component('ActionLink', ActionLink);
Vue.component('ActionButton', ActionButton);
