
import { Component, Prop, Ref, Vue } from 'nuxt-property-decorator';
import { InputFileUploadStatus } from '../../../../types';

@Component
export default class InputFileProgress extends Vue {
  @Prop({ type: String, required: true }) readonly status!: InputFileUploadStatus;
  @Prop({ type: Number, default: null }) readonly progress!: number | null;

  @Ref() loaderPathRef?: SVGPathElement;

  readonly InputFileUploadStatus = InputFileUploadStatus;

  pathLength = 1;

  get classNames() {
    return {
      '-uploading': this.status === InputFileUploadStatus.Uploading && !this.isProcessing,
      '-complete': this.status === InputFileUploadStatus.Complete,
      '-pending': this.status === InputFileUploadStatus.Pending,
      '-error': this.status === InputFileUploadStatus.Error,
      '-processing': this.isProcessing,
    };
  }

  get isProcessing() {
    return this.progress && this.progress >= 1 && this.status === InputFileUploadStatus.Uploading;
  }

  get pathStyles() {
    if (this.status === InputFileUploadStatus.Complete) return {};
    const quarterPathLength = this.pathLength * 0.25;
    const useQuarterLength = this.status === InputFileUploadStatus.Pending || this.isProcessing;

    return {
      '--dasharray': useQuarterLength ? quarterPathLength : this.pathLength,
      '--dashoffset':
        this.status === InputFileUploadStatus.Pending || this.progress == null
          ? this.pathLength - quarterPathLength
          : this.pathLength * (1 - this.progress),
    };
  }

  get textProgress() {
    if (this.progress == null) return null;
    return `${Math.round(this.progress * 100)}%`;
  }

  async mounted() {
    await this.$nextTick();
    this.pathLength = this.loaderPathRef?.getTotalLength() ?? 0;
  }
}
