import type { Dictionary } from '../../../core';
import type { TranslatableField } from '../../../services/fourwaves/models/TranslatableField';
import type { FormDto } from './FormDto';
import type { TransactionDto } from './TransactionDto';
import type { FormFieldChoiceDto } from './FormFieldChoiceDto';
import type { ReviewStatus } from './ReviewStatus';
import type { UpdatedByUser } from './UserDto';

export enum SubmissionDecisionEnum {
  Rejected = 'rejected',
  Pending = 'pending',
  Accepted = 'accepted',
}

export interface SubmissionDecisionDto {
  id: string;
  formEntryId: string;
  presentationTypeId: string;
  decision: SubmissionDecisionEnum;
  lastUpdateDate: string | null;
  lastUpdateUser: UpdatedByUser | null;
}

export interface FormEntryValueDto {
  id: string;
  value: string;
  formFieldId: string;
  fieldType: string;
  order?: number;
  creationDate: string;
}

export interface AuthorDto {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  isPresenter: boolean;
  order: number;
  affiliations: string[];
  userId: string | null;
}

export interface PresentationType {
  id: string;
  name: TranslatableField;
}

export interface PresentationTime {
  sessionId: string;
  start: string;
  end: string;
}

export enum FormEntryStatus {
  Registered = 'Registered',
  Submitted = 'Submitted',
  OnWaitingList = 'OnWaitingList',
  Withdrawn = 'Withdrawn',
  Scheduled = 'Scheduled',
}

export interface FormEntryDto {
  id: string;
  formId: string;
  userId: string;
  sequentialId: number;
  formEntryValues: FormEntryValueDto[];
  creationDate: string;
  lastUpdateDate: string | null;
  lastUpdateUser: UpdatedByUser | null;
  paidChoiceIds: string[];
  transactions: TransactionDto[];
  videoParticipantCount: number;
  listenerCount: number;
  thumbnail?: string;
  isPresentingNow: boolean;
  presentationTimes?: PresentationTime[];
  isDiscussionPermittedBySession: boolean;
  submissionDecisions: SubmissionDecisionDto[];
  isPublished: boolean;
  reviewStatus: ReviewStatus;
  reviewScore: number;
  reviewScoreStdDev: number;
  status: FormEntryStatus;
  withdrawnAt: string | null;
  withdrawnBy: UpdatedByUser | null;
  withdrawalReason: string | null;
  unpaidItems: boolean;
  isMissingRequiredFields: boolean;
  notRegistered: boolean;
  pendingPayment: boolean;
  inConflict: boolean;
  upvoteCount: number;
  isUpvotedByCurrentUser: boolean;
  isBookmarkedByCurrentUser: boolean;
  commentCount: number;
  withSubmission?: boolean;
  participantId?: string;
  affiliation: string;
  authors?: AuthorDto[];
}

export const formEntryStatusColorMap = {
  [FormEntryStatus.Registered]: 'success',
  [FormEntryStatus.Submitted]: 'success',
  [FormEntryStatus.OnWaitingList]: 'gray',
  [FormEntryStatus.Withdrawn]: 'error',
  [FormEntryStatus.Scheduled]: 'primary',
};

export function fillDictionary(formEntry: FormEntryDto, formData: Dictionary<string | string[]>) {
  if (formEntry && formEntry.formEntryValues) {
    for (const formEntryValue of formEntry.formEntryValues) {
      if (!formData[formEntryValue.formFieldId]) {
        formData[formEntryValue.formFieldId] = formEntryValue.value;
      } else if (!Array.isArray(formData[formEntryValue.formFieldId])) {
        formData[formEntryValue.formFieldId] = [formData[formEntryValue.formFieldId] as string];
        (formData[formEntryValue.formFieldId] as string[]).push(formEntryValue.value);
      } else {
        (formData[formEntryValue.formFieldId] as string[]).push(formEntryValue.value);
      }
    }
  }
}

export function getFormFieldChoicePaymentStatus(formEntry: FormEntryDto, choice: FormFieldChoiceDto) {
  if (!formEntry || !formEntry.transactions) return 'pending';
  const transaction = formEntry.transactions.find(trx => trx.items.some(item => item.itemId === choice.id));
  // free items, but 'ignored'
  if (!transaction) return 'concluded';
  return transaction.status;
}

export function getFormFieldChoicePaidCost(formEntry: FormEntryDto, choice: FormFieldChoiceDto): number {
  if (!formEntry || !formEntry.transactions) return 0;
  const transaction = formEntry.transactions.find(trx => trx.items.some(item => item.itemId === choice.id));
  if (!transaction) return 0;
  return transaction.items.find(item => item.itemId === choice.id)!.total;
}

export function getFirstName(formEntry?: FormEntryDto | null, form?: FormDto | null): string {
  if (formEntry == null || form == null) return '';
  const formEntryValue = formEntry.formEntryValues.find(x => x.formFieldId === form.firstNameFormFieldId);
  return formEntryValue ? formEntryValue.value : '';
}

export function getLastName(formEntry?: FormEntryDto | null, form?: FormDto | null): string {
  if (formEntry == null || form == null) return '';
  const formEntryValue = formEntry.formEntryValues.find(x => x.formFieldId === form.lastNameFormFieldId);
  return formEntryValue ? formEntryValue.value : '';
}

export function getEmail(formEntry?: FormEntryDto | null, form?: FormDto | null): string {
  if (formEntry == null || form == null) return '';
  const formEntryValue = formEntry.formEntryValues.find(x => x.formFieldId === form.emailFormFieldId);
  return formEntryValue ? formEntryValue.value : '';
}

export function getSubmissionTitle(formEntry?: FormEntryDto | null, form?: FormDto | null): string {
  if (formEntry == null || form == null) return '';
  const formEntryValue = formEntry.formEntryValues.find(x => x.formFieldId === form.submissionTitleFormFieldId);
  return formEntryValue ? formEntryValue.value : '';
}

export function getAbstract(formEntry?: FormEntryDto | null, form?: FormDto | null): string {
  if (formEntry == null || form == null) return '';
  const formEntryValue = formEntry.formEntryValues.find(x => x.formFieldId === form.submissionAbstractFormFieldId);
  return formEntryValue ? formEntryValue.value : '';
}

export function getFormEntryValue(
  formEntry?: FormEntryDto | null,
  form?: FormDto | null,
  formFieldId?: string,
): string {
  if (formEntry == null || form == null || !formFieldId) return '';
  const formEntryValue = formEntry.formEntryValues.filter(x => x.formFieldId === formFieldId);
  return formEntryValue ? formEntryValue.map(x => x.value).join(',') : '';
}
