
import { LocaleObject } from '@nuxtjs/i18n';
import { Component, Vue, Prop, namespace } from 'nuxt-property-decorator';
import { NuxtEvents } from '~/core';

const EventsModule = namespace('events');

@Component({})
export default class LangChooser extends Vue {
  @EventsModule.Getter isEventMultiLanguage!: boolean;
  @Prop({ type: String, default: null }) readonly buttonClass!: string | null;
  @Prop({ type: String, default: 'link-gray' }) readonly hierarchy!: string;
  @Prop({ type: String, default: 'md' }) readonly size!: string;
  @Prop(Boolean) dark!: boolean;

  get availableLocales() {
    return (this.$i18n.locales as Array<LocaleObject>).filter(x => x.code !== this.$i18n.locale);
  }

  public changeLanguage(code: string) {
    this.$nuxt.$emit(NuxtEvents.ChangeLanguage, code);
  }
}
