import type { DirectiveBinding } from 'vue';
import { Vue } from 'nuxt-property-decorator';
import { v4 as uuid } from 'uuid';
import { omit } from 'lodash-es';

type IntersectionEdgeHandler = () => void;
type IntersectionChangeHandler = (isVisible: boolean) => void;

type IntersectBindingValue = {
  onChange?: IntersectionChangeHandler;
  onEnter?: IntersectionEdgeHandler;
  onLeave?: IntersectionEdgeHandler;
  root?: HTMLElement;
  rootMargin?: string;
  threshold?: number | number[];
};

const intersectState: { observers: Record<string, IntersectionObserver> } = { observers: {} };

function createFallbackHandler({ onChange, onEnter, onLeave }: IntersectBindingValue): IntersectionChangeHandler {
  return isVisible => {
    if (onChange) onChange(isVisible);
    if (onEnter && isVisible) onEnter();
    if (onLeave && !isVisible) onLeave();
  };
}

Vue.directive('intersect', {
  inserted: (el, { value }: Partial<DirectiveBinding<IntersectionChangeHandler | IntersectBindingValue>>) => {
    if (!value) return;
    const isShorthand = typeof value === 'function';
    const id = uuid();
    const { onChange, onEnter, onLeave, ...options } = isShorthand ? ({} as IntersectBindingValue) : value;
    const handler = isShorthand ? value : createFallbackHandler({ onChange, onEnter, onLeave });
    el.dataset.observerId = id;

    const observer = new IntersectionObserver(
      entries => handler(entries.some(({ isIntersecting }) => isIntersecting)),
      options,
    );

    intersectState.observers = { ...intersectState.observers, [id]: observer };
    observer.observe(el);
  },
  unbind: el => {
    const id = el.dataset.observerId;
    if (!id) return;
    intersectState.observers[id]?.disconnect();
    intersectState.observers = omit(intersectState.observers, id);
  },
});
