export default {
  about: 'About',
  modal_title: 'Contact Participant',
  modal_send_email: 'Send Email',
  modal_success_message: 'Email sent.',
  aria_participant_link: `View {name}'s profile`,
  presentations: {
    title: 'Presentations',
    presented_by: 'Presented by ',
    schedule_presentation: 'Scheduled in {count} session | Scheduled in {count} sessions',
    presenting_now: 'Presenting now',
  },
  search_placeholder: 'Search by name or affiliation',
  contact_by_mail: 'Contact by mail',
  contact_by_dr: 'Contact by direct message',
  go_to_twitter: 'Go to Twitter account of',
  go_to_linkedin: 'Go to LinkedIn account of',
};
