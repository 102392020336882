/*
 * Taken from the DJANGO app. These values have been hard-coded in the legacy app.
 * (WARNING) Any changes will require back-end adaptation.
 */
export const Provinces = [
  { country: 'CA', province: 'AB' },
  { country: 'CA', province: 'BC' },
  { country: 'CA', province: 'MB' },
  { country: 'CA', province: 'NB' },
  { country: 'CA', province: 'NL' },
  { country: 'CA', province: 'NS' },
  { country: 'CA', province: 'ON' },
  { country: 'CA', province: 'PE' },
  { country: 'CA', province: 'QC' },
  { country: 'CA', province: 'SK' },
  { country: 'CA', province: 'NT' },
  { country: 'CA', province: 'YK' },
  { country: 'CA', province: 'NU' },
  { country: 'US', province: 'AL' },
  { country: 'US', province: 'AK' },
  { country: 'US', province: 'AS' },
  { country: 'US', province: 'AZ' },
  { country: 'US', province: 'AR' },
  { country: 'US', province: 'CA' },
  { country: 'US', province: 'CO' },
  { country: 'US', province: 'CT' },
  { country: 'US', province: 'DK' },
  { country: 'US', province: 'DE' },
  { country: 'US', province: 'DC' },
  { country: 'US', province: 'FL' },
  { country: 'US', province: 'GA' },
  { country: 'US', province: 'GU' },
  { country: 'US', province: 'HI' },
  { country: 'US', province: 'ID' },
  { country: 'US', province: 'IL' },
  { country: 'US', province: 'IN' },
  { country: 'US', province: 'IA' },
  { country: 'US', province: 'KS' },
  { country: 'US', province: 'KY' },
  { country: 'US', province: 'LA' },
  { country: 'US', province: 'ME' },
  { country: 'US', province: 'MD' },
  { country: 'US', province: 'MA' },
  { country: 'US', province: 'MI' },
  { country: 'US', province: 'MN' },
  { country: 'US', province: 'MS' },
  { country: 'US', province: 'MO' },
  { country: 'US', province: 'MT' },
  { country: 'US', province: 'NE' },
  { country: 'US', province: 'NV' },
  { country: 'US', province: 'NH' },
  { country: 'US', province: 'NJ' },
  { country: 'US', province: 'NM' },
  { country: 'US', province: 'NY' },
  { country: 'US', province: 'NC' },
  { country: 'US', province: 'ND' },
  { country: 'US', province: 'MP' },
  { country: 'US', province: 'OH' },
  { country: 'US', province: 'OK' },
  { country: 'US', province: 'OR' },
  { country: 'US', province: 'OL' },
  { country: 'US', province: 'PA' },
  { country: 'US', province: 'PI' },
  { country: 'US', province: 'PR' },
  { country: 'US', province: 'RI' },
  { country: 'US', province: 'SC' },
  { country: 'US', province: 'SD' },
  { country: 'US', province: 'TN' },
  { country: 'US', province: 'TX' },
  { country: 'US', province: 'UT' },
  { country: 'US', province: 'VT' },
  { country: 'US', province: 'VI' },
  { country: 'US', province: 'VA' },
  { country: 'US', province: 'WA' },
  { country: 'US', province: 'WV' },
  { country: 'US', province: 'WI' },
  { country: 'US', province: 'WY' },
];
