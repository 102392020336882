
import { Component, Prop, Model, Ref, Watch, mixins } from 'nuxt-property-decorator';
import SimpleBar from 'simplebar';
import { v4 as uuid } from 'uuid';
import { GlobalDropdownEvents, InputOption } from '../../../../types';
import { Collapsible } from '../../../layout';
import InputValidationMixin from '../InputValidationMixin';
import { rem } from '../../../../helpers';

type InputOptionValue = InputOption['id'];

@Component({ components: { Collapsible } })
export default class InputSelect extends mixins(InputValidationMixin) {
  @Prop({ required: true }) readonly options!: InputOption[];
  @Prop({ type: String, required: true }) readonly groupId!: string;
  @Prop({ type: String, required: true }) readonly label!: string | null;
  @Prop({ type: String, default: null }) readonly placeholder!: string | null;
  @Prop({ type: String, default: null }) readonly help!: string | null;
  @Prop({ type: String, default: null }) readonly leadingIcon!: string | null;
  @Prop({ type: Number, default: null }) readonly trayMaxHeight!: number | null;
  @Prop(Boolean) readonly multiple!: boolean;
  @Prop(Boolean) readonly disabled!: boolean;
  @Prop(Boolean) readonly above!: boolean;
  @Prop(Boolean) readonly emptyOption!: boolean;
  @Prop(Boolean) readonly forceCustom!: boolean;
  @Prop(Boolean) readonly hideLabel!: boolean;

  @Model('change', { type: [Array, String, Number, Boolean], default: null }) readonly value!:
    | InputOptionValue[]
    | InputOptionValue
    | null;

  @Ref('optionsMenuContainer') readonly optionsMenuContainer?: HTMLElement;
  @Ref('selectButton') readonly selectButton?: HTMLElement;

  simplebarInstance: SimpleBar | null = null;
  scrollElement: HTMLElement | null = null;
  isOpen = false;
  isFocused = false;
  uniqueLabelId = uuid();

  get valueAsArray() {
    if (this.value === null || this.value === undefined) return [];
    return Array.isArray(this.value) ? this.value : [this.value];
  }

  get selectedOptions() {
    return this.options.filter(o => this.valueAsArray.includes(o.id));
  }

  get placeholderFallback() {
    return this.placeholder || this.$t('shared.inputs.select.placeholder');
  }

  get buttonValue() {
    if (!this.selectedOptions.length) return this.placeholderFallback || '';
    if (this.selectedOptions.length > 1) return `${this.selectedOptions.length} selected`;
    return this.selectedOptions[0].label || '';
  }

  get optionRole() {
    return this.multiple ? 'menu-item-checkbox' : 'menu-item-radio';
  }

  get footerText() {
    if (this.hasValidationError) return this.firstValidationError;
    return this.help || '';
  }

  get styles() {
    const maxHeight = this.trayMaxHeight ? `${this.trayMaxHeight}px` : rem(280);
    return {
      '--tray-max-height': maxHeight,
    };
  }

  get customSelectClassName() {
    return {
      '-above': this.above,
      '-below': !this.above,
      '-focus': this.isOpen || this.isFocused,
      '-disabled': this.disabled,
      '-destructive': this.hasValidationError,
      '-force-custom': this.forceCustom,
    };
  }

  get nativeSelectClassName() {
    return {
      '-hidden-for-mouse-users': this.multiple,
      '-destructive': this.hasValidationError,
      '-placeholder': !this.selectedOptions.length,
      '-force-custom': this.forceCustom,
    };
  }

  get innerNativeValue() {
    if (this.multiple) return this.valueAsArray;
    return this.value === null ? '' : this.value;
  }

  set innerNativeValue(value: InputOptionValue | InputOptionValue[] | null) {
    this.$emit('change', value);
  }

  async mounted() {
    await this.$nextTick();
    if (!this.optionsMenuContainer) return;
    this.simplebarInstance = new SimpleBar(this.optionsMenuContainer);
    await this.$nextTick();
    this.optionsMenuContainer?.querySelector('[tabindex="0"]')?.removeAttribute('tabindex');
    this.scrollElement = this.simplebarInstance.getScrollElement();
  }

  @Watch('isOpen')
  onIsOpenChange(isOpen: boolean, wasOpen: boolean) {
    if (isOpen === wasOpen) return;

    if (isOpen) {
      document.body.addEventListener('click', () => {
        this.closeTray();
      });
      this.$nuxt.$on(GlobalDropdownEvents.Close, this.closeTray);
    } else {
      document.body.removeEventListener('click', () => {
        this.closeTray();
      });
      this.$nuxt.$off(GlobalDropdownEvents.Close, this.closeTray);
    }
  }

  beforeDestroy() {
    document.body.removeEventListener('click', () => {
      this.closeTray();
    });
    this.$nuxt.$off(GlobalDropdownEvents.Close, this.closeTray);
  }

  public onOptionSelected(option: InputOption) {
    if (option.disabled || option.id === null || option.id === '') return; // False and 0 are valid values
    if (!this.multiple) {
      this.$emit('change', option.id);
      this.$nuxt.$emit(GlobalDropdownEvents.Close);
    } else {
      const newValue = this.isChecked(option.id)
        ? this.valueAsArray.filter(v => v !== option.id)
        : [...this.valueAsArray, option.id];
      this.$emit('change', newValue);
    }
  }

  public onButtonClick() {
    if (!this.isOpen) this.$nuxt.$emit(GlobalDropdownEvents.Close);
    this.isOpen = !this.isOpen;
  }

  public tabTrapHandle(index: number): string | null {
    return index === 0 ? 'first' : index === this.options.length - 1 ? 'last' : null;
  }

  public closeTray(focusButton = false) {
    this.isOpen = false;
    if (focusButton) this.selectButton?.focus();
  }

  public onTrayClose() {
    if (!this.scrollElement) return;
    this.scrollElement.scrollTop = 0;
  }

  public isChecked(optionId: InputOptionValue) {
    return this.valueAsArray.includes(optionId);
  }

  public clearSelection() {
    const emptyValue = this.multiple ? [] : '';
    this.$emit('change', emptyValue);
    this.closeTray(true);
  }
}
