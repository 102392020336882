import { FormDto } from '../models';
import { Dictionary } from '../../../core';

export function getFormValueByShortLabel(
  fieldShortLabel: string,
  formData: Dictionary<string | string[]>,
  form: FormDto,
) {
  const emailField = getFieldByShortLabel(form, fieldShortLabel);
  return emailField != null ? formData[emailField.id] : '';
}

export function getFieldByShortLabel(form: FormDto, shortLabel: string) {
  return form.formSections.flatMap(x => x.formFields).find(x => x.shortLabel.en === shortLabel);
}

export function getFieldById(form: FormDto, id: string) {
  return form.formSections.flatMap(x => x.formFields).find(x => x.id === id);
}
