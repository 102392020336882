import { AxiosResponse } from 'axios';

export function extractFileNameFromHeaders(response: AxiosResponse) {
  const contentDisposition = response.headers['content-disposition'];
  let fileName = 'unknown';
  if (contentDisposition) {
    const fileNameMatch = contentDisposition.match(/filename=(.+);/);
    if (fileNameMatch.length === 2) fileName = fileNameMatch[1];
  }
  return fileName;
}
