export default {
  title: 'Live Chat',
  textarea_placeholder: 'Type a message',
  textarea_label: 'Message',
  aria_label_send: 'Send',
  aria_label_toggle_emoji: 'Toggle Emogi List',
  aria_label_profile: "{fullName}'s profile",
  title_toggle_emoji: 'React',
  no_results: 'Be the first to send a message.',
};
