
import { Component, Vue, Prop } from 'nuxt-property-decorator';
import { UserProfilePicture } from '../users';
import { ParticipantElement } from './types';

@Component({
  components: {
    UserProfilePicture,
  },
})
export default class ParticipantCard extends Vue {
  @Prop({ required: true }) readonly participant!: ParticipantElement;
  @Prop({ required: true }) readonly linkName!: string;
  @Prop(Boolean) readonly isParticipant!: boolean;
  @Prop(Boolean) readonly isOrganizer!: boolean;
}
