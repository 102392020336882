import { EventDto, getShortAddress } from '../services';
import { Component, Vue } from 'nuxt-property-decorator';
import { isCivicAddressValid, CivicAddressDto } from '../services';
import { TranslateResult } from 'vue-i18n';

export interface AddressMixins {
  getShortAddress(event: Partial<EventDto>): string;
  getPlace(event: Partial<EventDto>): string;
}

export const addressMixins: AddressMixins = {
  getShortAddress(event) {
    if (event.type === 'Online') {
      return this.$t('global.online_event');
    }

    if (!event.locality && !event.country) {
      return this.$t('global.no_location');
    }

    return getShortAddress(event);
  },
  getPlace(event) {
    if (!event.locality && !event.country) {
      return this.$t('global.no_location');
    }

    if (event.place && this.transl8(event.place)) {
      return this.transl8(event.place);
    }

    return getShortAddress(event);
  },
};

@Component
export class CivicAddressMixins extends Vue {
  getCivicAddressOutput(address: CivicAddressDto): string[] | TranslateResult[] {
    if (!isCivicAddressValid(address)) return [];

    const country = this.$t(`shared.geo.countries.${address.countryRegion}`);
    const province = address.stateProvince ? this.$t(`shared.geo.provinces.${address.stateProvince}`) : '';
    const countryLine = address.stateProvince ? `${country}, ${province}` : country;

    return [address.addressLine1, `${address.city}, ${address.postalCode}`, countryLine];
  }
}
