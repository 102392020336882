
import { Vue, Prop, Component, Emit, Model } from 'nuxt-property-decorator';
import { v4 as uuid } from 'uuid';
import Hint from '../../../Hint.vue';
import { Size } from '../../../../types';
import { TranslateResult } from '../../../../types/i18n';
import { rem } from '../../../../helpers';

@Component({ components: { Hint } })
export default class InputCheckboxSwitch extends Vue {
  @Prop({ type: String, default: '' }) readonly name!: string;
  @Prop({ type: String, default: 'sm' }) readonly size!: string;
  @Prop({ type: String, default: '' }) readonly label!: string | TranslateResult;
  @Prop({ type: String, default: '' }) readonly hint!: string | TranslateResult;
  @Prop(Boolean) readonly disabled!: boolean;

  @Model('change', { type: Boolean, default: false }) readonly value!: boolean;

  Sizes = Size;
  fallbackId = uuid();

  get sizeMap() {
    return {
      sm: {
        '--slider-height': rem(20),
        '--slider-width': rem(36),
        '--dot-width': rem(16),
        '--dot-active-left': rem(18),
      },
      md: {
        '--slider-height': rem(24),
        '--slider-width': rem(44),
        '--dot-width': rem(20),
        '--dot-active-left': rem(22),
      },
    };
  }

  get inputId() {
    return this.name ? this.name : this.fallbackId;
  }

  @Emit('change')
  emitChange() {
    return !this.value;
  }
}
