import type { TaxRules } from './FormFieldChoiceDto';
import { TranslatableField } from './TranslatableField';

export interface QuoteItem {
  itemId: string;
  formFieldShortLabel: TranslatableField;
  choiceShortLabel: TranslatableField;
  units: number;
  unitPrice: number;
  taxRuleAsString?: TaxRules;
}

export interface QuoteDto {
  quoteItems: QuoteItem[];
  subTotal: number;
  gst: number;
  qst: number;
  hst: number;
  vat: number;
  pst: number;
  total: number;
  couponCode: string | null;
  couponCodeAmount: number | null;
  couponCodeAmountType: string | null;
}

export function getDiscrepancies(quote: QuoteDto | null, newQuote: QuoteDto): QuoteItem[] {
  const result: QuoteItem[] = [];

  if (newQuote == null) return result;

  // Compare items
  for (const item of newQuote.quoteItems) {
    const oldItem = quote == null ? null : quote.quoteItems.find(x => x.itemId === item.itemId);
    if (oldItem != null && oldItem.unitPrice !== item.unitPrice) {
      result.push(item);
    } else if (oldItem == null) {
      result.push(item);
    }
  }

  return result;
}
