
import { Component, Vue, Prop } from 'nuxt-property-decorator';
import SimpleBar from 'simplebar';
import FilterClear from '../filters/FilterClear.vue';
import Collapsible from './Collapsible.vue';

enum FilterBarSections {
  Filters = 'filters',
  Sort = 'sort',
}

@Component({
  components: {
    Collapsible,
    FilterClear,
  },
})
export default class MobileFilterBar extends Vue {
  @Prop(Boolean) readonly hasAppliedFilters!: boolean;
  @Prop({ type: Array, default: Array }) readonly preserveKeys!: string[];

  $refs!: {
    sortMenu: HTMLMenuElement;
    filtersMenu: HTMLMenuElement;
  };

  FilterBarSections = FilterBarSections;
  displaySection: FilterBarSections | null = null;
  simplebarInstances: SimpleBar[] = [];

  get isTrayOpen() {
    return this.displaySection !== null;
  }

  get hasFilters() {
    return this.$slots.filters?.length;
  }

  get hasSort() {
    return this.$slots.sort?.length;
  }

  get showFilters() {
    return this.displaySection === FilterBarSections.Filters;
  }

  get showSort() {
    return this.displaySection === FilterBarSections.Sort;
  }

  async mounted() {
    await this.$nextTick();
    const { sortMenu, filtersMenu } = this.$refs;
    if (sortMenu) this.simplebarInstances.push(new SimpleBar(sortMenu));
    if (filtersMenu) this.simplebarInstances.push(new SimpleBar(filtersMenu));
  }

  public toggleSection(section: FilterBarSections | null) {
    if (this.displaySection === null) {
      this.displaySection = section;
      this.$emit('tray-opened');
    } else {
      if (section && this.displaySection !== section) {
        this.$once('transition-end', () => {
          this.displaySection = section;
          this.$emit('tray-opened');
        });
      }

      this.displaySection = null;
      this.$emit('tray-closed');
    }
  }
}
