
import { Component, Prop, Watch, Vue } from 'nuxt-property-decorator';
import { mergeRoute, getLocationFromRoute } from '../../helpers/router';

@Component({
  components: {},
})
export default class DateCarousel extends Vue {
  @Prop({ required: true }) readonly dates!: string[];
  @Prop({ required: true }) readonly activeCard!: string;
  @Prop({ type: Array, default: Array }) readonly emptyDates!: string[];
  @Prop(Boolean) readonly compact!: boolean;
  @Prop(Boolean) readonly liveApp!: boolean;

  currentPosition = 0;

  get datesWithStates() {
    return this.dates.map(date => ({
      date,
      isDisabled: this.emptyDates.includes(date),
      isActive: date === this.activeCard,
    }));
  }

  get hasControls() {
    return this.dates.length > this.visibleCards;
  }

  get lastIndex() {
    if (!this.hasControls) return 0;
    return this.dates.length - this.visibleCards;
  }

  get prevDisabled() {
    return this.currentPosition === 0;
  }

  get nextDisabled() {
    return this.currentPosition === this.lastIndex;
  }

  get translateValue() {
    return (this.currentPosition / this.visibleCards) * -100;
  }

  get innerStyles() {
    return { transform: `translateX(${this.translateValue}%)` };
  }

  get classNames() {
    return {
      '-has-controls': this.hasControls,
      '-compact': this.compact,
      '-live': this.liveApp,
    };
  }

  get contentClassNames() {
    return {
      '-gradient-left': this.hasControls && !this.prevDisabled,
      '-gradient-right': this.hasControls && !this.nextDisabled,
    };
  }

  get visibleCards() {
    if (!this.liveApp) return this.$device.tablet ? 5 : 3;
    return (this.$device.tablet && !this.$device.laptop) || this.$device.laptopLg ? 5 : 3;
  }

  @Watch('visibleCards', { immediate: true })
  onVisibleCardsChange(visibleCards: 5 | 3) {
    if (visibleCards === 5 && !this.hasControls) this.currentPosition = 0;
    this.centerPositionOnCurrentDate();
  }

  @Watch('activeCard')
  centerPositionOnCurrentDate() {
    const currentIndex = this.dates.findIndex(date => date === this.activeCard);
    if (!this.hasControls) return;
    const centerPosition = Math.max(currentIndex - Math.floor(this.visibleCards * 0.5), 0);
    this.currentPosition = Math.min(centerPosition, this.lastIndex);
  }

  public dateLink(date: string) {
    if (this.$route.query.date === date) return getLocationFromRoute(this.$route);
    const { ...currentQuery } = this.$route.query;
    const query = { ...currentQuery, date };
    return mergeRoute(this.$route, { query });
  }

  public next() {
    if (this.currentPosition === this.lastIndex) return;
    this.currentPosition += 1;
  }

  public prev() {
    if (this.currentPosition === 0) return;
    this.currentPosition -= 1;
  }
}
