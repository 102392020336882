import { BaseChannel, BaseChannelParams } from './BaseChannel';

type UserStatusCallbackFn = (isOnline: boolean) => void;

type UserStatusChannelParams = BaseChannelParams & {
  userId: string;
  onUpdateCallback: UserStatusCallbackFn;
};

export class UserStatusChannel extends BaseChannel {
  public userId: string;
  private onUpdateCallback: UserStatusCallbackFn;

  private get channel(): string {
    return `user.status/${this.userId}`;
  }

  constructor(params: UserStatusChannelParams) {
    super(params.connection);
    this.userId = params.userId;
    this.onUpdateCallback = params.onUpdateCallback;
  }

  subscribe() {
    if (this.isSubscribed) return;
    this.connection.on(this.channel, this.onUpdateCallback);
    this.connection.invoke('SubscribeUserStatus', this.userId);
    this.isSubscribed = true;
  }

  unsubscribe() {
    if (!this.isSubscribed) return;
    this.connection.off(this.channel, this.onUpdateCallback);
    this.connection.invoke('UnsubscribeUserStatus', this.userId);
    this.isSubscribed = false;
  }
}
