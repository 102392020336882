export default {
  file_upload: {
    modal_title: 'Modifier le fichier',
    drop_file: 'Déposer un fichier ou',
    browser: 'parcourir votre ordinateur pour ajouter',
    instructions: 'La taille du fichier doit être de 100 Mo ou moins',
    instructions_slides: 'La taille du fichier doit être de 10 Mo ou moins',
    instructions_video: 'La taille du fichier doit être de 1 Go ou moins',
    uploading: 'Téléchargement en cours...',
    caption_title: 'Titre',
    description_placeholder: 'Veuillez saisir une brève description...',
    missing_description: 'Aucune description. Cliquez modifier pour mettre à jour.',
  },
  hidden_field: 'Champ caché',
  unavailable: 'Non-disponible',
  choice: {
    has_quantity: 'Quantité disponible',
    cost: 'Coût',
  },
  authors: {
    add: 'Ajouter',
    empty: "Personne n'a encore été ajouté à cette soumission",
    label: 'Auteurs',
  },
};
