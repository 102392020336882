import { TranslateResult } from './i18n';

export enum TiptapExtension {
  Heading = 'heading',
  Bold = 'bold',
  Italic = 'italic',
  Underline = 'underline',
  Subscript = 'subscript',
  Superscript = 'superscript',
  Link = 'link',
  TextAlign = 'textAlign',
  Quote = 'quote',
  Callout = 'callout',
  Video = 'video',
  HorizontalLine = 'horizontalLine',
  BulletList = 'bulletList',
  OrderedList = 'orderedList',
  WordCount = 'WordCount',
}

export interface TiptapMenuButton {
  id: string;
  extension: TiptapExtension;
  label: TranslateResult;
  icon: string;
  action: string;
  ariaLabel: TranslateResult;
  params?: string;
}
