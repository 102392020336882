
import { Component, namespace, Vue, Watch } from 'nuxt-property-decorator';
import FourwavesLogoFull from 'fourwaves-shared/src/images/fourwaves-dark-full.png';

const FooterModule = namespace('footer');

@Component({})
export default class FwBadge extends Vue {
  @FooterModule.State isFooterVisible!: boolean;

  readonly FourwavesLogoFull = FourwavesLogoFull;

  isBadgeHidden = false;

  @Watch('isFooterVisible', { immediate: true })
  onVisibilityChange() {
    this.isBadgeHidden = this.isFooterVisible;
  }
}
