
import { Component, Prop, Emit, Watch, mixins } from 'nuxt-property-decorator';
import DatePicker from 'vue2-datepicker';
import { isValidDate } from '../../../../helpers';
import Collapsible from '../../../layout/Collapsible.vue';
import InputValidationMixin from '../InputValidationMixin';

@Component({
  components: {
    DatePicker,
    Collapsible,
  },
})
export default class InputTime extends mixins(InputValidationMixin) {
  @Prop({ type: String, required: true }) readonly name!: string;
  @Prop({ type: [String, Array], default: null }) readonly value!: string | null;
  @Prop({ type: String, default: null }) readonly placeholder!: string | null;
  @Prop({ type: String, default: null }) readonly label!: string | null;
  @Prop({ type: String, default: null }) readonly minTime!: string | null;
  @Prop({ type: String, default: null }) readonly maxTime!: string | null;
  @Prop({ type: String, default: null }) readonly clearWhenBefore!: string | null;
  @Prop({ type: String, default: null }) readonly clearWhenAfter!: string | null;
  @Prop(Boolean) readonly validateOnChange!: boolean;
  @Prop(Boolean) readonly disabled!: boolean;
  @Prop(Boolean) readonly readonly!: boolean;
  @Prop(Boolean) readonly appendToBody!: boolean;

  selectedTime: string | null = null;
  isDatePickerOpen = false;
  inputFormat = 'HH:mm';

  get inputClass() {
    const classNames = [
      ['input-date-input', true],
      ['-focus', this.isDatePickerOpen],
      ['-readonly', this.readonly],
      ['-destructive', this.hasValidationError],
    ];

    return classNames.flatMap(([className, isApplied]) => (isApplied ? className : [])).join(' ');
  }

  get placeholderWithFallback() {
    if (this.placeholder) return this.placeholder;
    return this.$t('shared.inputs.date.select_time');
  }

  get datePickerLocalizedCalendar() {
    return {
      days: this.$t('shared.inputs.date.calendar.days'),
      months: this.$t('shared.inputs.date.calendar.months'),
      placeholder: {
        date: this.$t('shared.inputs.date.select_date'),
        timeRange: this.$t('shared.inputs.date.select_time'),
      },
    };
  }

  get formatter() {
    return {
      stringify: (date: Date) => {
        return isValidDate(date) ? this.userTimezoneMoment(date).format(this.inputFormat) : '';
      },
      parse: (dateString: string) => {
        const moment = this.userTimezoneMoment(dateString);
        return moment.isValid() ? this.userTimezoneMoment(dateString).toDate() : null;
      },
    };
  }

  get selectedTimeMoment() {
    if (!this.selectedTime) return null;
    const [hours, minutes] = this.selectedTime.split(':').map(Number);
    return this.userTimezoneMoment().set({ hours, minutes });
  }

  get minTimeMoment() {
    if (!this.minTime) return null;
    const [hours, minutes] = this.minTime.split(':').map(Number);
    return this.userTimezoneMoment().set({ hours, minutes });
  }

  get maxTimeMoment() {
    if (!this.maxTime) return null;
    const [hours, minutes] = this.maxTime.split(':').map(Number);
    return this.userTimezoneMoment().set({ hours, minutes });
  }

  get clearBeforeMoment() {
    if (!this.clearWhenBefore) return null;
    const [hours, minutes] = this.clearWhenBefore.split(':').map(Number);
    return this.userTimezoneMoment().set({ hours, minutes });
  }

  get clearAfterMoment() {
    if (!this.clearWhenAfter) return null;
    const [hours, minutes] = this.clearWhenAfter.split(':').map(Number);
    return this.userTimezoneMoment().set({ hours, minutes });
  }

  get shouldValidateOnClose() {
    return this.validateOnChange || this.validation != null;
  }

  @Watch('value', { immediate: true })
  onValueChange(value: string | null) {
    if (!value && !this.selectedTime) return;
    const moment = this.$moment(value, this.inputFormat);
    this.selectedTime = moment.isValid() ? moment.format(this.inputFormat) : null;
  }

  @Watch('clearBeforeMoment')
  async onClearBeforeChange() {
    if (
      this.selectedTime &&
      this.clearBeforeMoment &&
      this.clearBeforeMoment?.isSameOrAfter(this.selectedTimeMoment, 'minute')
    ) {
      this.selectedTime = null;
      this.emitInput();
      await this.$nextTick();
      this.$validator.validate(this.name);
    }
  }

  @Watch('clearAfterMoment')
  async onClearAfterChange() {
    if (
      this.selectedTime &&
      this.clearAfterMoment &&
      this.clearAfterMoment?.isSameOrBefore(this.selectedTimeMoment, 'minute')
    ) {
      this.selectedTime = null;
      this.emitInput();
      await this.$nextTick();
      this.$validator.validate(this.name);
    }
  }

  @Emit('input')
  emitInput(): string | null {
    return this.selectedTime;
  }

  public isDisabledTime(date: Date) {
    if (!this.minTime && !this.maxTime) return false;
    const reqDate = this.userTimezoneMoment(date);
    const isAfterMin = !this.minTimeMoment || reqDate.isAfter(this.minTimeMoment, 'minute');
    const isBeforeMax = !this.maxTimeMoment || reqDate.isBefore(this.maxTimeMoment, 'minute');
    return !isAfterMin || !isBeforeMax;
  }

  public userTimezoneMoment(date?: string | Date | null) {
    if (!date) return this.$moment.tz(new Date(), this.$moment.tz.guess());

    return isValidDate(date)
      ? this.$moment.tz(date, this.$moment.tz.guess())
      : this.$moment.tz(date, this.inputFormat, this.$moment.tz.guess());
  }

  public closeDatePicker() {
    this.isDatePickerOpen = false;
    this.onDatePickerClose();
  }

  public async onDatePickerClose() {
    this.emitInput();
    await this.$nextTick();
    if (!this.shouldValidateOnClose && !this.$validator.errors.has(this.name)) return;
    this.$validator.validate(this.name);
  }
}
