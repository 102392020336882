
import { mixins, Component, Prop } from 'nuxt-property-decorator';
import FilterMixin from './FilterMixin';

@Component
export default class FilterPageSize extends mixins(FilterMixin) {
  @Prop({ default: Array }) readonly sizes!: number[];

  get defaultPageSize() {
    const [defaultPageSize = 0] = this.sizes;
    return defaultPageSize;
  }

  get currentPageSize() {
    return !this.$route.query[this.queryKey] ? Number(this.defaultPageSize) : Number(this.$route.query[this.queryKey]);
  }

  public onClick(newPageSize: number) {
    if (!this.currentPageSize || newPageSize === this.currentPageSize) return;
    this.updateQuery(newPageSize, newPageSize === this.defaultPageSize);
  }
}
