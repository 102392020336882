
import { Component, Prop, Vue, Watch } from 'nuxt-property-decorator';
import sanitizeHtml from 'sanitize-html';
import DynamicElement from '../DynamicElement';

type PositionedHtml = Partial<Record<InsertPosition, string | null>>;

type PositionedHtmlProp = {
  before?: string | null;
  after?: string | null;
};

@Component({
  components: { DynamicElement },
})
export default class RawHtml extends Vue {
  @Prop({ type: String, default: 'div' })
  readonly tag!: string;

  @Prop({ type: [String, Object], default: Object })
  readonly html!: string | PositionedHtmlProp;

  @Prop(Boolean)
  readonly preserveBreaks!: boolean;

  @Prop(Boolean)
  readonly alignLeft!: boolean;

  get htmlContent(): PositionedHtml {
    const { before = null, after = null } = typeof this.html === 'string' ? { after: this.html } : this.html;
    return { afterbegin: before, beforeend: after };
  }

  @Watch('htmlContent', { immediate: true })
  async renderHTML() {
    await this.$nextTick();
    const slots = this.$slots.default || [];
    const slotNodes = slots.map(({ elm }) => elm?.cloneNode(true));
    const positions = Object.keys(this.htmlContent) as InsertPosition[];

    // do not proceed if the element does not exist
    if (!this.$el) return;

    this.$el.innerHTML = '';
    slotNodes.forEach(node => this.$el.appendChild(node!));

    // sanitizer configuration
    const allowedAttributes = sanitizeHtml.defaults.allowedAttributes;
    allowedAttributes['*'] = ['data-test-id'];
    const allowedClasses = {
      span: ['nowrap'], // receipt page
    };

    positions.forEach(position => {
      const content = this.htmlContent[position];
      if (!content) return;
      this.$el.insertAdjacentHTML(position, sanitizeHtml(content, { allowedAttributes, allowedClasses }));

      const links: HTMLAnchorElement[] = Array.from(this.$el.querySelectorAll('a[href^="http"]'));
      links.forEach(externalLink => {
        externalLink.target = '_blank';
        externalLink.rel = 'noopener';
      });
    });
  }
}
