
import { Vue, Component, Prop } from 'nuxt-property-decorator';

@Component({})
export default class CountProgressBar extends Vue {
  @Prop({ type: Number, required: true }) readonly count!: number;
  @Prop({ type: Number, required: true }) readonly total!: number;
  @Prop({ type: String, required: true }) readonly label!: string;

  get outOf() {
    return ` ${this.$t('shared.global.of')} ${this.total}`;
  }

  get sizingProperties() {
    const percentage = (this.count / this.total) * 100;
    return {
      '--percentage': percentage < 100 ? `${percentage}%` : '100%',
    };
  }
}
