
import { Component, Vue, Prop } from 'nuxt-property-decorator';
import { TranslateResult } from '../../types/i18n';
import { Size } from '../../types';

@Component({})
export default class EmptyState extends Vue {
  @Prop({ required: true }) title!: TranslateResult | string;
  @Prop({ required: true }) subtitle!: TranslateResult | string;
  @Prop({ type: String, default: 'md' }) readonly size!: string;
  @Prop(Boolean) readonly dark!: boolean;

  readonly sizes = Size;

  hasImage = false;
  hasCta = false;

  created() {
    this.detectSlotsContent();
  }

  beforeUpdate() {
    this.detectSlotsContent();
  }

  public detectSlotsContent() {
    this.hasImage = !!this.$slots.image?.length;
    this.hasCta = !!this.$slots.cta?.length;
  }
}
