import { Intercom } from '@intercom/messenger-js-sdk';
const APP_ID = 'o9b2hrv8';

interface IntercomUserData {
  name?: string;
  email?: string;
  user_id?: string;
}

export default ({ $auth }) => {
  if (!process.client) return;

  const userData: IntercomUserData = {};

  if ($auth?.loggedIn) {
    userData.name = `${$auth.user.firstName === null ? '' : $auth.user.firstName + ' '}${$auth.user.lastName ?? ''}`;
    userData.email = $auth.user.email;
    userData.user_id = $auth.user.id;
  }

  Intercom({ app_id: APP_ID, hide_default_launcher: true, ...userData });
};
