
import { Component, Vue, Prop, namespace } from 'nuxt-property-decorator';
import {
  FileUploadDto,
  FormDto,
  FormEntryDto,
  FormFieldDto,
  FormFieldType,
  getFormEntryValue,
  PageDto,
  PageType,
  transl8,
} from '../../services';

const EventsModule = namespace('events');

@Component
export default class ParticipantOtherInformations extends Vue {
  @EventsModule.State pages!: PageDto[];
  @Prop({ required: true }) formEntry!: FormEntryDto;
  @Prop({ required: true }) form!: FormDto;
  @Prop({ required: true }) pageType!: PageType;

  readonly FormFieldType = FormFieldType;

  fields: FormFieldDto[] = [];
  uploadedFiles: FileUploadDto[] = [];

  async created() {
    // Get all fields that can be shown
    const presentationPage = this.pages.find(({ type }) => type === this.pageType);
    const baseFields = presentationPage ? presentationPage.formFields : [];
    const formSectionFields = this.form.formSections.flatMap(fs => fs.formFields);

    const filters = [
      ({ fieldType }) => fieldType !== FormFieldType.Poster,
      ({ fieldType }) => fieldType !== FormFieldType.Video,
      ({ fieldType }) => fieldType !== FormFieldType.Slides,
      ({ fieldType }) => fieldType !== FormFieldType.Images,
      ({ id }) => this.formEntry.formEntryValues.some(({ formFieldId }) => formFieldId === id),
      ({ id }) => formSectionFields.some(field => field.id === id),
    ];

    this.fields = baseFields.filter(field => filters.every(filter => filter(field)));

    // Preload all files
    this.uploadedFiles = await Promise.all(
      this.fields
        .filter(({ fieldType }) => fieldType === FormFieldType.File)
        .map(async field => await this.$api.getFile(getFormEntryValue(this.formEntry, this.form, field.id))),
    );
  }

  public getTextValue(field: FormFieldDto): string {
    return getFormEntryValue(this.formEntry, this.form, field.id);
  }

  public getMultipleChoices(field: FormFieldDto): string {
    const formEntryValue = getFormEntryValue(this.formEntry, this.form, field.id);

    const { formFieldChoices } = this.form.formSections
      .flatMap(({ formFields }) => formFields)
      .find(({ id }) => id === field.id)!;

    return formEntryValue
      .split(',')
      .map(choiceId => formFieldChoices.find(({ id }) => id === choiceId))
      .map(choice => transl8(choice!.shortLabel))
      .join(', ');
  }

  public getFileUrls(field: FormFieldDto): FileUploadDto[] {
    return getFormEntryValue(this.formEntry, this.form, field.id)
      .split(',')
      .filter(fileId => this.uploadedFiles.find(({ id }) => id === fileId))
      .map(fileId => this.uploadedFiles.find(({ id }) => id === fileId)!);
  }

  public isSelectionField({ fieldType }: FormFieldDto) {
    return (
      fieldType === FormFieldType.Checkboxes ||
      fieldType === FormFieldType.Dropdown ||
      fieldType === FormFieldType.Radios
    );
  }

  public isCountryList({ fieldType }: FormFieldDto) {
    return fieldType === FormFieldType.CountryList;
  }

  public getUnabbreviatedLocation(field: FormFieldDto) {
    const formEntryValue = getFormEntryValue(this.formEntry, this.form, field.id);
    if (formEntryValue.includes('/')) {
      const fieldsOfLocation = formEntryValue.split('/');
      return `${this.$t(`countries.${fieldsOfLocation[0]}`)}/${this.$t(`provinces.${fieldsOfLocation[1]}`)}`;
    } else return this.$t(`countries.${formEntryValue}`);
  }
}
