
import { Component, Prop, Vue } from 'nuxt-property-decorator';
import { pick } from 'lodash-es';

@Component
export default class FilterClear extends Vue {
  @Prop({ type: Array, default: Array }) readonly preserveKeys!: string[];
  @Prop({ type: String, default: null }) readonly label!: string;
  @Prop({ type: String, default: 'tertiary-color' }) readonly hierarchy!: string;
  @Prop({ type: String, default: null }) readonly iconLeading!: string | null;

  get actionLabel() {
    return this.label || this.$t('shared.global.clear_filters');
  }

  clearFilters() {
    this.$emit('clear');
    if (!this.preserveKeys.length) return this.$router.push({ query: {} });
    this.$router.push({ query: pick(this.$route.query, this.preserveKeys) });
  }
}
