
import { Component, Prop, Vue } from 'nuxt-property-decorator';
import videojs, { VideoJsPlayerOptions } from 'video.js';
import frenchLang from 'video.js/dist/lang/fr.json';

@Component
export default class VideoPlayer extends Vue {
  @Prop({ required: true }) source!: string;

  $refs!: {
    videoPlayer?: HTMLVideoElement;
  };

  player: videojs.Player | null = null;

  videoOptions: VideoJsPlayerOptions = {
    autoplay: true,
    controls: true,
    sources: [{ src: this.source, type: 'video/mp4' }],
    language: 'en',
    controlBar: {
      pictureInPictureToggle: false,
    },
  };

  async mounted() {
    await this.$nextTick();
    videojs.addLanguage('fr', frenchLang);
    this.videoOptions.language = this.$i18n.locale;
    if (this.$refs.videoPlayer)
      this.player = videojs(this.$refs.videoPlayer, this.videoOptions, () => {
        this.player?.on('contextmenu', event => event.preventDefault());
      });
  }

  beforeDestroy() {
    if (this.player) this.player.dispose();
  }
}
