export enum GetEventsQueryStatus {
  All = 'all',
  Active = 'active',
  Draft = 'draft',
  Past = 'past',
}

export interface GetEventsModel {
  page?: number;
  pageSize?: number;
  status: GetEventsQueryStatus;
  searchTerm?: string;
  userRoles?: string[];
  organizationId?: string;
  sortDirection?: 'ascending' | 'descending';
  sortField?: 'startDate' | 'state';
}
