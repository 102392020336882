import { MutationTree } from 'vuex';
import { Route } from 'vue-router';

export type HistoryState = {
  previousRoute: Route | null;
};

export const state = (): HistoryState => ({
  previousRoute: null,
});

export const mutations: MutationTree<HistoryState> = {
  setPreviousRoute(state, previousRoute: Route | null) {
    state.previousRoute = previousRoute;
  },
};
