import type { BasePaginatedModel } from './BasePaginatedModel';
import type { ReviewStatus } from './ReviewStatus';

export interface GetReviewsModel extends BasePaginatedModel {
  eventId: string;
  searchTerm?: string;
  userId?: string;
  formEntryId?: string;
  submissionFormEntryId?: string;
  status?: ReviewStatus | '' | null;
  subsetIds?: string[];
  excludedIds?: string[];
}

export function toReviewsSearchParams(model: GetReviewsModel): URLSearchParams {
  const query = new URLSearchParams(model as any);
  // URLSearchParams do not handles array, ex: foo=bar1&foo=bar2
  if (model.subsetIds && model.subsetIds.length > 0) {
    query.delete('subsetIds');
    model.subsetIds.forEach(x => query.append('subsetIds', x));
  }
  if (model.excludedIds && model.excludedIds.length > 0) {
    query.delete('excludedIds');
    model.excludedIds.forEach(x => query.append('excludedIds', x));
  }
  query.delete('eventId');
  return query;
}
