import { HubConnection } from '@microsoft/signalr';

export type BaseChannelParams = {
  connection: HubConnection;
};

export class BaseChannel {
  protected connection: HubConnection;
  protected isSubscribed = false;

  constructor(connection: HubConnection) {
    if (!connection) throw new Error('connection not set');
    this.connection = connection;
  }
}
