
import { Component, Vue, Prop, namespace, Ref } from 'nuxt-property-decorator';
import linkifyElement from 'linkify-element';
import UserProfilePicture from '../users/UserProfilePicture.vue';
import { isSuccess, isValidationError, MessageDto } from '../../services';
import MoreDropdown from './MoreDropdown.vue';

const EventsModule = namespace('events');

@Component({
  $_veeValidate: {
    validator: 'new',
  },
  components: {
    MoreDropdown,
    UserProfilePicture,
  },
})
export default class Comment extends Vue {
  @Ref() readonly text!: HTMLElement;
  @EventsModule.State currentTime!: string;
  @EventsModule.Getter isOrganizer!: boolean;
  @Prop({ required: true }) message!: MessageDto;
  @Prop({ default: null }) presentingUserId!: string | null;
  @Prop(Boolean) isLiveStream!: boolean;
  @Prop(Boolean) isDarkMode!: boolean;

  newTextUpdate = '';
  editMode = false;

  get isPresenter(): boolean {
    return this.message.userId === this.presentingUserId;
  }

  get isOwner(): boolean {
    return this.message.userId === this.$auth.user.id;
  }

  get fullName(): string {
    return `${this.message.firstName} ${this.message.lastName}`;
  }

  get hasActionPermission(): boolean {
    return this.$auth.loggedIn;
  }

  get canInteract(): boolean {
    if (!this.hasActionPermission) return false;
    return this.$auth.user.id === this.message.userId || this.isOrganizer;
  }

  get canEdit(): boolean {
    if (!this.hasActionPermission) return false;
    return !this.isLiveStream && this.isOwner;
  }

  mounted() {
    this.linkifyText();
  }

  beforeDestroy() {
    document.removeEventListener('click', this.clickOutside);
  }

  public linkifyText() {
    if (this.text) {
      linkifyElement(this.text, { defaultProtocol: 'https', rel: 'noopener noreferrer', target: '_blank' });
    }
  }

  public openEditMode() {
    this.newTextUpdate = this.message.text;
    this.editMode = true;
    setTimeout(() => document.addEventListener('click', this.clickOutside), 50);

    this.$nextTick(() => {
      const input = this.$refs.commentInputUpdate as any;
      if (input) {
        const inputLength = input.value.length;

        input.focus();
        input.setSelectionRange(inputLength, inputLength);
      }
    });
  }

  public closeEditMode() {
    document.removeEventListener('click', this.clickOutside);
    this.editMode = false;
  }

  public clickOutside(event) {
    if (!this.$el.contains(event.target)) {
      this.closeEditMode();
    }
  }

  public async handleEdit() {
    const successMessage = this.$t('shared.qna.success_message.updated') as string;
    if (await this.$validator.validateAll()) {
      const response = await this.$api.updateMessage(this.message.id, this.newTextUpdate);

      if (isSuccess(response)) {
        this.$toast.success(successMessage);
        this.editMode = false;
        this.$nextTick(this.linkifyText);
      } else if (isValidationError(response)) {
        this.feedErrorBag(response);
      }
    }
  }

  public async handleDelete() {
    const successMessage = this.$t('shared.qna.success_message.deleted') as string;
    const response = await this.$api.deleteMessage(this.message.id);

    if (isSuccess(response)) {
      this.$toast.success(successMessage);
    } else if (isValidationError(response)) {
      this.feedErrorBag(response);
    }
  }

  public async handleCmdEnter(event: KeyboardEvent) {
    if ((event.metaKey || event.ctrlKey) && event.key === 'Enter') {
      await this.handleEdit();
    }
  }

  public async toggleUpvote() {
    if (!this.message.isUpvotedByCurrentUser) {
      const response = await this.$api.upvoteMessage(this.message.id);
      if (isValidationError(response)) {
        this.feedErrorBag(response);
      }
    } else {
      const response = await this.$api.deleteUpvoteMessage(this.message.id);
      if (isValidationError(response)) {
        this.feedErrorBag(response);
      }
    }
  }
}
