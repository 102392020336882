
import { Component, Prop, Vue } from 'nuxt-property-decorator';
import Badge from '../Badge.vue';
import { TrackDto } from '../../services';
import { trackColorsList } from '../../constants';

@Component({
  components: {
    Badge,
  },
})
export default class TrackBadge extends Vue {
  @Prop({ required: true }) readonly track!: TrackDto;

  get currentTrackStyles(): Record<string, string> | '' {
    const color = trackColorsList.find(({ id }) => id === this.track.color);

    if (!color) return '';

    return {
      backgroundColor: color.bgColor,
      borderColor: color.borderColor,
      color: color.textColor,
    };
  }
}
