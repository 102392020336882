import { debounce } from 'lodash-es';

export type EventHandler<E = unknown> = (event: E) => void;

export function createThrottledHandler<E = any>(handler: EventHandler<E>, throttleTime?: number): EventHandler<E> {
  let isQueued = false;
  const lastCall = debounce<EventHandler<E>>(handler, (throttleTime || 80) * 1.25);

  return (event: E) => {
    if (isQueued) return lastCall(event);
    isQueued = true;
    lastCall.cancel();

    const currentCall = () => {
      handler(event);
      isQueued = false;
    };

    if (!throttleTime) requestAnimationFrame(currentCall);
    else setTimeout(currentCall, throttleTime);
  };
}
