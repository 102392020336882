import { EventType } from './EventDto';
import { TranslatableField } from './TranslatableField';

export enum EventState {
  Past = 'Past',
  Live = 'Live',
  Future = 'Future',
}

export interface EventListingDto {
  id: string;
  name: TranslatableField;
  banner: TranslatableField;
  startDate: string;
  endDate: string;
  type: EventType;
  urlSegment: string;
  timeZone: string;
  formattedAddress: string;
  latitude: number;
  longitude: number;
  streetNumber: string;
  route: string;
  postalCode: string;
  country: string;
  administrativeAreaLevel1: string;
  locality: string;
  place: TranslatableField;
  organizationId: string | null;
  organizationName?: TranslatableField;
  state: EventState;
  registrationCount: number | null;
  submissionCount: number | null;
  publishedAt: string | null;
}
