
import { Component, Prop, Vue } from 'nuxt-property-decorator';
import { InputText } from '../design-system';
import { BubbleMenu, Editor, isMarkActive } from '@tiptap/vue-2';
import { BubbleMenuPluginProps } from '@tiptap/extension-bubble-menu';

@Component({
  components: {
    InputText,
    BubbleMenu,
  },
})
export default class TiptapLinkBubble extends Vue {
  @Prop({ required: true }) editor!: Editor;

  link = '';
  isShown = true;
  isEditMode = false;

  tippyOptions: BubbleMenuPluginProps['tippyOptions'] = {
    placement: 'bottom',
    offset: [0, 8],
    animation: 'tiptap-bubble',
    duration: [125, 125],
    onShow: this.init,
    onBeforeUpdate: this.init,
    onHide: this.onHide,
    interactive: true,
    hideOnClick: true,
    appendTo: () => document.body,
  };

  public shouldShow() {
    return isMarkActive(this.editor.view.state, 'link') && this.isShown;
  }

  public init() {
    this.link = this.editor.getAttributes('link').href || '';
    this.isEditMode = false;
  }

  public onHide() {
    this.isShown = true;
  }

  public applyLink() {
    if (!this.link) return this.clearLink();
    this.editor.chain().focus().extendMarkRange('link').setLink({ href: this.link }).run();
    this.isShown = false;
  }

  public clearLink() {
    this.editor.chain().focus().extendMarkRange('link').unsetLink().run();
    this.isShown = false;
  }
}
