export default {
  virtual: 'Virtuel',
  live: 'En cours',
  presentation: {
    title: 'Présentations',
    show_all_in_presentations: 'Voir dans la page de présentations',
    no_results: "Il n'y a pas de présentation dans cette session.",
    presenting_now: 'A lieu maintenant',
    presented_by: 'Présenté par  ',
  },
  bookmark: 'Mettre en favori',
  unbookmark: 'Retirer le favori',
  bookmarked: 'Favori',
  filter_bookmark: 'Mes Favoris',
  search_placeholder: 'Rechercher par titre de session, sujet ou autre',
  session_count: 'Aucune session | {count} session | {count} sessions',
  tracks_filter: 'Thématiques',
  minute_countdown: 'Débute dans {count} minute | Débute dans {count} minutes',
  less_than_a_minute: "Débute dans moins d'une minute",
  presentation_count_title: 'Présente {count} présentation | Présente {count} presentations',
  poster_count_title: 'Présente {count} affiche | Présente {count} affiches',
  cta_poster: 'Aller à la salle des affiches',
  cta_livestream: 'Rejoindre',
  watch_video: 'Regarder la vidéo',
  prev_date_label: 'Montrer la prochaine date',
  next_date_label: 'Montrer la date précédente',
};
