import { Component, Vue, namespace } from 'nuxt-property-decorator';
import { EventDto, ScheduleDto, RoomDto, LiveStreamType, TrackDto } from '../../services';
import { Dictionary } from '../../core';
import { normalizeDiacritics } from '../../helpers';

interface SessionRoom {
  session: ScheduleDto;
  room?: RoomDto;
}

interface QueryFilterFields {
  date: string | null;
  isVirtual: boolean;
  isLive: boolean;
  isBookmarked: boolean;
  searchTerm: string;
  trackIds: string[];
}

type SessionFilterFn = (sessionRoom: SessionRoom) => boolean;

const EventsModule = namespace('events');

@Component
export default class SessionIndexMixin extends Vue {
  @EventsModule.State currentEvent!: EventDto;
  @EventsModule.Getter isParticipant!: boolean;
  @EventsModule.Getter isOrganizer!: boolean;
  @EventsModule.State currentTime!: string;
  @EventsModule.State sessions!: ScheduleDto[];
  @EventsModule.State tracks!: TrackDto[];
  @EventsModule.State rooms!: RoomDto[];
  @EventsModule.Getter liveSessions!: ScheduleDto[];
  @EventsModule.Getter nextRelevantSessionDate!: string | null;
  @EventsModule.Mutation updateSession!: (session: ScheduleDto) => void;

  readonly LiveStreamType = LiveStreamType;

  fallBackModel: QueryFilterFields = {
    date: null,
    isVirtual: false,
    isLive: false,
    isBookmarked: false,
    searchTerm: '',
    trackIds: [],
  };

  get currentModel() {
    return { ...this.fallBackModel, ...this.$route.query };
  }

  get sessionFilters() {
    const filters: SessionFilterFn[] = [];
    if (this.currentModel.isBookmarked) filters.push(this.isSessionBookmarked);
    if (this.currentModel.isLive) filters.push(this.isSessionLive);
    if (this.currentModel.isVirtual) filters.push(this.isSessionVirtual);
    if (this.currentModel.trackIds.length) filters.push(this.isSessionTrackMatch);
    if (this.currentModel.searchTerm) filters.push(this.isSessionSearchMatch);
    return filters;
  }

  get schedules(): Dictionary<Dictionary<SessionRoom[]>> {
    const scheduleMap = {};

    for (const session of this.sessions) {
      const startMoment = this.$moment(session.startDate);
      const dateKey = startMoment.format('YYYY-MM-DD');
      const timeKey = startMoment.format('LT');
      scheduleMap[dateKey] = scheduleMap[dateKey] || {};
      const existingTime = scheduleMap[dateKey][timeKey] || [];
      const room = this.rooms.find(room => room.id === session.roomId);
      const isSessionPreserved = this.sessionFilters.every(filter => filter({ session, room }));
      if (isSessionPreserved) scheduleMap[dateKey][timeKey] = [...existingTime, { session, room }];
    }

    return scheduleMap;
  }

  get selectedDateSchedule(): Dictionary<SessionRoom[]> {
    return this.schedules[this.currentDate] || {};
  }

  get selectedDateHasSessions() {
    return Object.keys(this.selectedDateSchedule).length > 0;
  }

  get trackOptions() {
    return this.tracks
      .filter(({ id }) =>
        this.sessions.some(({ sessionTracks }) => sessionTracks.some(({ trackId }) => trackId === id)),
      )
      .map(({ id, name }) => ({ id, label: this.transl8(name) }));
  }

  get sessionDates(): string[] {
    return Object.keys(this.schedules).sort((a, b) => (new Date(b) < new Date(a) ? 1 : -1));
  }

  get emptyDates() {
    return this.sessionDates.filter(date => !Object.keys(this.schedules[date]).length);
  }

  get currentDate(): string {
    return this.currentModel.date
      ? this.currentModel.date
      : this.$moment(this.nextRelevantSessionDate).format('YYYY-MM-DD');
  }

  get currentDayOfMonth() {
    const [dayOfMonth] = this.currentDate.split('-').slice(-1);
    return dayOfMonth || new Date().getDate();
  }

  public isSessionSearchMatch({ session }: SessionRoom) {
    const content = `${this.transl8(session.title)} ${this.transl8(session.description)}`;
    const normalizedContent = normalizeDiacritics(content.toLowerCase());
    const normalizedSearchTerm = normalizeDiacritics(this.currentModel.searchTerm.toLowerCase());
    return normalizedContent.includes(normalizedSearchTerm);
  }

  public isSessionTrackMatch({ session }: SessionRoom) {
    return session.sessionTracks.some(({ trackId }) => this.currentModel.trackIds.includes(trackId));
  }

  public isSessionBookmarked({ session }: SessionRoom) {
    return session.isBookmarkedByCurrentUser;
  }

  public isSessionLive({ session }: SessionRoom) {
    return this.liveSessions.some(({ id }) => id === session.id);
  }

  public isSessionVirtual({ room }: SessionRoom) {
    return !!room && room.liveStreamType !== LiveStreamType.None;
  }
}
