
import { Vue, Component, Watch, Prop } from 'nuxt-property-decorator';

@Component({ components: {} })
export default class MenuDropdown extends Vue {
  @Prop(Boolean) isSubMenu!: boolean;

  isDropdownOpen = false;

  @Watch('$route.path')
  onRouteChanged() {
    this.isDropdownOpen = false;
  }

  public onDropdownClose() {
    this.$emit('on-dropdown-close');
  }
}
