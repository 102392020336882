import { initiateWebSocket } from '~/services';
import { EventsState } from '~/types/state';

export default async function ({ $config, $cookies, $auth, store }, inject) {
  if ($auth?.loggedIn && process.client) {
    inject(
      'realtime',
      await initiateWebSocket(
        $config.API_URL,
        $cookies.get('auth._token.local'),
        (store.state.events as EventsState).currentEvent!.id,
      ),
    );
  } else {
    inject('realtime', null);
  }
}
