import * as en from './en';
import * as fr from './fr';

const sharedLocales = { en, fr };

export function addSharedLocales(lang: 'en' | 'fr', appLocales: Record<string, any>) {
  return {
    ...appLocales,
    shared: sharedLocales[lang],
  };
}
