
import { TranslateResult } from 'vue-i18n';
import { Component, Vue, namespace } from 'nuxt-property-decorator';
import { EventDto, PageDto, transl8, PageType, FormState, FormType } from 'fourwaves-shared';

const EventsModule = namespace('events');
const HeaderModule = namespace('header');

@Component({})
export default class Header extends Vue {
  @EventsModule.State pages!: PageDto[];
  @EventsModule.Getter currentEvent!: EventDto;
  @EventsModule.Getter registrationPage!: PageDto | undefined;
  @EventsModule.Getter submissionPage!: PageDto | undefined;

  @HeaderModule.Action setHeaderVisibility!: (isVisible: boolean) => void;
  headerObserver: any = null;

  mounted() {
    if ('IntersectionObserver' in window) {
      this.headerObserver = new IntersectionObserver(entry => {
        this.setHeaderVisibility(entry[0].isIntersecting);
      });

      this.headerObserver.observe(this.$el);
    }
  }

  beforeDestroy() {
    if (this.headerObserver) {
      this.headerObserver.unobserve(this.$el);
    }
  }

  get isSubmissionCtaShown(): boolean {
    const form = this.currentEvent.forms.find(x => x.type === FormType.Submission)!;
    return form.state === FormState.Open;
  }

  get isRegistrationCtaShown(): boolean {
    const form = this.currentEvent.forms.find(x => x.type === FormType.Registration)!;
    return form.state === FormState.Open || form.state === FormState.FullWithWaitingList;
  }

  get headerTransition(): string {
    return this.currentEvent.isDarkMode ? 'fade-transition' : 'page-transition';
  }

  get isHomePage(): boolean {
    if (!this.$route?.name) return false;
    const route = this.$route.name;
    return (
      // the "/" is just for redirects
      route === 'slug___fr' ||
      route === 'slug___en' ||
      // "/pages" is the real homepage
      (route === 'slug-pages-id___fr' && this.$route.params.id == null) ||
      (route === 'slug-pages-id___en' && this.$route.params.id == null)
    );
  }

  get bannerUrl(): string {
    return this.transl8(this.currentEvent.banner);
  }

  get eventBannerBackgroundStyle() {
    return this.bannerUrl ? { 'background-image': `url('${this.bannerUrl}?w=2000&fit=max&auto=format'` } : {};
  }

  get isRegistrationPage(): boolean {
    const route = this.$route.name;
    return (
      route === 'slug-registration___en' ||
      route === 'slug-registration___fr' ||
      // Registration billing page
      route === 'slug-registration-billing___en' ||
      route === 'slug-registration-billing___fr' ||
      // Registration confirmation page
      route === 'slug-registration-confirmation___en' ||
      route === 'slug-registration-confirmation___fr'
    );
  }

  get isSubmissionPage(): boolean {
    const route = this.$route.name;
    return (
      route === 'slug-submission___en' ||
      route === 'slug-submission___fr' ||
      // Submission billing page
      route === 'slug-submission-billing___en' ||
      route === 'slug-submission-billing___fr' ||
      // Submission confirmation page
      route === 'slug-submission-confirmation___en' ||
      route === 'slug-submission-confirmation___fr'
    );
  }

  get isParticipantPage(): boolean {
    return this.$route?.name?.includes('participants') ?? false;
  }

  get isAbstractPage(): boolean {
    return this.$route?.name?.includes('abstracts') ?? false;
  }

  get isSchedulePage(): boolean {
    const route = this.$route.name;
    return route === 'slug-schedule___en' || route === 'slug-schedule___fr';
  }

  get headerTitle(): TranslateResult | string | null {
    let pageTitle: string | null = null;

    if (this.$route.params && this.$route.params.id) {
      const page = this.pages.find(page => page.id === this.$route.params.id);
      if (page) {
        pageTitle = transl8(page.name);
      }
    }

    // Registration Page
    if (this.isRegistrationPage && this.registrationPage) {
      const form = this.currentEvent.forms.find(x => x.type === FormType.Registration)!;
      return form.state === FormState.FullWithWaitingList
        ? this.$t('registration.waitlist')
        : transl8(this.registrationPage.name);
    }

    // Submission Page
    if (this.isSubmissionPage && this.submissionPage) {
      return transl8(this.submissionPage.name);
    }

    // Participants Page
    if (this.isParticipantPage) {
      const page = this.pages.find(page => page.type === PageType.Participants);
      if (page) {
        pageTitle = transl8(page.name);
      }
    }

    // Abstracts Page
    if (this.isAbstractPage) {
      const page = this.pages.find(page => page.type === PageType.Abstracts);
      if (page) {
        pageTitle = transl8(page.name);
      }
    }

    // Schedule Page
    if (this.isSchedulePage) {
      const page = this.pages.find(page => page.type === PageType.Schedule);
      if (page) {
        pageTitle = transl8(page.name);
      }
    }

    return pageTitle;
  }

  get headerDescription(): string | null {
    // Registration Page
    if (this.isRegistrationPage && this.registrationPage) {
      const form = this.currentEvent.forms.find(x => x.type === FormType.Registration);
      if (form && form.state === FormState.FullWithWaitingList) {
        return `${this.$t('registration.state_FullWithWaitingList')}`;
      }
      if (form && form.state === FormState.PeriodDidNotStart) {
        return `${this.$t('registration.subtitle_not_started')} ${this.showUserFriendlyDate(
          this.currentEvent.registrationStartDate,
        )}`;
      } else if (form && form.state === FormState.PeriodHasEnded) {
        return `${this.$t('registration.subtitle_closed')} ${this.showUserFriendlyDate(
          this.currentEvent.registrationEndDate,
        )}`;
      } else {
        return `${this.$t('registration.subtitle')} ${this.showUserFriendlyDate(
          this.currentEvent.registrationEndDate,
        )}`;
      }
    }

    // Submission page
    if (this.isSubmissionPage && this.submissionPage) {
      const form = this.currentEvent.forms.find(x => x.type === FormType.Submission);
      if (form && form.state === FormState.FullWithWaitingList) {
        return `${this.$t('submission.state_FullWithWaitingList')}`;
      }
      if (form && form.state === FormState.PeriodDidNotStart) {
        return `${this.$t('submission.subtitle_not_started')} ${this.showUserFriendlyDate(
          this.currentEvent.submissionStartDate,
        )}`;
      } else if (form && form.state === FormState.PeriodHasEnded) {
        return `${this.$t('submission.subtitle_closed')} ${this.showUserFriendlyDate(
          this.currentEvent.submissionEndDate,
        )}`;
      } else {
        return `${this.$t('submission.subtitle')} ${this.showUserFriendlyDate(this.currentEvent.submissionEndDate)}`;
      }
    }

    // Schedule page
    if (this.isSchedulePage) {
      return `${this.$t('schedule.time_zone_details')} ${this.currentEvent.timeZone} ${this.$moment().format('z')}.`;
    }

    return null;
  }
}
