export default {
  file: {
    click_upload: 'Click to upload',
    drag_drop: 'drag and drop',
    upload_failed: 'Upload failed, please try a different file',
    upload_failed_mobile: 'Upload failed',
    view_file: 'View {fileName}',
    delete_file: 'Delete {fileName}',
    invalid_file: 'Invalid file',
    invalid_file_type: 'Invalid file type {extension}',
    invalid_file_size: 'File size must be less than {maxSize}',
    all_file_types: 'All files types accepted.',
    accepted_file_types: 'Accepted formats: {fileTypes}.',
    recommended_dimensions: 'Recommended dimensions: {dimensions}.',
    max_dimensions: 'Max dimensions: {dimensions}.',
    max_size: 'Max file size: {size}.',
    download_ppt:
      'This PowerPoint file is over 10MB and cannot be previewed. Please download the file to view its contents.',
  },
  date: {
    calendar: {
      days: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
      months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    },
    select_date: 'Select date',
    select_time: 'Select time',
    select_datetime: 'Select date & time',
  },
  select: {
    placeholder: 'Select an option',
    country_label: 'Country',
    state_label: 'Province / State',
  },
  email_confirmation: 'Confirm email',
  email: 'Email',
  author: {
    affiliations: 'Affiliations',
    title_add: 'Add an author',
    title_edit: 'Edit an author',
    press_enter: 'Press enter',
    picker: 'Select an affiliation',
    empty: 'Type an affiliation',
    presenter: 'Presenter',
  },
  first_name: 'First name',
  last_name: 'Last name',
  password: {
    label: 'Password',
    help: 'Minimum of 8 characters.',
    show: 'Show password',
    hide: 'Hide password',
  },
};
