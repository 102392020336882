export default {
  acronym: 'Q&R',
  title: 'Posez une question',
  edit: 'Modifier',
  delete: 'Supprimer',
  presenter: 'Présentateur',
  reply_btn: 'Répondre',
  upvote: 'votes | vote | votes',
  edit_btn: 'Modifier',
  cancel_btn: 'Annuler',
  submit_btn: 'Soumettre',
  textarea_message_placeholder: 'Votre question',
  textarea_reply_placeholder: 'Votre réponse',
  textarea_placeholder: 'Posez une question',
  success_message: {
    added: 'Le commentaire a été ajouté avec succès',
    updated: 'Le commentaire a été modifié avec succès',
    deleted: 'Le commentaire a été supprimé avec succès',
    delete_all: 'Les questions ont été supprimées avec succès',
  },
  must_be_logged_in: 'Vous devez être connecté pour interagir.',
  clear_messages: 'Effacer les questions',
  clear_messages_confirm: 'Voulez-vous vraiment effacer toutes les questions?',
  no_results: 'Posez une première question.',
};
