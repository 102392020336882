
import { Component, Vue, Prop } from 'nuxt-property-decorator';
import VideoPlayer from '../../components/media/VideoPlayer.vue';
import { RecordedVideoDto } from '../../services';

@Component({
  components: { VideoPlayer },
})
export default class SessionVideoModal extends Vue {
  @Prop({ required: true }) video!: RecordedVideoDto;

  isOpen = false;

  public openSessionVideo() {
    this.isOpen = true;
  }

  public closeSessionVideo() {
    this.isOpen = false;
  }
}
