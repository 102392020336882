export default {
  credit_card: {
    number: 'Numéro de carte',
    name_on_card: 'Nom sur la carte',
    expiry: `Date d'expiration`,
    expiry_paypal: `Date d'expiration (MM/AAAA)`,
    expiry_moneris: `Date d'expiration (MM/YY)`,
    cvv: 'CVC',
  },
  billing_info: {
    title: 'Adresse de facturation',
    fullname: 'Facturer à (Nom de la personne et/ou de l’entreprise)',
    address_line_1: 'Adresse',
    city: 'Ville',
    postal_code: 'Code postal ou ZIP',
    use_org: "Utiliser l'adresse de l'organisation",
  },
  paypal: {
    billing_info_notice: 'Veuillez saisir une adresse de facturation.',
  },
};
