export enum UploadFileType {
  FormData = 'FormData',
  Banner = 'Banner',
  FileContent = 'FileContent',
  SpeakerContent = 'SpeakerContent',
  ProfilePicture = 'ProfilePicture',
  Logo = 'Logo',
  LogoContent = 'LogoContent',
  VideoContent = 'VideoContent',
  Attachment = 'Attachment',
}

export interface UploadFileModel {
  id: string;
  file: File;
  type: UploadFileType;
}
