import { getSubmissionTitle, FormEntryDto, FormDto, getAbstract, FormFieldType, FormType } from '../services';

export interface FormEntryMixins {
  getSubmissionTitle(formEntry: FormEntryDto, form: FormDto): string;
  getAbstract(formEntry: FormEntryDto, form: FormDto): string;
  buildDataDictionary(
    form: FormDto,
    formEntry: FormEntryDto | null,
    formData?: Record<string, any> | null,
  ): Record<string, any>;
}

export const formEntryMixins: FormEntryMixins = {
  getSubmissionTitle(formEntry, form) {
    return getSubmissionTitle(formEntry, form);
  },
  getAbstract(formEntry, form) {
    return getAbstract(formEntry, form);
  },
  buildDataDictionary(form, formEntry, formData = {}) {
    const checkboxFieldIds = form.formSections.flatMap(section => {
      return section.formFields
        .filter(field => field.fieldType === FormFieldType.Checkboxes || field.fieldType === FormFieldType.Images)
        .map(field => field.id);
    });

    const hiddenFieldsFormData = {};
    for (const key in formData) {
      if (checkboxFieldIds.includes(key)) {
        hiddenFieldsFormData[key] = Array.isArray(formData[key]) ? formData[key] : [formData[key]];
      } else {
        hiddenFieldsFormData[key] = formData[key];
      }
    }

    if (formEntry && form.type === FormType.Submission) {
      const authorField = form.formSections
        .flatMap(section => section.formFields)
        .find(field => field.fieldType === FormFieldType.Authors);
      if (authorField) {
        hiddenFieldsFormData[authorField.id] = formEntry?.authors ? JSON.stringify(formEntry.authors) : null;
      }
    }

    return hiddenFieldsFormData;
  },
};
