
import { Component, Prop, Vue } from 'nuxt-property-decorator';

@Component
export default class CalendarDateIcon extends Vue {
  @Prop({ type: [Number, String], default: '' }) readonly date!: number | string;

  get displayDate() {
    const dateNumber = Number(this.date);
    return !!dateNumber && dateNumber <= 31 ? dateNumber : new Date().getDate();
  }
}
