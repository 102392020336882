
import { Component, Emit, Vue, Prop } from 'nuxt-property-decorator';

@Component({})
export default class OrganizationSplashScreen extends Vue {
  @Prop(Boolean) readonly showSplashScreen!: boolean;
  @Prop({ type: String, required: true }) readonly illustrationPath!: string;

  @Emit()
  close() {}

  @Emit()
  createOrganization() {}
}
