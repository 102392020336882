export interface TranslatableField {
  fr: string;
  en: string;
}

// Highly used. Not very clean, but efficient.
// Please minimize memory allocations here.
export function transl8(field: TranslatableField, i18n?: any): string {
  if (field == null) return '';
  if (process.client && !i18n && window?.$nuxt?.$i18n) i18n = window.$nuxt.$i18n;

  // return the term by current language
  if (i18n?.locale && field[i18n.locale]) return field[i18n.locale];

  // return the term in any language
  if (i18n?.locales) {
    for (let i = 0; i < i18n.locales.length; i++) {
      if (field[i18n.locales[i].code] !== '') {
        return field[(i18n.locales[i] as any).code];
      }
    }
  }

  return '';
}
