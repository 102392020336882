
import { Component, Vue, namespace, Prop } from 'nuxt-property-decorator';
import { EventDto, LiveStreamType } from '../../services';

const EventsModule = namespace('events');

@Component({})
export default class LiveAppRoomRedirect extends Vue {
  @EventsModule.State currentEvent!: EventDto;
  @Prop({ required: true }) liveStreamType!: LiveStreamType;
  @Prop({ required: true }) roomId!: string;
  @Prop({ required: true }) readonly sessionId!: string;
  @Prop(Boolean) hasIcon!: boolean;
  @Prop({ default: 'primary' }) hierarchy?: string;
  @Prop({ default: 'md' }) size?: string;

  readonly LiveStreamType = LiveStreamType;

  get redirectUrl() {
    return this.liveStreamType === LiveStreamType.VirtualPosterSession
      ? `posters/?eventId=${this.currentEvent.id}&sessionId=${this.sessionId}`
      : `rooms/?eventId=${this.currentEvent.id}&roomId=${this.roomId}`;
  }
}
