export interface ValidationMixins {
  scrollToErrors(): void;
  scrollToUnregisteredErrors(): void;
}

export const validationMixins: ValidationMixins = {
  scrollToErrors() {
    this.$nextTick(() => {
      const hasErrors = this.$validator && this.$validator.errors.items.length > 0;
      if (!hasErrors) return;

      let offset = 0;

      // Top menu of the event website
      let offsetElement = document.querySelector<HTMLElement>('.nav');

      // Top menu of the organizer panel
      if (!offsetElement) {
        offsetElement = document.querySelector<HTMLElement>('.header');
      }

      if (offsetElement) offset = -(offsetElement.offsetHeight + 80);

      const selector = document.querySelector('.js-validation-error:not(:empty)')
        ? '.js-validation-error:not(:empty)'
        : '.error';

      const firstError = document.querySelectorAll(selector)[0];

      this.$scrollTo(firstError, { offset, force: true });
    });
  },
  scrollToUnregisteredErrors() {
    this.$nextTick(() => {
      let offset = 0;

      // Top menu of the event website
      let offsetElement = document.querySelector<HTMLElement>('.nav');

      // Top menu of the organizer panel
      if (!offsetElement) {
        offsetElement = document.querySelector<HTMLElement>('.header');
      }

      if (offsetElement) offset = -(offsetElement.offsetHeight + 80);

      const selector = document.querySelector('.js-validation-error:not(:empty)')
        ? '.js-validation-error:not(:empty)'
        : '.error';
      const allErrors = document.querySelectorAll(selector);

      if (allErrors.length) {
        this.$scrollTo(allErrors[0], { offset, force: true });
        return;
      }
    });
  },
};
