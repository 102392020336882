
import { Vue, Component, Watch, Emit, Prop } from 'nuxt-property-decorator';
import { NestedPage, PageDto, PageType } from 'fourwaves-shared';
import { BurgerBtn } from 'fourwaves-shared/components';
import SubMenu from './SubMenu.vue';
import LangChooser from '~/components/layout/LangChooser.vue';

@Component({
  components: {
    LangChooser,
    SubMenu,
    BurgerBtn,
  },
})
export default class MobileNav extends Vue {
  @Prop({ required: true }) pages!: NestedPage[];

  isActive = false;

  @Emit() onActiveChange(activeState: boolean) {
    return activeState;
  }

  @Watch('isActive')
  onActiveChangeHandler() {
    this.onActiveChange(this.isActive);
  }

  @Watch('$route')
  onRoutechange() {
    this.$nextTick(() => setTimeout(() => (this.isActive = false), 50));
  }

  public isNativeLink({ type }: PageDto) {
    const isConfirmationRoute = this.$route.path.endsWith('/confirmation');
    const isRegistration = this.$route.name?.includes('registration') && type === PageType.Registration;
    const isSubmission = this.$route.name?.includes('submission') && type === PageType.Submission;
    return isConfirmationRoute && (isRegistration || isSubmission);
  }
}
