
import { Vue, Prop, Component, Watch, Inject } from 'nuxt-property-decorator';
import { sortBy } from 'lodash-es';
import { Countries, Provinces } from '../../../../core';
import { InputOption } from '../../../../types';
import Collapsible from '../../../layout/Collapsible.vue';
import InputSelectNative from './InputSelectNative.vue';

@Component({
  components: {
    InputSelectNative,
    Collapsible,
  },
})
export default class InputSelectCountry extends Vue {
  @Prop({ required: true }) readonly country!: string | null;
  @Prop({ required: true }) readonly stateProvince!: string | null;
  @Prop({ type: String, default: null }) readonly itemClassName!: string;
  @Prop(Boolean) readonly required!: boolean;
  @Prop(Boolean) readonly disabled!: boolean;
  @Prop(Boolean) readonly labelLess!: boolean;
  @Inject() $validator;

  provinces: InputOption[] = [];

  get countries(): InputOption[] {
    return sortBy(
      Countries.map(c => ({ id: c, label: this.$t(`shared.geo.countries.${c}`) })),
      'label',
    );
  }

  get innerCountry() {
    return this.country || '';
  }

  set innerCountry(value: string) {
    this.$emit('country', value);
  }

  get innerStateProvince() {
    return this.stateProvince || '';
  }

  set innerStateProvince(value: string) {
    this.$emit('state', value);
  }

  get countryError() {
    return this.errors.first('CountryRegion');
  }

  get stateError() {
    return this.errors.first('StateProvince');
  }

  @Watch('country', { immediate: true })
  onCountryChanged(country: string) {
    const provinces = Provinces.filter(x => x.country === country).map(p => ({
      id: p.province,
      label: this.$t(`shared.geo.provinces.${p.province}`),
    }));
    this.provinces = sortBy(provinces, 'label');

    if (this.innerStateProvince && !this.provinces.some(x => x.id === this.innerStateProvince)) {
      this.innerStateProvince = '';
    }
  }
}
