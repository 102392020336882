import { Component, Watch, Vue } from 'nuxt-property-decorator';
import { isEqual, cloneDeep } from 'lodash-es';

@Component
export class BeforeRouteLeaveMixin extends Vue {
  async beforeRouteLeave(to, from, next: () => void) {
    if (!this.$beforeUnload.isActive) return next();
    const isNavigationConfirmed = await this.$beforeUnload.confirmNavigation();
    return isNavigationConfirmed ? next() : false;
  }
}

@Component
export class ChangeDetectionMixin extends Vue {
  isChangeDetected = false;
  isInitialized = false;

  unwatch: (() => void) | null = null;

  @Watch('isChangeDetected', { immediate: true })
  public onIsChangeDetectedChange(isChangeDetected: boolean, wasChangeDetected?: boolean) {
    if (isChangeDetected === wasChangeDetected) return;
    this.$beforeUnload.isActive = isChangeDetected;
  }

  beforeDestroy() {
    if (this.unwatch) this.unwatch();
  }

  public preventLostChanges(modelPath: string, checkpoint?: any) {
    const innerCheckpoint = checkpoint || cloneDeep(this[modelPath]);

    if (this.unwatch) {
      this.isChangeDetected = false;
      this.unwatch();
    }

    this.unwatch = this.$watch(
      modelPath,
      (model: any) => {
        if (!this.isInitialized) {
          this.isInitialized = true;
          return;
        }

        if (this.isChangeDetected) return;
        this.isChangeDetected = model.id !== '' && !isEqual(innerCheckpoint, model);
      },
      { deep: true, immediate: true },
    );
  }
}
