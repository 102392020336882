import { MutationTree, ActionTree } from 'vuex';
import { RootState, HeaderState } from '~/types';

export const state = (): HeaderState => ({
  isHeaderVisible: false,
});

export const mutations: MutationTree<HeaderState> = {
  setHeaderVisibility(state: HeaderState, isHeaderVisible: boolean): void {
    state.isHeaderVisible = isHeaderVisible;
  },
};

export const actions: ActionTree<RootState, RootState> = {
  setHeaderVisibility({ commit }, isHeaderVisible: boolean) {
    commit('setHeaderVisibility', isHeaderVisible);
  },
};
