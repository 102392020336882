export default {
  bold: 'Bold',
  aria_bold: 'Toggle bold',
  italic: 'Italic',
  aria_italic: 'Toggle italic',
  underline: 'Underline',
  aria_underline: 'Toggle underline',
  subscript: 'Subscript',
  aria_subscript: 'Toggle subscript',
  superscript: 'Superscript',
  aria_superscript: 'Toggle superscript',
  link: 'Link',
  aria_link: 'Insert or edit link',
  alignLeft: 'Align Left',
  aria_alignLeft: 'Align left',
  alignCenter: 'Align Center',
  aria_alignCenter: 'Align center',
  video: 'Video',
  aria_video: 'Insert video',
  horizontalLine: 'Horizontal Line',
  aria_horizontalLine: 'Insert horizontal line',
  bulletList: 'Bullet List',
  aria_bulletList: 'Toggle bullet list',
  orderedList: 'Ordered List',
  aria_orderedList: 'Toggle ordered list',
  title: 'Title {level}',
  paragraph: 'Paragraph',
  open_format_dropdown: 'Open format dropdown',
  url: 'URL',
  video_url: 'Video URL',
  enter_url: 'Enter URL',
  supported_video: 'Only YouTube or Vimeo URLs.',
  open_overlow_menu: 'Open overflow menu',
};
