
import { Component, Vue, Prop, namespace } from 'nuxt-property-decorator';
import { SchedulePresentationForFormEntryDto, RoomDto, LiveStreamType, EventDto, ScheduleDto } from '../../../services';
import { RoomCta } from '../../blocks';
import SessionCard from '../../sessions/SessionCard.vue';

type ScheduleSessionWithRoom = {
  session: ScheduleDto;
  room?: RoomDto;
};

const EventsModule = namespace('events');

@Component({
  components: {
    RoomCta,
    SessionCard,
  },
})
export default class PresentationDetailsScheduleList extends Vue {
  @EventsModule.State currentEvent!: EventDto;
  @EventsModule.State currentTime!: string;
  @EventsModule.State sessions!: ScheduleDto[];
  @EventsModule.Getter liveSessions!: ScheduleDto[];
  @EventsModule.Getter isParticipant!: boolean;
  @EventsModule.Getter isOrganizer!: boolean;

  @Prop({ required: true }) schedulePresentations!: SchedulePresentationForFormEntryDto[];
  @Prop({ required: true }) rooms!: RoomDto[];
  @Prop(Boolean) readonly isLiveApp!: boolean;

  readonly LiveStreamType = LiveStreamType;

  get scheduleList(): ScheduleSessionWithRoom[] {
    return this.schedulePresentations.flatMap(({ sessionId, roomId }) => ({
      session: this.sessions.find(({ id }) => id === sessionId)!,
      room: this.rooms!.find(({ id }) => id === roomId),
    }));
  }

  get presentationsByDate() {
    const groups: Record<string, ScheduleSessionWithRoom[]> = {};

    for (const schedule of this.scheduleList) {
      const date = this.$moment(schedule.session.startDate).format('YYYY-MM-DD');
      groups[date] = groups[date] || [];
      groups[date].push(schedule);
    }

    return groups;
  }

  get timezoneDetails() {
    return this.isLiveApp
      ? this.$moment.tz.guess() + ' ' + this.$moment.utc().tz(this.$moment.tz.guess()).format('z')
      : this.currentEvent.timeZone + ' ' + this.$moment.utc().tz(this.currentEvent.timeZone).format('z');
  }
}
