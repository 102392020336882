
import { Component, Vue, Prop, Emit } from 'nuxt-property-decorator';
import { UserStatusChannel } from '../../channels';

@Component
export default class UserProfilePicture extends Vue {
  @Prop({ required: true }) userId!: string;
  @Prop({ default: '' }) firstName!: string;
  @Prop({ default: '' }) lastName!: string;
  @Prop(Boolean) hideStatus!: boolean;
  @Prop({ default: false }) logName!: string | boolean;

  profilePictureUrl = '';
  showProfilePicture = false;
  profileImageUpdateKey = 0;
  isOnline: boolean | null = null;
  statusChannel: UserStatusChannel | null = null;
  useDefaultImage = false;

  get fullName() {
    return [this.firstName, this.lastName].filter(Boolean).join(' ');
  }

  @Emit() statusChanged(_status: boolean | null, _userId: string) {}

  created() {
    this.setProfilePictureUrl();
    this.$nuxt.$on('profile-picture-updated', this.onProfilePictureUpdated);

    if (this.$realtime && !this.hideStatus) {
      this.statusChannel = new UserStatusChannel({
        connection: this.$realtime,
        userId: this.userId,
        onUpdateCallback: isOnline => {
          this.isOnline = isOnline;
          this.statusChanged(isOnline, this.userId);
        },
      });
    }
  }

  beforeDestroy() {
    this.statusChannel?.unsubscribe();
    this.$nuxt.$off('profile-picture-updated', this.onProfilePictureUpdated);
  }

  public getVersionTag() {
    return this.$auth.loggedIn && this.$auth.user?.id === this.userId
      ? new Date().getTime()
      : new Date().setHours(0, 0, 0, 0);
  }

  public onProfilePictureUpdated(newUrl: string | null) {
    setTimeout(() => {
      this.setProfilePictureUrl();
      this.showProfilePicture = newUrl != null;
      this.$forceUpdate();
    }, 2000);
  }

  public setProfilePictureUrl() {
    const devicePixelRatio = process.client ? window.devicePixelRatio || 1 : 1;

    this.profilePictureUrl = `${this.$config.imgixDomain}/static/media/profilepictures/${
      this.userId
    }/latest?version_tag=${
      this.getVersionTag() + this.profileImageUpdateKey
    }&w=128&dpr=${devicePixelRatio}&auto=format`;
  }

  public removeImage({ target }: { target: HTMLImageElement }) {
    target.parentNode?.removeChild(target);
    this.showProfilePicture = false;
  }

  public onVisibilityChanged(isVisible: boolean) {
    if (isVisible) this.statusChannel?.subscribe();
    else this.statusChannel?.unsubscribe();
  }
}
