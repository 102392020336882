import { MutationTree, ActionTree } from 'vuex';
import { RootState, FooterState } from '~/types';

export const state = (): FooterState => ({
  isFooterVisible: false,
});

export const mutations: MutationTree<FooterState> = {
  setFooterVisibility(state: FooterState, isFooterVisible: boolean): void {
    state.isFooterVisible = isFooterVisible;
  },
};

export const actions: ActionTree<RootState, RootState> = {
  setFooterVisibility({ commit }, isFooterVisible: boolean) {
    commit('setFooterVisibility', isFooterVisible);
  },
};
