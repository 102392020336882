
import { Component, Vue, Prop } from 'nuxt-property-decorator';

@Component({})
export default class SessionPresentationCount extends Vue {
  @Prop({ required: true }) count!: number;
  @Prop(Boolean) isVps!: boolean;

  get iconName() {
    return this.isVps ? 'poster' : 'slides';
  }

  get title() {
    return this.isVps
      ? this.$tc('shared.sessions.poster_count_title', this.count)
      : this.$tc('shared.sessions.presentation_count_title', this.count);
  }
}
