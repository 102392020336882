import { isUtcNowInRange } from '../../../helpers';
import { Dictionary } from '../../../core';
import type { InputOption } from '../../../types';
import { transl8, TranslatableField } from './TranslatableField';
import { UserDto } from './UserDto';
import { isFieldChoiceAvailable, FormFieldChoiceDto } from './FormFieldChoiceDto';
import { FormType } from './FormType';

export enum FormState {
  Open = 'Open',
  Full = 'Full',
  FullWithWaitingList = 'FullWithWaitingList',
  PeriodHasEnded = 'PeriodHasEnded',
  PeriodDidNotStart = 'PeriodDidNotStart',
  EventHasEnded = 'EventHasEnded',
}

export enum FormFieldType {
  Text = 'text',
  Textarea = 'textarea',
  Email = 'email',
  Checkboxes = 'checkboxes',
  Radios = 'radios',
  Dropdown = 'dropdown',
  File = 'file',
  RichText = 'richtext',
  Instructions = 'instructions',
  Poster = 'poster',
  Slides = 'slides',
  Images = 'images',
  Authors = 'authors',
  Video = 'video',
  Score = 'score',
  CountryList = 'countrylist',
}

export const FieldTypeIconMap = {
  [FormFieldType.File]: 'field-file-download',
  [FormFieldType.Video]: 'film',
  [FormFieldType.Poster]: 'poster',
  [FormFieldType.Slides]: 'slides',
};

export interface FormFieldConditionChoiceDto {
  id: string;
  formFieldId: string;
  formFieldChoiceId: string;
}

export interface FormFieldConditionDto {
  id: string;
  conditionLogic: string;
  conditionAction: string;
  formFieldConditionChoices: FormFieldConditionChoiceDto[];
}

export interface FormFieldDto {
  id: string;
  shortLabel: TranslatableField;
  label: TranslatableField;
  description: TranslatableField;
  order: number;
  fieldType: FormFieldType;
  allowedFileTypes?: string | null;
  isRequired: boolean;
  isOrganizersOnly: boolean;
  isDeletable: boolean;
  isVisibleToReviewers: boolean;
  isVisibleToSubmitters: boolean;
  maxWords: number;
  maxReviewScore: number;
  isBasicStylesAllowed: boolean;
  formFieldChoices: FormFieldChoiceDto[];
  formFieldCondition?: FormFieldConditionDto | null;
}

export interface FormSectionDto {
  id: string;
  label: TranslatableField;
  introText: TranslatableField;
  formFields: FormFieldDto[];
  order: number;
}

export interface FormDto {
  id: string;
  eventId: string;
  type: number;
  name: TranslatableField;
  areMultipleSubmissionsAllowed: boolean;
  isActive: boolean;
  formSections: FormSectionDto[];
  emailFormFieldId: string | null;
  firstNameFormFieldId: string | null;
  lastNameFormFieldId: string | null;
  submissionTitleFormFieldId: string | null;
  submissionAbstractFormFieldId: string | null;
  isWaitingListEnabled: boolean;
  capacity: number;
  isCapacityLimited: boolean;
  state: FormState;
  lastProcessUpdateDate?: string;
  lastProcessUpdateUser?: UserDto;
  confirmationEmailSubject: TranslatableField;
  confirmationEmailBody: TranslatableField;
  reviewMaxScore?: number;
  areResultsSharedToSubmitters: boolean;
  areReviewerNamesHidden: boolean;
}

export function getOptionsFromFields(formFields: FormFieldDto[], isFieldPicker = false): InputOption[] {
  const allOptions: InputOption[] = [];
  if (!isFieldPicker) {
    formFields.forEach(field => {
      field.formFieldChoices.forEach(choice =>
        allOptions.push({
          id: choice.id,
          label: `${transl8(field.shortLabel)}: ${transl8(choice.shortLabel)}`,
        }),
      );
    });
  } else {
    formFields.forEach(option => {
      const label = transl8(option.shortLabel);
      allOptions.push({
        id: option.id,
        label: label || transl8(option.label),
      });
    });
  }

  return allOptions;
}

export function getRequiredFieldsWithNoAvailableChoices(
  form: FormDto,
  formData: Dictionary<string | string[]>,
): string[] {
  const problematicFieldIds: string[] = [];
  if (form && form.formSections) {
    form.formSections
      .flatMap(x => x.formFields)
      .filter(
        field =>
          field.fieldType === FormFieldType.Checkboxes ||
          field.fieldType === FormFieldType.Radios ||
          field.fieldType === FormFieldType.Dropdown,
      )
      .filter(field => field.isRequired)
      .filter(field => !formData || !formData[field.id])
      .forEach(field => {
        const withAtLeastOneAvailableChoice = field.formFieldChoices.some(
          choice => isFieldChoiceAvailable(choice) && isUtcNowInRange(choice.startDate, choice.endDate),
        );

        if (!withAtLeastOneAvailableChoice) {
          problematicFieldIds.push(field.id);
        }
      });
  }
  return problematicFieldIds;
}

export function getAllPaidChoices(form: FormDto, formData: Dictionary<string | string[]>): FormFieldChoiceDto[] {
  const selectedPaidChoices: FormFieldChoiceDto[] = [];
  if (form && form.formSections) {
    for (const section of form.formSections) {
      for (const field of section.formFields) {
        if (field.formFieldChoices && formData[field.id]) {
          for (const choice of field.formFieldChoices) {
            if (choice.id === formData[field.id] || formData[field.id].includes(choice.id)) {
              if ((choice.cost && choice.cost > 0) || (choice.lateCost && choice.lateCost > 0)) {
                selectedPaidChoices.push(choice);
              }
            }
          }
        }
      }
    }
  }
  return selectedPaidChoices;
}

export function getAvailableFieldTypes(form: FormDto): FormFieldType[] {
  const types: FormFieldType[] = [];
  const allFields = form.formSections ? form.formSections.flatMap(x => x.formFields) : [];
  types.push(FormFieldType.Text);
  types.push(FormFieldType.Textarea);
  types.push(FormFieldType.Radios);
  types.push(FormFieldType.Checkboxes);
  types.push(FormFieldType.Dropdown);
  types.push(FormFieldType.Instructions);

  if (form.type !== FormType.BankAccountConnection) {
    types.push(FormFieldType.File);
  }

  if (form.type !== FormType.Review) {
    types.push(FormFieldType.CountryList);
  }

  // submission form
  if (form.type === FormType.Submission) {
    // limit 1 poster field per form
    if (!allFields.some(x => x.fieldType === FormFieldType.Poster)) {
      types.push(FormFieldType.Poster);
    }

    // limit 1 images field per form
    if (!allFields.some(x => x.fieldType === FormFieldType.Images)) {
      types.push(FormFieldType.Images);
    }

    // limit 1 slides field per form
    if (!allFields.some(x => x.fieldType === FormFieldType.Slides)) {
      types.push(FormFieldType.Slides);
    }

    // limit 1 video field per form
    if (!allFields.some(x => x.fieldType === FormFieldType.Video)) {
      types.push(FormFieldType.Video);
    }
  }
  // review form
  else if (form.type === FormType.Review) {
    types.push(FormFieldType.Score);
  }

  return types;
}

export function isFileUpload(type: FormFieldType) {
  return (
    type === FormFieldType.File ||
    type === FormFieldType.Slides ||
    type === FormFieldType.Poster ||
    type === FormFieldType.Images ||
    type === FormFieldType.Video
  );
}
