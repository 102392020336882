
import { Component, Prop, Emit, Watch, mixins } from 'nuxt-property-decorator';
import DatePicker from 'vue2-datepicker';
import { GlobalDropdownEvents } from '../../../../types';
import { isValidDate } from '../../../../helpers';
import Collapsible from '../../../layout/Collapsible.vue';
import InputValidationMixin from '../InputValidationMixin';

@Component({
  components: {
    DatePicker,
    Collapsible,
  },
})
export default class InputDate extends mixins(InputValidationMixin) {
  @Prop({ type: String, required: true }) readonly name!: string;
  @Prop({ type: String, default: null }) readonly value!: string | null;
  @Prop({ type: String, default: null }) readonly placeholder!: string | null;
  @Prop({ type: String, default: null }) readonly label!: string | null;
  @Prop({ type: String, default: null }) readonly minDate!: string | null;
  @Prop({ type: String, default: null }) readonly maxDate!: string | null;
  @Prop({ type: String, default: null }) readonly clearWhenBefore!: string | null;
  @Prop({ type: String, default: null }) readonly clearWhenAfter!: string | null;
  @Prop(Boolean) readonly validateOnChange!: boolean;
  @Prop(Boolean) readonly disabled!: boolean;
  @Prop(Boolean) readonly readonly!: boolean;
  @Prop(Boolean) readonly time!: boolean;
  @Prop(Boolean) readonly appendToBody!: boolean;

  selectedDate: string | null = null;
  showTimePanel = false;
  isDatePickerOpen = false;

  get inputClass() {
    const classNames = [
      ['input-date-input js-datepicker__date', true],
      ['-focus', this.isDatePickerOpen],
      ['-readonly', this.readonly],
      ['-destructive', this.hasValidationError],
    ];

    return classNames.flatMap(([className, isApplied]) => (isApplied ? className : [])).join(' ');
  }

  get placeholderWithFallback() {
    if (this.placeholder) return this.placeholder;
    return this.time ? this.$t('shared.inputs.date.select_datetime') : this.$t('shared.inputs.date.select_date');
  }

  get datePickerLocalizedCalendar() {
    return {
      days: this.$t('shared.inputs.date.calendar.days'),
      months: this.$t('shared.inputs.date.calendar.months'),
      placeholder: {
        date: this.$t('shared.inputs.date.select_date'),
        timeRange: this.$t('shared.inputs.date.select_time'),
      },
      formatLocale: {
        monthsShort: this.$moment.monthsShort(),
      },
    };
  }

  get datePickerType() {
    return this.time ? 'datetime' : 'date';
  }

  get inputFormat() {
    return this.time ? 'LLL' : 'LL';
  }

  get formatter() {
    return {
      stringify: (date: Date) => {
        return isValidDate(date) ? this.userTimezoneMoment(date).format(this.inputFormat) : '';
      },
      parse: (dateString: string) => {
        const moment = this.userTimezoneMoment(dateString);
        return moment.isValid() ? moment.toDate() : null;
      },
    };
  }

  get selectedMoment() {
    return this.selectedDate ? this.$moment(this.selectedDate, this.inputFormat) : null;
  }

  get minDateMoment() {
    if (!this.minDate) return null;
    return this.userTimezoneMoment().set(this.getDateObjectDefinition(this.minDate));
  }

  get maxDateMoment() {
    if (!this.maxDate) return null;
    return this.userTimezoneMoment().set(this.getDateObjectDefinition(this.maxDate));
  }

  get clearBeforeMoment() {
    if (!this.clearWhenBefore) return null;
    return this.userTimezoneMoment().set(this.getDateObjectDefinition(this.clearWhenBefore));
  }

  get clearAfterMoment() {
    if (!this.clearWhenAfter) return null;
    return this.userTimezoneMoment().set(this.getDateObjectDefinition(this.clearWhenAfter));
  }

  get shouldValidateOnClose() {
    return this.validateOnChange || this.validation != null;
  }

  get prefers12hFormat() {
    return this.userTimezoneMoment().localeData().longDateFormat('LT').endsWith('A');
  }

  @Watch('value', { immediate: true })
  onValueChange(value: string | null) {
    if (!value && !this.selectedDate) return;
    const moment = this.$moment(value);
    this.selectedDate = moment.isValid() ? moment.format(this.inputFormat) : null;
  }

  @Watch('clearBeforeMoment')
  async onClearBeforeChange() {
    if (this.selectedMoment && this.clearBeforeMoment && this.clearBeforeMoment?.isSameOrAfter(this.selectedMoment)) {
      this.selectedDate = null;
      this.emitInput();
      await this.$nextTick();
      this.$validator.validate(this.name);
    }
  }

  @Watch('clearAfterMoment')
  async onClearAfterChange() {
    if (this.selectedMoment && this.clearAfterMoment && this.clearAfterMoment?.isSameOrBefore(this.selectedMoment)) {
      this.selectedDate = null;
      this.emitInput();
      await this.$nextTick();
      this.$validator.validate(this.name);
    }
  }

  @Watch('isDatePickerOpen')
  onIsDatePickerOpen(isOpen: boolean) {
    if (isOpen) this.$nuxt.$on(GlobalDropdownEvents.Close, this.closeDatePicker);
    else this.$nuxt.$off(GlobalDropdownEvents.Close, this.closeDatePicker);
  }

  @Emit('input')
  emitInput(): string | null {
    if (!this.selectedDate) return null;
    return this.$moment(this.selectedDate, this.inputFormat).toISOString();
  }

  beforeDestroy() {
    this.$nuxt.$off(GlobalDropdownEvents.Close, this.closeDatePicker);
  }

  public getDateObjectDefinition(dateString: string) {
    const dateMoment = this.$moment(dateString);

    return {
      year: dateMoment.year(),
      month: dateMoment.month(),
      date: dateMoment.date(),
      hours: dateMoment.hours(),
      minutes: dateMoment.minutes(),
    };
  }

  public isDisabledDate(date: Date) {
    if (!this.minDate && !this.maxDate) return false;
    const reqDate = this.userTimezoneMoment(date);
    const isSameOrAfterMin = !this.minDateMoment || reqDate.isSameOrAfter(this.minDateMoment, 'day');
    const isSameOrBeforeMax = !this.maxDateMoment || reqDate.isSameOrBefore(this.maxDateMoment, 'day');
    return !isSameOrAfterMin || !isSameOrBeforeMax;
  }

  public isDisabledTime(date: Date) {
    if (!this.minDate && !this.maxDate) return false;
    const reqDate = this.userTimezoneMoment(date);
    const isAfterMin = !this.minDateMoment || reqDate.isAfter(this.minDateMoment, 'minute');
    const isSameOrBeforeMax = !this.maxDateMoment || reqDate.isSameOrBefore(this.maxDateMoment, 'minute');
    return !isAfterMin || !isSameOrBeforeMax;
  }

  public userTimezoneMoment(date?: string | Date | null) {
    if (!date) return this.$moment.tz(new Date(), this.$moment.tz.guess());

    return isValidDate(date)
      ? this.$moment.tz(date, this.$moment.tz.guess())
      : this.$moment.tz(date, this.inputFormat, this.$moment.tz.guess());
  }

  public closeDatePicker() {
    this.isDatePickerOpen = false;
    this.onDatePickerClose();
  }

  public async onDatePickerClose() {
    this.showTimePanel = false;
    this.emitInput();
    await this.$nextTick();
    if (!this.shouldValidateOnClose && !this.$validator.errors.has(this.name)) return;
    this.$validator.validate(this.name);
  }

  public onCalendarChange(date: Date, type: string) {
    if (!this.time || type !== 'date') return;
    this.showTimePanel = true;
  }
}
