import { Vue } from 'nuxt-property-decorator';
import { MutationTree } from 'vuex';
import { ScheduleDto } from '../../services';
import { EventsBaseState } from './EventsBaseState';

export const eventsMutations: MutationTree<EventsBaseState> = {
  updateSession(state, session: ScheduleDto): void {
    Vue.set(
      state.sessions,
      state.sessions.findIndex(({ id }) => id === session.id),
      session,
    );
  },
};
