
import { Prop, Component, Vue, Inject, Watch } from 'nuxt-property-decorator';
import { closest } from '../../helpers';

@Component({})
export default class Tabs extends Vue {
  @Inject() $validator;

  @Prop({ required: true }) tabSlugs!: Array<string>;
  @Prop({ required: false, default: 0 }) initialTabIndex!: number;
  @Prop({ required: false, type: Boolean, default: true }) enableTabs!: boolean;
  @Prop({ required: false }) participantCount!: number;
  @Prop({ required: false, type: Boolean }) useBrowserHistory!: boolean;
  @Prop(Boolean) isLiveApp!: boolean;

  tabHeadPrefix = 'tab-head__';
  tabContentPrefix = 'tab-content__';
  activeTabIndex = 0;
  previousTabIndex = 0;
  currentTabIsValid = false;
  isInSidePanel = false;

  get areTabsEnabled(): boolean {
    return this.enableTabs && this.currentTabIsValid;
  }

  @Watch('initialTabIndex')
  handleInitialTabIndex() {
    this.activeTabIndex = this.initialTabIndex || 0;
  }

  mounted() {
    this.handleInitialTabIndex();
    if (closest(this.$refs.tabs as HTMLElement, 'side-panel') !== null) {
      this.isInSidePanel = true;
    }
  }

  public async handleClick(tabSlug: string, tabIndex: number) {
    if (!this.enableTabs) return;
    if (this.activeTabIndex === tabIndex) return;

    if (await this.$validator.validateAll()) {
      this.previousTabIndex = this.activeTabIndex;
      this.activeTabIndex = tabIndex;
      this.$emit('tab-changed', tabSlug);
    }
  }

  public beforeEnter() {
    if (!this.isInSidePanel) {
      // height anim setup
      (this.$refs.tabs as HTMLElement).style.height = (this.$refs.tabs as HTMLElement).offsetHeight + 'px';
      (this.$refs.tabs as HTMLElement).style.transition = 'height 0.3s';
    }
  }

  public enter(el) {
    if (!this.isInSidePanel) {
      // get oldTab/newTab height difference
      const oldTab = (this.$refs.tabs as HTMLElement).querySelectorAll('.tabs__tab-content.active')[0];
      const oldTabHeight = (oldTab as HTMLElement).offsetHeight;
      const newTabHeight = el.offsetHeight;
      const newHeight = (this.$refs.tabs as HTMLElement).offsetHeight + newTabHeight - oldTabHeight;

      // animate height
      (this.$refs.tabs as HTMLElement).style.height = newHeight + 'px';
    }
  }

  public afterEnter() {
    if (!this.isInSidePanel) {
      // reset height to auto for auto-resize on window resize
      (this.$refs.tabs as HTMLElement).style.height = 'auto';
    }
  }

  public async handleMouseEnter() {
    this.currentTabIsValid = await this.$validator.validateAll();
  }

  public onClickBack() {
    if (this.useBrowserHistory) return this.$router.back();
    const name = this.isLiveApp ? 'slug-presentations' : 'slug-abstracts';
    this.$router.push(this.localePath({ name }));
  }
}
