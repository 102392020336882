
import { NestedPage } from 'fourwaves-shared';
import { Collapsible } from 'fourwaves-shared/components';
import { Vue, Component, Prop } from 'nuxt-property-decorator';

@Component({ components: { Collapsible } })
export default class SubMenuItem extends Vue {
  @Prop({ required: true }) page!: NestedPage;

  isCollapsibleOpen = false;

  mounted() {
    this.$nuxt.$on('more-dropdown:close', this.closeSubMenu);
  }

  beforeDestroy() {
    this.$nuxt.$off('more-dropdown:close', this.closeSubMenu);
  }

  public closeSubMenu() {
    this.isCollapsibleOpen = false;
  }
}
