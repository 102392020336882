import { Component, Inject, Prop, Vue } from 'nuxt-property-decorator';
import { Validator } from 'vee-validate';

@Component
export default class InputValidationMixin extends Vue {
  @Prop({ type: String, default: '' }) readonly name!: string;
  @Prop({ type: String, default: null }) readonly groupId!: string | null;
  @Prop({ type: [String, Object], default: Object }) readonly validation!: object | string | null;
  @Prop(Boolean) readonly required!: boolean;

  @Inject() $validator!: Validator;

  get validationRules() {
    if (typeof this.validation !== 'string') return { required: this.required, ...this.validation };

    const validationObject: { [key: string]: string | boolean } = { required: this.required };
    const rules = this.validation.split('|');
    for (const rule of rules) {
      const [key, value = true] = rule.split(':');
      validationObject[key] = value;
    }
    return validationObject;
  }

  get isRequired() {
    return !!this.validationRules.required;
  }

  get validationName() {
    return this.groupId || this.name;
  }

  get hasValidationError() {
    return this.$validator?.errors.has(this.validationName) || false;
  }

  get firstValidationError() {
    return this.$validator?.errors.first(this.validationName) || null;
  }

  public triggerValidation() {
    this.$validator?.validate(this.validationName);
  }
}
