
import { Component, Vue, Prop } from 'nuxt-property-decorator';

@Component
export default class InputTextSearch extends Vue {
  @Prop({ default: '' }) readonly value!: string;
  @Prop({ default: '' }) readonly placeholder!: string;
  @Prop({ type: Number, default: null }) readonly resultsCount!: number;
  @Prop(Boolean) readonly disabled!: boolean;

  isFocused = false;

  get className() {
    return { '-filled': !!this.value.length, '-focus': this.isFocused, '-disabled': this.disabled };
  }
}
