import { MessageReactionDto } from './MessageReactionDto';

export interface MessageDto {
  id: string;
  parentId?: string;
  threadId?: string;
  firstName: string;
  lastName: string;
  userId: string;
  participantId: string | null;

  upvoteCount: number;
  isUpvotedByCurrentUser: boolean;

  creationDate: string;
  lastUpdateDate?: string;

  text: string;
  messages: MessageDto[];
  reactions: MessageReactionDto[];
}

export interface MessageThreadDto {
  id: string;
  preview: string;
  token: string;
  lastUpdateDate: string;
}

export enum MessageThreadType {
  QuestionsAndAnswers = 'QuestionsAndAnswers',
  LiveChat = 'LiveChat',
  Direct = 'Direct',
}
