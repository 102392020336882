
import { Component, Prop, Vue } from 'nuxt-property-decorator';
import {
  EventDto,
  PageDto,
  ScheduleDto,
  FormFieldDto,
  FormType,
  transl8,
  FormFieldType,
  ReviewStatus,
} from '../../services';
import { ListingOptions, InputSelectOption } from '../../types';
import { FilterMultiSelect, FilterToggle } from '../filters';
import { FilterBar, MobileFilterBar } from '../layout';

@Component({
  components: {
    FilterMultiSelect,
    FilterToggle,
    FilterBar,
    MobileFilterBar,
  },
})
export default class PresentationFilterBar extends Vue {
  @Prop({ required: true }) readonly sessions!: ScheduleDto[];
  @Prop({ required: true }) readonly currentEvent!: EventDto;
  @Prop({ required: true }) readonly isOrganizer!: boolean;
  @Prop({ required: true }) readonly page!: PageDto | null;
  @Prop({ required: true }) readonly isReviewFilteringAvailable!: boolean;
  @Prop({ required: true }) readonly totalCount!: number;
  @Prop(Boolean) readonly areFiltersCollapsed!: boolean;
  @Prop(Boolean) readonly firstLoadCompleted!: boolean;

  get scheduleOptions(): InputSelectOption[] {
    return this.sessions
      .filter(({ presentationCount }) => presentationCount)
      .map(({ id, title }) => ({ id, label: transl8(title) }));
  }

  get additionalFilters(): FormFieldDto[] {
    if (!this.page || !this.page.formFields.length) return [];
    const form = this.currentEvent.forms.find(({ type }) => type === FormType.Submission)!;

    const isCorrectFieldType = ({ fieldType }: FormFieldDto) =>
      fieldType === FormFieldType.Radios ||
      fieldType === FormFieldType.Checkboxes ||
      fieldType === FormFieldType.Dropdown;

    return form.formSections
      .flatMap(({ formFields }) => formFields)
      .filter(field => this.page!.formFields.find(({ id }) => id === field.id) && isCorrectFieldType(field));
  }

  get canBookmark() {
    return this.$auth.loggedIn;
  }

  get hasLivePresentations() {
    return this.scheduleOptions.length > 0;
  }

  get hasSorting() {
    return this.listingOptions.length > 0;
  }

  get listingOptions(): ListingOptions[] {
    return [ListingOptions.Alphabetical, ListingOptions.Upvotes];
  }

  get reviewFilters(): InputSelectOption[] {
    return Object.values(ReviewStatus)
      .map(status => ({
        id: status,
        label: this.$t(`global.review_status.${status}`),
      }))
      .filter(({ id }) => id !== ReviewStatus.Unassigned);
  }

  get hasAppliedFilters() {
    const { page, layout, ...query } = this.$route.query;
    return Object.keys(query).length > 0;
  }

  public getFilterOptions(field: FormFieldDto): InputSelectOption[] {
    return field.formFieldChoices.map(({ id, shortLabel }) => ({ id, label: transl8(shortLabel) }));
  }
}
