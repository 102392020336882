
import { Component, Prop } from 'nuxt-property-decorator';
import { orderBy } from 'lodash-es';
import { MessageDto, MessageThreadType } from '../../services';
import CommentInput from './CommentInput.vue';
import Comment from './Comment.vue';
import BaseMessagingComponent from './BaseMessagingComponent';

@Component({
  components: {
    CommentInput,
    Comment,
  },
})
export default class PresentationQnA extends BaseMessagingComponent {
  @Prop({ default: null }) presentingUserId!: string | null;

  type: MessageThreadType = MessageThreadType.QuestionsAndAnswers;

  get sortedMessages(): MessageDto[] {
    const messagesOrder = 'asc';
    const sort = orderBy(this.messages, [x => x.upvoteCount, x => x.creationDate], ['desc', messagesOrder]);
    sort.forEach(message => {
      message.messages = orderBy(message.messages, [x => x.creationDate], ['asc']);
    });

    return sort;
  }

  public handleClickReply(id: string) {
    const commentInput = this.$refs[`reply-${id}`]![0];
    if (commentInput) {
      commentInput.expand();
    }
  }
}
