
import { Component, Vue, namespace } from 'nuxt-property-decorator';
import { EventDto, PageDto } from 'fourwaves-shared';

const EventsModule = namespace('events');

@Component({})
export default class EventDetails extends Vue {
  @EventsModule.Getter currentEvent!: EventDto;
  @EventsModule.Getter submissionPage!: PageDto | undefined;
  @EventsModule.Getter registrationPage!: PageDto | undefined;

  get showSubmissionPeriod(): boolean {
    return this.submissionPage != null && this.submissionPage.isVisible;
  }

  get isRegistrationPageVisible(): boolean {
    return this.registrationPage != null && this.registrationPage.isVisible;
  }

  get formattedStreetAddress(): string {
    const parts = [this.currentEvent.streetNumber, this.currentEvent.route];
    return parts.filter(Boolean).join(' ');
  }

  get formattedLocality(): string {
    const parts = [this.currentEvent.locality, this.currentEvent.administrativeAreaLevel1];
    return parts.filter(Boolean).join(', ');
  }

  get formattedCountry(): string {
    const parts = [this.currentEvent.country, this.currentEvent.postalCode];
    return parts.filter(Boolean).join(', ');
  }
}
