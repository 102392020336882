
import { Vue, Component, namespace, Ref } from 'nuxt-property-decorator';
import { EventDto } from 'fourwaves-shared';
import {
  UserDropdown,
  UserProfilePicture,
  OrganizationSplashScreen,
  OrganizationCreateModal,
} from 'fourwaves-shared/components';
import LangChooser from '~/components/layout/LangChooser.vue';

const EventsModule = namespace('events');

@Component({
  components: {
    LangChooser,
    UserProfilePicture,
    UserDropdown,
    OrganizationSplashScreen,
    OrganizationCreateModal,
  },
})
export default class SecondaryNavBar extends Vue {
  @EventsModule.Getter currentEvent!: EventDto;
  @EventsModule.Getter isOrganizer!: boolean;
  @EventsModule.Getter isChair!: boolean;

  @Ref() readonly createOrganizationModal?: OrganizationCreateModal;

  showSplashScreen = false;

  get isLoggedIn() {
    return this.$auth && this.$auth.$state.loggedIn;
  }

  get dashboardUrl(): string {
    return this.$api.getDashboardUrl(this.currentEvent.id);
  }

  public openOrganizationCreateModal() {
    this.createOrganizationModal?.openModal();
  }

  public onOrganizationCreated() {
    window.location.href = `${this.$config.API_URL}/event-redirect/organizations`;
  }
}
