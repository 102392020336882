export enum MessageReactionType {
  ThumbsUp = 'ThumbsUp', // 1F44D 👍
  ThumbsDown = 'ThumbsDown', // 1F44E 👎
  ClappingHands = 'ClappingHands', // 1F44F 👏
  GrinningFace = 'GrinningFace', // 1F600 😀
  PoutingFace = 'PoutingFace', // 1F621 😡
  GrinningFaceWithBigEyes = 'GrinningFaceWithBigEyes', // 1F603 😃
  FaceWithOpenMouth = 'FaceWithOpenMouth', // 1F62E 😮
  FaceWithTearsOfJoy = 'FaceWithTearsOfJoy', // 1F602 😂

  HundredPoints = 'HundredPoints', // 1F4AF 💯
  Fire = 'Fire', // 1F525 🔥
  PartyPopper = 'PartyPopper', // 1F389 Party popper 🎉
  FoldedHands = 'FoldedHands', // 1F64F 🙏
  Bullseye = 'Bullseye', // 1F3AF 🎯
  Eyes = 'Eyes', // 1F440 👀

  RedQuestionMark = 'RedQuestionMark', // 2753 ❓
  HighVoltage = 'HighVoltage', // 26A1 ⚡
  Rocket = 'Rocket', // 1F680 🚀

  Syringe = 'Syringe', // 1F489 💉
  PetriDish = 'PetriDish', // 1F9EB 🧫
  Microscope = 'Microscope', // 1F52C 🔬
  AtomSymbol = 'AtomSymbol', // 269B ⚛
  TestTube = 'TestTube', // 1F9EA 🧪
  Microbe = 'Microbe', // 1F9A0 🦠

  BottleWithPoppingCork = 'BottleWithPoppingCork', // 1F37E 🍾
  ClinkingBeerMugs = 'ClinkingBeerMugs', // 1F37B 🍻
  LabCoat = 'LabCoat', // 1F97C 🥼
  Dna = 'Dna', // 1F9EC 🧬
  Scientist = 'Scientist', // 1F9D1-200D-1F52C 👩‍🔬
}

export interface MessageReactionDto {
  type: MessageReactionType;
  userId: string;
}
