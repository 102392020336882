export default {
  file: {
    click_upload: 'Cliquez pour téléverser',
    drag_drop: 'glisser-déposer',
    upload_failed: 'Téléversement échoué, veuillez essayer un autre fichier',
    upload_failed_mobile: 'Téléversement échoué',
    view_file: 'Voir {fileName}',
    delete_file: 'Supprimer {fileName}',
    invalid_file: 'Fichier invalide',
    invalid_file_type: 'Type de fichier invalide {extension}',
    invalid_file_size: 'La taille du fichier doit être inférieure à {maxSize}',
    all_file_types: 'Tous types de fichiers acceptés.',
    accepted_file_types: 'Formats acceptés: {fileTypes}.',
    recommended_dimensions: 'Dimensions recommandées: {dimensions}.',
    max_dimensions: 'Dimensions maximales: {dimensions}.',
    max_size: 'Poids maximal du fichier: {size}.',
    download_ppt:
      'Ce fichier PowerPoint fait plus de 10 Mo et ne peut pas être prévisualisé. Veuillez télécharger le fichier pour visualiser son contenu.',
  },
  date: {
    calendar: {
      days: ['dim', 'lun', 'mar', 'mer', 'jeu', 'ven', 'sam'],
      months: ['jan.', 'fév.', 'mars', 'avril', 'mai', 'juin', 'juil.', 'août', 'sep.', 'oct.', 'nov.', 'déc.'],
    },
    select_date: 'Choisir la date',
    select_time: `Choisir l'heure`,
    select_datetime: `Choisir la date et l'heure`,
  },
  select: {
    placeholder: 'Sélectionner une option',
    country_label: 'Pays',
    state_label: 'Province / État',
  },
  email_confirmation: 'Confirmation du courriel',
  email: 'Courriel',
  author: {
    affiliations: 'Affiliations',
    title_add: 'Ajouter un auteur',
    title_edit: 'Modifier un auteur',
    press_enter: `Appuyez sur la touche d'entrée`,
    picker: 'Sélectionnez une affiliation',
    empty: 'Tapez une affiliation',
    presenter: 'Présentateur',
  },
  first_name: 'Prénom',
  last_name: 'Nom',
  password: {
    label: 'Mot de passe',
    help: 'Minimum de 8 caractères.',
    show: 'Montrer le mot de passe',
    hide: 'Cacher le mot de passe',
  },
};
