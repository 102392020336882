import { ActionTree } from 'vuex';
import { RootState } from '~/types';

export const actions: ActionTree<RootState, RootState> = {
  async nuxtServerInit({ dispatch }, pageContext) {
    const eventSlug = pageContext.params.slug
      ? pageContext.params.slug // using a direct link
      : pageContext.query.eId; // using a redirection link
    await dispatch('events/init', eventSlug);
  },
  async nuxtClientInit({ dispatch }, pageContext) {
    const eventSlug = pageContext.params.slug
      ? pageContext.params.slug // using a direct link
      : pageContext.query.eId; // using a redirection link
    await dispatch('events/init', eventSlug);
  },
};
