
import { Component, Vue, Prop } from 'nuxt-property-decorator';
import gsap from 'gsap';

@Component({})
export default class WavePath extends Vue {
  @Prop({ type: String, default: '1' }) opacity!: string;
  @Prop({ type: String, default: 'default' }) amplitude!: string;
  @Prop(Boolean) isReversed!: boolean;

  durations = { default: 2.25, shorter: 1.25, longer: 3.25 };

  coordonates = {
    a: {
      y: 9,
      xVariation: null,
    },
    b: {
      y: 28,
      xVariation: 0,
      originalLeftX: 93,
      originalRightX: 209,
      leftX: 93,
      rightX: 209,
    },
    c: {
      y: 7,
      xVariation: null,
      originalLeftX: 412,
      originalRightX: 567,
      leftX: 412,
      rightX: 567,
    },
    d: {
      y: 25,
      xVariation: 0,
      originalLeftX: 772,
      originalRightX: 894,
      leftX: 772,
      rightX: 894,
    },
    e: {
      y: 10,
      xVariation: null,
      originalLeftX: 1219,
      originalRightX: 1424,
      leftX: 1219,
      rightX: 1424,
    },
    f: {
      y: 31,
      xVariation: null,
    },
  };

  get transformAttribute(): string {
    return this.isReversed ? 'translate(1446) scale(-1, 1)' : '';
  }

  get pathCoordinate(): string {
    const { a, b, c, d, e, f } = this.coordonates;
    return `1446 0H-4.30362e-05L4.30362e-05 ${a.y}C23.5628 18 ${b.leftX} ${b.y} ${b.rightX} ${b.y}C393.636 ${b.y} ${c.leftX} ${c.y} ${c.rightX} ${c.y}C722.75 ${c.y} ${d.leftX} ${d.y} ${d.rightX} ${d.y}C1017.35 ${d.y} 1005.85 ${e.y} ${e.leftX} ${e.y}C1340.46 ${e.y} ${e.rightX} ${f.y} 1446 ${f.y}V0Z`;
  }

  get duration(): number {
    return this.durations[this.amplitude];
  }

  mounted() {
    Object.keys(this.coordonates).forEach(this.setPoint);
  }

  setPoint(key) {
    const destination: { y: number; rightX?: number; leftX?: number } = { y: 0 };

    destination.y = this.coordonates[key].y >= 16 ? this.random(2, 12) : this.random(20, 31);

    if (this.coordonates[key].xVariation !== null) {
      if (this.coordonates[key].originalLeftX === this.coordonates[key].leftX) {
        this.coordonates[key].xVariation =
          this.coordonates[key].xVariation > 0 ? this.random(-50, -10) : this.random(10, 50);
        destination.leftX = this.coordonates[key].originalLeftX + this.coordonates[key].xVariation;
        destination.rightX = this.coordonates[key].originalRightX + this.coordonates[key].xVariation;
      } else {
        this.coordonates[key].xVariation = 0;
        destination.leftX = this.coordonates[key].originalLeftX;
        destination.rightX = this.coordonates[key].originalRightX;
      }
    }
    this.animatePoint({ key, duration: this.duration, destination });
  }

  random(min: number, max: number): number {
    return Number((Math.random() * (max - min) + min).toFixed(2));
  }

  animatePoint({ key, duration, destination }) {
    gsap.to(this.coordonates[key], {
      duration,
      ease: 'power1.inOut',
      onComplete: this.setPoint,
      onCompleteParams: [key],
      ...destination,
    });
  }
}
