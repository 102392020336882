import { Route, Location } from 'vue-router';
import { Dictionary } from '../core';
import { isEmpty } from './typeAssertions';

export type QueryValue = string | number | boolean | (string | number | null)[] | null | undefined;

export type QueryParams = Dictionary<QueryValue>;

type CastQueryOptions = Partial<Record<'numbers' | 'booleans' | 'arrays', string[]>>;

export type LocationWithRawQuery = Omit<Location, 'query'> & { query?: QueryParams };

function createTypeCastReducer(keys: string[], castFunction: (value: QueryValue) => QueryValue) {
  return (q: QueryParams, [key, value]) => ({
    ...q,
    [key]: keys.includes(key) ? castFunction(value) : value,
  });
}

export function toStringOrNull(value: unknown) {
  return value !== null ? String(value) : value;
}

export function castQueryNumbers<T = QueryParams>(query: QueryParams, keys: string[]): T {
  return Object.entries(query).reduce(createTypeCastReducer(keys, Number), {}) as T;
}

export function castQueryBooleans<T = QueryParams>(query: QueryParams, keys: string[]): T {
  return Object.entries(query).reduce(
    createTypeCastReducer(keys, value => value === 'true'),
    {},
  ) as T;
}

export function castQueryArrays<T = QueryParams>(query: QueryParams, keys: string[]): T {
  return Object.entries(query).reduce(
    createTypeCastReducer(keys, value => (!Array.isArray(value) ? [value as string | number] : value)),
    {},
  ) as T;
}

export function castQuery<T = QueryParams>(
  query: QueryParams,
  { numbers = [], booleans = [], arrays = [] }: CastQueryOptions = {},
): T {
  const withArrays = castQueryArrays(query, arrays);
  const withBooleans = castQueryBooleans(withArrays, booleans);
  return castQueryNumbers<T>(withBooleans, numbers);
}

export function normalizeQuery(query: Dictionary<unknown>): Location['query'] {
  return Object.entries(query).reduce((q, [key, value]) => {
    if (isEmpty(value)) return q;
    return { ...q, [key]: Array.isArray(value) ? value.map(String) : String(value) };
  }, {});
}

export function getLocationFromRoute($route: Route): Location {
  return {
    path: $route.path,
    hash: $route.hash,
    query: $route.query,
    params: $route.params,
    name: $route.name || undefined,
  };
}

export function mergeRoute($route: Route, newRoute: LocationWithRawQuery): Location {
  const currentLocation = getLocationFromRoute($route);
  const { query = {}, ...newLocation } = newRoute;

  return {
    ...currentLocation,
    ...newLocation,
    query: normalizeQuery(query),
  };
}
