
import { Prop, Component, Ref, mixins, Watch } from 'nuxt-property-decorator';
import { Collapsible } from '../../../layout';
import Hint from '../../../Hint.vue';
import { getWordCount } from '../../../../helpers/string';
import InputTextMixin from './InputTextMixin';

@Component({ components: { Collapsible, Hint } })
export default class InputTextArea extends mixins(InputTextMixin) {
  @Prop({ type: [String, Number, Array] }) readonly value!: string | number | string[];
  @Prop({ default: 2 }) readonly rows!: number;
  @Prop({ type: Number, default: 0 }) readonly maxWords!: number;

  @Ref() textareaElement?: HTMLTextAreaElement;

  get inputClassNames() {
    return {
      '-destructive': this.hasValidationError,
    };
  }

  get footerText() {
    if (this.hasValidationError) return this.firstValidationError;
    if (this.maxWords) return `${this.wordCount} / ${this.maxWords}`;
    return this.help || '';
  }

  get wordCount(): number {
    if (!this.value) return 0;
    return getWordCount(String(this.value) || '');
  }

  async mounted() {
    await this.$nextTick();
    if (this.autofocus) this.textareaElement?.focus();
  }

  @Watch('value.length')
  onValueChange(newValue: string | number, oldValue: string | number) {
    if (newValue !== oldValue && this.hasValidationError) this.triggerValidation();
  }

  public onBlur() {
    this.$emit('blur');
    // prevent required validation on blur
    if (this.value) this.triggerValidation();
  }
}
