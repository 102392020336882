
import { Vue, Component, Prop } from 'nuxt-property-decorator';
import { NestedPage } from 'fourwaves-shared';
import MenuDropdown from './MenuDropdown.vue';
import SubMenu from './SubMenu.vue';

@Component({ components: { MenuDropdown, SubMenu } })
export default class MoreDropdown extends Vue {
  @Prop({ required: true }) pages!: NestedPage[];

  public closeSubMenu() {
    this.$nuxt.$emit('more-dropdown:close');
  }
}
