
import { Component, Vue, Prop } from 'nuxt-property-decorator';
import { RawHtml } from '../../layout/';
import { Size, Color, Alignment } from '../../../types';
import { TranslateResult } from '../../../types/i18n';

@Component({
  components: {
    RawHtml,
  },
})
export default class Alert extends Vue {
  @Prop({ type: String, default: Color.Default }) readonly color!: Color;
  @Prop({ type: String, default: 'md' }) readonly size!: string;
  @Prop({ type: String, default: Alignment.Start }) readonly alignment!: string;
  @Prop({ type: String, default: null }) title!: TranslateResult | string;
  @Prop({ required: false, default: '' }) message!: TranslateResult | string;
  @Prop(String) readonly icon!: string;
  @Prop(Boolean) readonly dismissX!: boolean;
  @Prop(Boolean) readonly banner!: boolean;
  @Prop(Boolean) readonly static!: boolean;

  Sizes = Size;

  hasActions = false;

  get rootClassNames() {
    return [
      `-${this.Sizes[this.size] || this.size}`,
      {
        '-banner': this.banner,
        '-align-center': this.alignment === Alignment.Center,
        '-static': this.static,
        '-dismiss': this.showDismissX,
        [`-${this.color}`]: this.color !== Color.Default && this.color,
      },
    ];
  }

  get showDismissX() {
    return this.dismissX && !this.$slots.actions?.length;
  }

  created() {
    this.detectSlotContent();
  }

  beforeUpdate() {
    this.detectSlotContent();
  }

  public detectSlotContent() {
    this.hasActions = !!this.$slots.actions?.length;
  }
}
