
import { Component, Vue, namespace, Watch } from 'nuxt-property-decorator';
import FourwavesLogoFull from 'fourwaves-shared/src/images/fourwaves-dark-full.png';
import { shuffle } from 'lodash-es';

type Testimonial = {
  quote: string;
  title: string;
  name: string;
  organization_id: string;
  organization_name: string;
  hasLogo: boolean;
};

const EventsModule = namespace('events');
const FooterModule = namespace('footer');

const testimonialLength = 20;

const organizationFilters = [
  'cbs',
  'cdrv',
  'chuqul',
  'chusj',
  'cisss',
  'crcao',
  'crchum',
  'inrs',
  'lbl',
  'mcgill',
  'mpt',
  'mskcc',
  'mtroyal',
  'phare',
  'rimuhc',
  'rrsv',
  'ubc',
  'uidaho',
  'ulaval',
  'umontreal',
  'uottawa',
  'uppsala',
  'uqam',
  'usherbrooke',
  'utoronto',
  'ysu',
];

@Component({})
export default class Footer extends Vue {
  @EventsModule.State isWhiteLabel!: boolean;
  @FooterModule.Action setFooterVisibility!: (isVisible: boolean) => void;
  @FooterModule.State isFooterVisible!: boolean;

  readonly FourwavesLogoFull = FourwavesLogoFull;

  testimonials: Testimonial[] = [];
  sliderTimer: ReturnType<typeof setTimeout> | null = null;
  sliderIndex = 0;

  footerObserver: IntersectionObserver | null = null;

  @Watch('testimonialList', { immediate: true })
  onLanguageChange() {
    this.testimonials = this.getRandomTestimonials().map(t => ({
      ...t,
      hasLogo: t.organization_id !== '' && organizationFilters.includes(t.organization_id),
    }));
  }

  get testimonialList(): Testimonial[] {
    const testimonials = this.$i18n.t<Testimonial[]>('testimonials');

    if (!Array.isArray(testimonials)) return [];

    return testimonials;
  }

  get organizationFilter(): string | null {
    return organizationFilters.find(cat => this.$auth.user?.email?.split('@')[1].includes(cat)) || null;
  }

  get filteredTestimonials(): Testimonial[] {
    if (!this.organizationFilter) return this.testimonialList;

    return this.testimonialList.filter(testimonial => testimonial.organization_id === this.organizationFilter);
  }

  mounted() {
    this.createIntersectionObserver();
  }

  beforeDestroy() {
    this.clearSlider();

    if (this.footerObserver) {
      this.footerObserver.unobserve(this.$el);
    }
  }

  public createIntersectionObserver() {
    if ('IntersectionObserver' in window) {
      this.footerObserver = new IntersectionObserver(
        entry => {
          const isIntersecting = entry[0].isIntersecting;
          this.setFooterVisibility(isIntersecting);

          if (isIntersecting) {
            this.iterateSlide();
          } else {
            this.clearSlider();
          }
        },
        { root: null, rootMargin: '0px', threshold: 0.3 },
      );

      // wait for page reflow
      setTimeout(() => {
        if (this.footerObserver) {
          this.footerObserver.observe(this.$el);
        }
      }, 800);
    } else {
      this.setFooterVisibility(true);
      this.iterateSlide();
    }
  }

  public getRandomTestimonials(): Testimonial[] {
    const testimonials = shuffle(this.filteredTestimonials).slice(0, testimonialLength);

    if (testimonials.length >= testimonialLength) return testimonials;

    const otherTestimonials = this.testimonialList.filter(
      testimonial => testimonial.organization_id !== this.organizationFilter,
    );

    return [...testimonials, ...shuffle(otherTestimonials).slice(0, testimonialLength - testimonials.length)];
  }

  public iterateSlide() {
    this.sliderTimer = setTimeout(() => {
      if (this.sliderIndex + 1 === this.testimonials.length) {
        this.sliderIndex = 0;
      } else {
        this.sliderIndex++;
      }

      this.iterateSlide();
    }, 5000);
  }

  public clearSlider() {
    if (this.sliderTimer) clearInterval(this.sliderTimer);
  }
}
