export default {
  bold: 'Gras',
  aria_bold: 'Basculer en gras',
  italic: 'Italique',
  aria_italic: 'Basculer en italique',
  underline: 'Souligné',
  aria_underline: 'Basculer en souligné',
  subscript: 'Indice',
  aria_subscript: 'Basculer en indice',
  superscript: 'Exposant',
  aria_superscript: 'Basculer en exposant',
  link: 'Lien',
  aria_link: 'Insérer ou modifier un lien',
  alignLeft: 'Aligner à gauche',
  aria_alignLeft: 'Aligner à gauche',
  alignCenter: 'Centrer',
  aria_alignCenter: 'Centrer',
  video: 'Vidéo',
  aria_video: 'Insérer une vidéo',
  horizontalLine: 'Ligne horizontale',
  aria_horizontalLine: 'Insérer une ligne horizontale',
  bulletList: 'Liste à puces',
  aria_bulletList: 'Basculer en liste à puces',
  orderedList: 'Liste numérotée',
  aria_orderedList: 'Basculer en liste numérotée',
  title: 'Titre {level}',
  paragraph: 'Paragraphe',
  open_format_dropdown: 'Ouvrir le menu de formatage',
  url: 'URL',
  video_url: 'URL de la vidéo',
  enter_url: 'Entrez une URL',
  supported_video: 'Seulement les URLs YouTube ou Vimeo.',
  open_overlow_menu: 'Ouvrir le menu de débordement',
};
