import { Node, mergeAttributes } from '@tiptap/core';

export default Node.create({
  name: 'legacy-video',

  group: 'block',

  addAttributes() {
    return {
      src: {
        default: null,
        parseHTML: element => element.getAttribute('src'),
        renderHTML: ({ src }) => {
          if (!src) return;

          const allowedHosts = ['youtube.com', 'youtu.be', 'vimeo.com'];
          if (!allowedHosts.some(host => src.includes(host))) return;

          return { src };
        },
      },
      width: { default: null },
      height: { default: null },
      frameborder: { default: 0 },
      allowfullscreen: { default: true },
    };
  },

  parseHTML() {
    return [{ tag: 'iframe[src*="youtube.com"], iframe[src*="youtu.be"], iframe[src*="vimeo.com"]' }];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'div',
      {
        'data-legacy-video': '',
        style: 'padding:56.25% 0 0 0; position:relative;',
      },
      [
        'iframe',
        mergeAttributes(HTMLAttributes, {
          style: 'position:absolute; top:0; left:0; width:100%; height:100%;',
        }),
      ],
    ];
  },
});
