
import { Component, Vue, Ref, Emit, Prop } from 'nuxt-property-decorator';
import { EventListingDto, GetEventsQueryStatus, isSuccess, isValidationError } from '../../services';
import { InputOption, InputOptionWithLink } from '../../types';
import InputText from '../design-system/input/input-text/InputText.vue';
import InputSelectCountry from '../design-system/input/input-select/InputSelectCountry.vue';
import InputRadioGroup from '../design-system/input/input-radio/InputRadioGroup.vue';
import DelimiterTextArea from '../design-system/input/input-text/DelimiterTextArea.vue';
import { CreateOrganizationModel, MarketSegment, OrganizationDto, Role } from '../../services/fourwaves/models';
import FullPageModal from '../modals/FullPageModal.vue';
import CheckboxSelectionManager from '../design-system/organisms/CheckboxSelectionManager.vue';

@Component({
  $_veeValidate: {
    validator: 'new',
  },
  components: {
    FullPageModal,
    InputText,
    InputSelectCountry,
    InputRadioGroup,
    DelimiterTextArea,
    CheckboxSelectionManager,
  },
})
export default class OrganizationCreateModal extends Vue {
  @Prop({ type: Array, default: Array }) readonly adminOrganizations!: OrganizationDto[];

  @Ref() readonly modal?: FullPageModal;

  @Emit() close() {}
  @Emit() onOpen() {}
  @Emit()
  public organizationCreated(organizationId: string): string {
    return organizationId;
  }

  model: CreateOrganizationModel = {
    name: '',
    marketSegment: '',
    civicAddress: {
      addressLine1: '',
      city: '',
      postalCode: '',
      countryRegion: '',
      stateProvince: '',
    },
    emailAddresses: [],
    eventIds: [],
  };

  events: EventListingDto[] = [];
  importEventsOptions: InputOptionWithLink[] = [];
  stepIndex = 0;

  get marketSegmentOptions(): InputOption<MarketSegment>[] {
    return Object.values(MarketSegment).map(key => ({
      id: key,
      label: this.$t(`shared.organizations.create_org.market_segment.${key}.label`),
      subLabel:
        key !== MarketSegment.Other ? this.$t(`shared.organizations.create_org.market_segment.${key}.subLabel`) : '',
    }));
  }

  get stepsTitle() {
    return this.events.length
      ? [
          this.$t('shared.organizations.create_org.market_segment.title'),
          this.$t('shared.organizations.create_org.infos.title'),
          this.$t('shared.organizations.create_org.add_admins.title'),
          this.$t('shared.organizations.create_org.import_events.title'),
        ]
      : [
          this.$t('shared.organizations.create_org.market_segment.title'),
          this.$t('shared.organizations.create_org.infos.title'),
          this.$t('shared.organizations.create_org.add_admins.title'),
        ];
  }

  get subtitleCaption() {
    if (this.stepIndex === 0) return this.$t('shared.organizations.create_org.market_segment.subtitle');
    if (this.stepIndex === 2) return this.$t('shared.organizations.create_org.add_admins.subtitle');
    if (this.stepIndex === 3) return this.$t('shared.organizations.create_org.import_events.subtitle');
    return '';
  }

  get isWaiting() {
    return this.$wait.is('save');
  }

  public async openModal(preSelectedEventId?: string) {
    if (preSelectedEventId) this.model.eventIds = [preSelectedEventId];
    const { items } = await this.$api.getEvents({
      status: GetEventsQueryStatus.All,
      userRoles: [Role.Organizer, Role.Administrator],
      page: 1,
      pageSize: 500,
    });
    this.events = items.filter(
      event => !event.organizationId || this.adminOrganizations.some(({ id }) => id === event.organizationId),
    );

    this.importEventsOptions = this.computeOptions(this.events);
    this.modal?.open();
  }

  public async handleNext() {
    const isValid = await this.$validator.validateAll();
    if (isValid) this.stepIndex++;
  }

  public handleBack() {
    this.stepIndex--;
  }

  public async handleSubmit() {
    if (!(await this.$validator.validateAll())) return;

    this.$wait.start('save');
    const result = await this.$api.createOrganization(this.model);
    if (isSuccess(result)) {
      this.organizationCreated(result.organizationId);
      this.modal?.close();
    } else if (isValidationError(result)) {
      this.feedErrorBag(result);
    }

    this.$wait.end('save');
  }

  public computeOptions(events: EventListingDto[]): InputOptionWithLink[] {
    return events.map(event => {
      const organizationName = event.organizationName ? this.transl8(event.organizationName) : null;
      return {
        id: event.id,
        label: this.transl8(event.name),
        subLabel: organizationName || this.$t('shared.organizations.create_org.import_events.no_organization'),
        link: { name: 'events-dashboard', params: { id: event.id } },
      };
    });
  }

  public resetModel() {
    this.model = {
      name: '',
      marketSegment: '',
      civicAddress: {
        addressLine1: '',
        city: '',
        postalCode: '',
        countryRegion: '',
        stateProvince: '',
      },
      emailAddresses: [],
      eventIds: [],
    };
  }

  public onClose() {
    this.close();
    this.stepIndex = 0;
    this.resetModel();
    this.$validator.reset();
  }
}
