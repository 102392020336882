
import { Vue, Prop, Component, Emit, Model } from 'nuxt-property-decorator';
import { v4 as uuid } from 'uuid';
import { TranslateResult } from '../../../../types/i18n';

@Component({})
export default class InputRadio extends Vue {
  @Prop({ type: [String, Boolean, Number], required: true }) readonly value!: string | boolean | number;
  @Prop({ type: String, default: '' }) readonly label!: string | TranslateResult;
  @Prop({ required: true }) readonly groupId!: string;
  @Prop({ type: String, default: null }) readonly subLabel!: string | TranslateResult;
  @Prop({ type: String, default: null }) readonly labelId!: string;
  @Prop({ default: 'md' }) readonly size!: string;
  @Prop(Boolean) readonly disabled!: boolean;

  @Model('change', { type: [String, Boolean, Number], default: null }) readonly currentValue!:
    | string
    | boolean
    | number;

  uniqueId = uuid();

  get isChecked(): boolean {
    return this.currentValue === this.value;
  }

  get labelledById() {
    if (!this.label && !this.labelId) return null;
    return this.labelId || `${this.value}-label`;
  }

  get ariaChecked() {
    return this.isChecked ? 'true' : 'false';
  }

  get labelClassNames() {
    return {
      '-small': this.size === 'sm',
      '-checked': this.isChecked,
      '-disabled': this.disabled,
    };
  }

  @Emit('change')
  emitChange() {
    return this.value;
  }
}
