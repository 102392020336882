import { MarketSegment } from '../../services/fourwaves/models';

export default {
  create_org: {
    title: 'Organizations are here!',
    description:
      'Organizations will become the new hub around which your events will revolve, with a number of new benefits and tools at your doorstep. Start with creating and setting up your organization, and we’ll take care of the rest. ',
    modal_title: 'Create Organization',
    learn_more_link: 'https://fourwaves.com/blog/introducing-organizations/',
    maybe_later: 'Maybe Later',
    create: 'Create',
    market_segment: {
      title: 'Organization Type',
      subtitle: 'Select what type corresponds the most to your organization.',
      [MarketSegment.UniversityGroup]: {
        label: 'University Group',
        subLabel: 'A student office or department organizing events for university members.',
      },
      [MarketSegment.FundedResearchNetwork]: {
        label: 'Funded Research Network',
        subLabel: 'A publicly-funded group organizing events.',
      },
      [MarketSegment.InformalResearchNetwork]: {
        label: 'Informal Research Network',
        subLabel: 'An informal community, local or international.',
      },
      [MarketSegment.Association]: {
        label: 'Established Association or Society',
        subLabel: 'A registered association or society.',
      },
      [MarketSegment.ContinuingEducation]: {
        label: 'Continuing Education Office',
        subLabel: 'An office organizing events for continuing education.',
      },
      [MarketSegment.Private]: {
        label: 'Private',
        subLabel: 'A private organization hosting events for various purposes.',
      },
      [MarketSegment.Other]: {
        label: 'Other',
      },
    },
    infos: {
      title: 'General Info',
      name: 'Organization Name',
      name_hint: 'Be as specific as possible. Ex. Department of Marine Biology of the Brightwater University.',
      address: 'Street Address',
      address_hint: 'Required to publish your events, upgrade your plan, etc.',
      city: 'City',
      zip: 'Postal / Zip Code',
    },
    add_admins: {
      title: 'Add Organization Admins',
      subtitle:
        'Invite your colleagues to join your organization. An email will be sent to each admin with a link to activate their account or login.',
      emails: 'Email Addresses',
      emails_placeholder: 'colleague@something.edu',
      emails_help: 'Type one or more email addresses.',
    },
    import_events: {
      title: 'Import Events',
      subtitle:
        'Select the events you wish to link to this organization. All admins will be able to view and edit them.',
      select_all: 'Select all events',
      no_organization: 'No Organization',
    },
    success_message: 'Organization successfully created!',
  },
};
