export interface CivicAddressDto {
  addressLine1: string;
  city: string;
  countryRegion: string;
  stateProvince: string;
  postalCode: string;
}

export function isCivicAddressValid(address: CivicAddressDto): boolean {
  if (address.addressLine1 && address.city && address.postalCode) {
    if (address.countryRegion === 'CA' || address.countryRegion === 'US') {
      return !!address.stateProvince;
    } else {
      return !!address.countryRegion;
    }
  } else {
    return false;
  }
}
export interface BillingInfoDto {
  name: string;
  email?: string;
  vatNumber?: string;
  civicAddress: CivicAddressDto;
}

export function format(info: BillingInfoDto) {
  if (!info) return '---';

  let address = `${info.name},`;
  address = `${address} ${info.civicAddress.addressLine1}, ${info.civicAddress.city},`;
  address = `${address} ${info.civicAddress.stateProvince} (${info.civicAddress.postalCode}),`;
  address = `${address} ${info.civicAddress.countryRegion}`;

  if (info.vatNumber) address = `${address}, VAT: ${info.vatNumber}`;

  return address;
}
