
import { Prop, Component, Model, mixins } from 'nuxt-property-decorator';
import { v4 as uuid } from 'uuid';
import { InputOption, InputOptionWithLink } from '../../../../types';
import Badge from '../../../Badge.vue';
import { TranslateResult } from '../../../../types/i18n';
import Collapsible from '../../../layout/Collapsible.vue';
import InputValidationMixin from '../InputValidationMixin';
import InputCheckbox from './InputCheckbox.vue';

type InputOptionValue = InputOption['id'];

@Component({ components: { InputCheckbox, Badge, Collapsible } })
export default class InputCheckboxGroup extends mixins(InputValidationMixin) {
  @Prop({ required: true }) readonly options!: InputOptionWithLink[];
  @Prop({ type: String, required: true }) readonly groupId!: string;
  @Prop({ type: String, default: null }) readonly label!: string | TranslateResult;
  @Prop(Boolean) readonly disabled!: boolean;

  @Model('change', { type: Array, default: null }) readonly value!: InputOptionValue[];

  uniqueId = uuid();

  get innerValue(): InputOptionValue[] {
    return this.value || [];
  }

  set innerValue(value: InputOptionValue[]) {
    this.$emit('change', value);
  }

  get validationValue() {
    return this.innerValue.join(',');
  }

  public isChecked(optionId: string | number | boolean): boolean {
    return this.innerValue.includes(optionId);
  }
}
