import { TranslateResult } from './i18n';
import { LocationWithRawQuery } from './../helpers/router';

export enum InputFileUploadStatus {
  Pending = 'pending',
  Uploading = 'uploading',
  Complete = 'complete',
  Existing = 'existing',
  Error = 'error',
}

export enum InputFileType {
  Image = 'image',
  Video = 'video',
  Other = 'other',
}

export enum InputFileInvalidMessage {
  Size = 'invalid_file_size',
  Type = 'invalid_file_type',
  Server = 'server_validation',
}

export interface InputSelectOption {
  id: string | number;
  label: string | TranslateResult;
  hint?: string | TranslateResult;
}

export type FileProcessor = (file: File) => Promise<File | null>;

export type InputFileMeta = {
  size: string;
  type: InputFileType;
  fileName: string;
  extension?: string;
};

export type InputFileValue = {
  resourceId: string;
  originalFileName: string;
  url: string;
  size: number;
  type: string;
  key: string;
  file?: File;
};

export type InputFilePartialValue = Partial<InputFileValue> & {
  resourceId: string;
};

export type InputFileListEntry = Partial<InputFileValue> & {
  originalFileName: string;
  size: number;
  type: string;
  key: string;
  invalidFile?: InputFileInvalidMessage;
  serverErrorCode?: string | null;
};

export interface InputOption<T = string | number | boolean> {
  id: T;
  label: string | TranslateResult;
  subLabel?: string | TranslateResult;
  disabled?: boolean;
  unavailable?: boolean;
  icon?: string;
  keepOpen?: boolean;
}

export interface InputOptionWithLink<T = string | number | boolean> extends InputOption<T> {
  link?: LocationWithRawQuery;
}
