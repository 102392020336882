import { FormFieldDto, getOptionsFromFields, transl8, TranslatableField } from '../services';
import { getRteContent, isValidGuid } from '../helpers';
import { InputOption } from '../types';

export interface ContentMixins {
  transl8(field: TranslatableField, i18n?: any): string;
  getRteContent(html: string): string;
  truncate(text: string, length: number, suffix?: string): string;
  getOptionsFromFields(formFields: FormFieldDto[]): InputOption[];
  getFieldPickerOptionsFromFields(formFields: FormFieldDto[]): InputOption[];
  stripHtml(rawHTML: string): string;
  getResourceIdFromUrl(url: string | null): string | null;
  getDelimitedValue(value: string): string[];
}

export const contentMixins: ContentMixins = {
  transl8,
  getRteContent(html) {
    return getRteContent(html);
  },
  truncate(text, length, suffix = '...') {
    if (text && text.length > length) {
      return text.substring(0, length) + suffix;
    } else {
      return text;
    }
  },
  getOptionsFromFields(formFields) {
    return getOptionsFromFields(formFields);
  },
  getFieldPickerOptionsFromFields(formFields) {
    return getOptionsFromFields(formFields, true);
  },
  stripHtml(rawHTML: string) {
    const midWordHtmlTagRegex = /([^\s])<\/?[^>]+>([^\s])/g;
    const htmlTagRegex = /<(?:"[^"]*"['"]*|'[^']*'['"]*|[^'">])+>/g;
    const nbspOrMultipleWhitespaceRegex = /&nbsp;|\s{2,}/g;

    return rawHTML
      .replace(midWordHtmlTagRegex, '$1$2')
      .replace(htmlTagRegex, ' ')
      .replace(nbspOrMultipleWhitespaceRegex, ' ')
      .trim();
  },
  getResourceIdFromUrl(url: string | null) {
    if (!url || url.includes('default_')) return null;
    const captureIdRegex = /((?:\w|-)+)(?=(?:\.\w+)?(?:\?.+)?$)/;
    const [resourceId = null] = url.match(captureIdRegex) || [];
    if (resourceId && isValidGuid(resourceId)) return resourceId;
    return null;
  },
  getDelimitedValue(value: string): string[] {
    return value
      .split(/[,; \r\n]/) // [,] comma, [;] semi-colon, [ ] space
      .filter(str => str); // Filter empty string (double space for example)
  },
};
