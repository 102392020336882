
import { Editor } from '@tiptap/vue-2';
import { Component, Prop, Ref, Vue } from 'nuxt-property-decorator';
import SimpleBar from 'simplebar';
import { TranslateResult } from '../../types/i18n';
import { Collapsible } from '../layout';

interface Format {
  label: TranslateResult;
  type: 'heading' | 'paragraph';
  action: string;
  level?: number;
}

@Component({
  components: {
    Collapsible,
  },
})
export default class TiptapFormatDropdown extends Vue {
  @Prop({ required: true }) editor!: Editor;

  @Ref() readonly formatsMenuContainer?: HTMLMenuElement;

  isOpen = false;
  simplebarInstance: SimpleBar | null = null;
  scrollElement: HTMLElement | null = null;
  lastActiveFormat: Format | null = null;

  get formats(): Format[] {
    return [
      {
        label: this.$t('shared.tiptap.title', { level: 1 }),
        type: 'heading',
        action: 'toggleHeading',
        level: 2,
      },
      {
        label: this.$t('shared.tiptap.title', { level: 2 }),
        type: 'heading',
        action: 'toggleHeading',
        level: 3,
      },
      {
        label: this.$t('shared.tiptap.title', { level: 3 }),
        type: 'heading',
        action: 'toggleHeading',
        level: 4,
      },
      {
        label: this.$t('shared.tiptap.paragraph'),
        type: 'paragraph',
        action: 'setParagraph',
      },
    ];
  }

  get currentActiveFormat() {
    const foundActiveFormat = this.formats.find(format => this.isFormatActive(format));

    if (foundActiveFormat) {
      this.lastActiveFormat = foundActiveFormat;
      return foundActiveFormat;
    }

    if (!this.lastActiveFormat) return this.formats[this.formats.length - 1];

    return this.lastActiveFormat;
  }

  async mounted() {
    await this.$nextTick();
    if (!this.formatsMenuContainer) return;
    this.simplebarInstance = new SimpleBar(this.formatsMenuContainer);
    await this.$nextTick();
    this.formatsMenuContainer?.querySelector('[tabindex="0"]')?.removeAttribute('tabindex');
    this.scrollElement = this.simplebarInstance.getScrollElement();
  }

  public isFormatActive({ type, level }: Format) {
    if (level) return this.editor.isActive(type, { level });
    return this.editor.isActive(type);
  }

  public onFormatClick({ action, level }: Format) {
    if (level) {
      this.editor.chain().focus()[action]({ level }).run();
    } else {
      this.editor.chain().focus()[action]().run();
    }

    this.isOpen = false;
  }
}
