
import { Component, Vue, Prop } from 'nuxt-property-decorator';

@Component({})
export default class MoreDropdown extends Vue {
  @Prop(Boolean) allowEdit!: boolean;

  dropdownActive = false;

  beforeDestroy() {
    document.removeEventListener('click', this.clickOutside);
  }

  public handleClick() {
    if (this.dropdownActive) {
      this.closeDropdown();
    } else {
      this.openDropdown();
    }
  }

  public openDropdown() {
    setTimeout(() => document.addEventListener('click', this.clickOutside), 50);
    this.dropdownActive = true;
  }

  public closeDropdown() {
    this.dropdownActive = false;
    document.removeEventListener('click', this.clickOutside);
  }

  public clickOutside(event) {
    const dropdown = this.$refs.dropdown as HTMLElement;

    if (event.target !== dropdown && !dropdown.contains(event.target)) {
      this.closeDropdown();
    }
  }

  public onEdit() {
    this.closeDropdown();
    this.$emit('edit');
  }

  public onDelete() {
    this.closeDropdown();
    this.$emit('delete');
  }
}
