import svgIconSprite from '~/assets/sprite/gen/icons.svg';

function prepareSpriteString(sprite: string) {
  return sprite.replace(/.*?$/m, '').replace(/(<svg)([^>]*>)/, '$1 style="display: none"$2');
}

export default async function ({ $axios }) {
  const axios = $axios.create({ withCredentials: false });
  axios.setBaseURL('');
  const { data: sprite } = await axios(svgIconSprite);
  document.body.insertAdjacentHTML('afterbegin', prepareSpriteString(sprite));
}
