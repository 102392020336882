import moment from 'moment-timezone';

export default ({ req }, inject) => {
  const language = getLanguage(req);
  const localMoment = moment().locale(language);
  const LT = localMoment.localeData().longDateFormat('LT');
  const LTS = localMoment.localeData().longDateFormat('LTS');

  moment.updateLocale('fr', {
    monthsShort: ['Janv.', 'Févr.', 'Mars', 'Avril', 'Mai', 'Juin', 'Juil.', 'Août', 'Sept.', 'Oct.', 'Nov.', 'Déc.'],
    longDateFormat: {
      LT,
      LTS,
      L: 'DD/MM/YYYY',
      LL: 'D MMMM YYYY',
      LLL: 'D MMM YYYY - LT',
      LLLL: 'dddd D MMMM YYYY LT',
    },
  });

  moment.updateLocale('en', {
    monthsShort: ['Jan.', 'Feb.', 'March', 'April', 'May', 'June', 'July', 'Aug.', 'Sept.', 'Oct.', 'Nov.', 'Dec.'],
    longDateFormat: {
      LT,
      LTS,
      L: 'MM/DD/YYYY',
      LL: 'MMMM D, YYYY',
      LLL: 'MMM D, YYYY - LT',
      LLLL: 'dddd, MMMM D, YYYY LT',
    },
  });

  inject('moment', moment);
};

function getLanguage(request: any) {
  if (process.client) return navigator.language;

  const acceptLanguage = request?.headers?.['accept-language'];
  if (acceptLanguage) {
    const firstAcceptLanguage = acceptLanguage.replace(/\s/g, '').split(/[;,]/)[0];
    return firstAcceptLanguage === '*' ? 'en' : firstAcceptLanguage;
  }

  return 'en';
}
