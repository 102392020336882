
import { Component, Prop, ProvideReactive, Watch, Vue, namespace } from 'nuxt-property-decorator';
import { Route } from 'vue-router';
import { getLocationFromRoute } from '../../helpers';
import { PresentationMedia } from '../../types';

const HistoryModule = namespace('history');

@Component
export default class MediaViewer extends Vue {
  @HistoryModule.State readonly previousRoute!: Route | null;

  @Prop({ required: true }) readonly posterIndexRouteName!: string;
  @Prop(Boolean) readonly isLiveApp!: boolean;
  @Prop(Boolean) readonly isConversationOpen!: boolean;
  @Prop(Boolean) readonly isConversationCollapsed!: boolean;

  @Prop({ type: Object, required: true }) context!: {
    isPresenter: boolean;
    presentationTitle: string;
    presentationMedia: PresentationMedia;
  };

  @ProvideReactive() isPresenter = this.context.isPresenter;
  @ProvideReactive() presentationTitle = this.context.presentationTitle;
  @ProvideReactive() presentationMedia: PresentationMedia = this.context.presentationMedia;

  storedScrollPosition = 0;
  backLink: Route | null = null;

  get isVisible() {
    return /slug-(presentations|abstracts)-id-(figures|poster|slides|video)/.test(this.$route.name!);
  }

  get closeLocation() {
    return this.backLink
      ? getLocationFromRoute(this.backLink)
      : this.localePath({ name: `${this.posterIndexRouteName}-id`, params: { id: this.$route.params.id } });
  }

  get viewPresentationLabel() {
    return this.$device.laptop ? this.$t('shared.presentations.view') : this.$t('shared.presentations.details');
  }

  @Watch('isVisible', { immediate: true })
  onVisibilityChange(isVisible: boolean, wasVisible: boolean) {
    if (isVisible && !wasVisible) this.backLink = this.previousRoute;
    if (this.isLiveApp) return this.$store.commit('events/setMediaViewerState', isVisible);
    if (isVisible) this.preventBodyScroll();
    else if (wasVisible) this.restoreBodyScroll();
  }

  public isTabActive(tab: string) {
    return this.$route.name?.includes(`-${tab}__`);
  }

  public closePoster() {
    this.$router.push(this.closeLocation);
  }

  public preventBodyScroll() {
    if (!process.client || !document.scrollingElement) return;
    this.storedScrollPosition = document.scrollingElement.scrollTop || 0;
    document.documentElement.style.setProperty('--scroll-position', `${this.storedScrollPosition}px`);
    document.body.classList.add('-prevent-scroll');
  }

  public restoreBodyScroll() {
    if (!process.client || !document.scrollingElement) return;
    const position = this.storedScrollPosition || 0;
    this.storedScrollPosition = 0;
    document.body.classList.remove('-prevent-scroll');
    document.documentElement.style.removeProperty('--scroll-position');
    document.scrollingElement.scrollTop = position;
  }
}
