import { TranslatableField } from './TranslatableField';

export enum ReviewingPeriodStatus {
  Open = 'Open',
  Future = 'Future',
  ClosesSoon = 'ClosingSoon',
  Closed = 'Closed',
}

export interface EventWithAssignedSubmissionsDto {
  id: string;
  name: TranslatableField;
  banner: TranslatableField;
  reviewStartDate: string;
  reviewEndDate: string;
  reviewingPeriodStatus: ReviewingPeriodStatus;
  reviewCount: number;
  completedReviewCount: number;
}
