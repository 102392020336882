
import { Component, Vue, Prop } from 'nuxt-property-decorator';
import { FilterBar, MobileFilterBar, Collapsible } from '../layout';
import { FilterToggle, FilterClear, FilterSearch, FilterMultiSelect } from '../filters';
import { InputSelectOption } from '../../types';
import { ScheduleDto, LiveStreamType, RoomDto } from '../../services';

@Component({
  components: {
    FilterToggle,
    FilterBar,
    FilterClear,
    FilterSearch,
    FilterMultiSelect,
    MobileFilterBar,
    Collapsible,
  },
})
export default class SessionFilterBar extends Vue {
  @Prop({ required: true }) readonly isParticipant!: boolean;
  @Prop({ required: true }) readonly isOrganizer!: boolean;
  @Prop({ required: true }) readonly liveSessions!: ScheduleDto[];
  @Prop({ required: true }) readonly trackOptions!: InputSelectOption[];
  @Prop({ required: true }) readonly sessions!: ScheduleDto[];
  @Prop({ required: true }) readonly rooms!: RoomDto[];
  @Prop(Boolean) readonly areFiltersCollapsed!: boolean;

  get canBookmark() {
    return this.$auth.loggedIn;
  }

  get hasAppliedFilters() {
    const { page, date, ...query } = this.$route.query;
    return Object.keys(query).length > 0;
  }

  get hasVirtualSessions() {
    const virtualRooms = this.rooms.filter(room => room.liveStreamType !== LiveStreamType.None);
    return this.sessions.some(session => virtualRooms.some(room => room.id === session.roomId));
  }
}
