
import { Component, Vue, Prop, namespace } from 'nuxt-property-decorator';
import { TrackDto, ScheduleTrackDto } from '../../services';
import TrackBadge from '../blocks/TrackBadge.vue';

const EventsModule = namespace('events');

@Component({ components: { TrackBadge } })
export default class SessionTrackList extends Vue {
  @EventsModule.State tracks!: TrackDto[];
  @Prop({ required: true }) sessionTracks!: ScheduleTrackDto[];

  get filteredTracks(): TrackDto[] {
    return this.tracks.filter(track => this.sessionTracks.some(({ trackId }) => trackId === track.id));
  }
}
