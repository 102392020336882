
import { Vue, Component, Model, Prop } from 'nuxt-property-decorator';
import InputText from './InputText.vue';

@Component({
  components: {
    InputText,
  },
})
export default class InputTextPassword extends Vue {
  @Prop({ type: String, default: '' }) readonly name!: string;
  @Prop({ type: String, default: '' }) readonly label!: string;
  @Prop({ type: String, default: '' }) readonly help!: string;
  @Prop(Boolean) readonly disabled!: boolean;
  @Prop(Boolean) readonly hideRequiredMark!: boolean;
  @Model('input', { type: [String, Number], default: null }) readonly value!: string | number | null;

  password = '';
  showPassword = false;

  get inputLabel() {
    return this.label || this.$t('shared.inputs.password.label');
  }

  get inputName() {
    return this.name || 'Password';
  }

  get iconName() {
    return this.showPassword ? 'eye-off' : 'eye';
  }
}
