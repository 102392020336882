
import { Component, Vue, Inject, Prop, Watch } from 'nuxt-property-decorator';
import { loadStripe } from '@stripe/stripe-js/pure';
import {
  StripeCardElementChangeEvent,
  Stripe,
  StripeCardElement,
  CreatePaymentMethodData,
  StripeElements,
  StripeElementLocale,
} from '@stripe/stripe-js';
import { BillingInfoDto } from '../../services';
import InputText from '../design-system/input/input-text/InputText.vue';

@Component({
  components: { InputText },
})
export default class StripeCreditCard extends Vue {
  @Inject() $validator;
  @Prop({ required: true }) readonly billingInfo!: BillingInfoDto;
  @Prop({ type: String, required: true }) readonly stripePublishableKey!: string;
  @Prop({ required: true }) readonly nameOnCard!: string | null;

  stripe: Stripe | null = null;
  stripeElements: StripeElements | null = null;
  stripeCardElement: StripeCardElement | null = null;
  localNameOnCard = '';

  get stripeLocale(): StripeElementLocale {
    return this.$i18n.locale === 'fr' ? 'fr' : 'en';
  }

  @Watch('stripeLocale')
  onStripeLocaleChanged() {
    if (this.stripeElements) this.stripeElements.update({ locale: this.stripeLocale });
  }

  @Watch('localNameOnCard')
  onLocalNameOnCardUpdated(nameOnCard: string) {
    this.$emit('nameOnCardUpdate', nameOnCard);
  }

  async mounted() {
    this.localNameOnCard = this.nameOnCard || '';

    this.stripe = await loadStripe(this.stripePublishableKey);
    if (this.stripe) {
      this.stripeElements = this.stripe.elements({ locale: this.stripeLocale });

      this.stripeCardElement = this.stripeElements.create('card', {
        style: {
          base: {
            fontFamily: 'Helvetica, Arial, Tahoma, Verdana, Sans-Serif',
            '::placeholder': {
              color: '#858a8c',
            },
          },
        },
        hidePostalCode: true,
      });
      this.stripeCardElement.on('change', this.onStripeCardChanged);
      this.stripeCardElement.mount(this.$refs.card as HTMLElement);
      this.$nuxt.$on('initStripeAuthentication', this.onInitStripeAuthentication);
    }
  }

  beforeDestroy() {
    this.$nuxt.$off('initStripeAuthentication');
  }

  public updateErrorMessage(message: string | null | undefined) {
    if (!message) message = '';
    const displayError = document.getElementById('card-errors');
    if (displayError) displayError.textContent = message;
  }

  public onStripeCardChanged(event: StripeCardElementChangeEvent) {
    this.updateErrorMessage(event.error ? event.error.message : '');
  }

  public async initiatePayment(): Promise<string | null> {
    if (!this.stripe || !this.stripeCardElement) return null;
    if (!this.billingInfo || !this.billingInfo.civicAddress) return null;

    const stripeData: CreatePaymentMethodData = {
      type: 'card',
      card: this.stripeCardElement,
      billing_details: {
        email: this.billingInfo.email,
        name: this.localNameOnCard!,
        address: {
          city: this.billingInfo.civicAddress.city,
          country: this.billingInfo.civicAddress.countryRegion,
          line1: this.billingInfo.civicAddress.addressLine1,
          postal_code: this.billingInfo.civicAddress.postalCode,
          state: this.billingInfo.civicAddress.stateProvince,
        },
      },
    };

    const result = await this.stripe.createPaymentMethod(stripeData);
    if (result.error) {
      this.updateErrorMessage(result.error.message);
      return null;
    }

    this.updateErrorMessage(null);
    return result.paymentMethod.id;
  }

  public async onInitStripeAuthentication(clientSecret: string) {
    if (!this.stripe) return;
    const response = await this.stripe.confirmCardPayment(clientSecret);
    if (response.paymentIntent) {
      this.$emit('onGatewayAuth', response.paymentIntent.id);
    } else {
      this.$toast.error(this.$t('shared.error.server_validation.payment_auth_error') as string);
    }
  }
}
