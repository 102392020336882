export default {
  join_conversation: 'Joindre la conversation',
  enter_conversation: 'Entrer dans la salle de conversation',
  listen_conversation: 'Écouter la conversation vidéo',
  call_presenter: 'Appeler le présentateur',
  title: 'Conversation vidéo',
  presenter: 'Présentateur',
  screen_sharing: `Partage d'écran`,
  toggle_fullscreen: 'Mode plein écran',
  turn_cam_on: 'Activer la caméra',
  turn_cam_off: 'Désactiver la caméra',
  start_screenshare: 'Présenter',
  stop_screenshare: 'Arrêter',
  mute_mic: 'Désactiver le micro',
  unmute_mic: 'Activer le micro',
  open_settings: 'Paramètres',
  chat: 'Chat',
  close_call: `Quitter l'appel`,
  restore: `Restaurer la fenêtre d'appel`,
  video: 'Vidéo',
  video_call: 'Conversation',
  browser_limitation: `Le navigateur que vous utilisez ({browser}) n'est pas compatible.`,
  browser_doc:
    'https://help.fourwaves.com/fr/quels-navigateurs-et-appareils-sont-pris-en-charge-pour-les-%C3%A9v%C3%A9nements-virtuels',
  zoom_browser_limitation: `Les navigateurs mobiles ne sont pas supportés par l'extension Web Zoom et peut résulter en une expérience dégrader.`,
  access_denied_doc:
    'https://help.fourwaves.com/fr/un-message-indique-que-je-dois-autoriser-lacc%C3%A8s-%C3%A0-la-cam%C3%A9ra-et-au-microphone.-que-devrais-je-faire',
  join_video: 'Une place est disponible. Rejoindre la conversation vidéo.',
  micro: 'Microphone',
  camera: 'Caméra',
  leave: 'Quitter',
  listening: 'En mode écoute',
  join_listen_mode: 'Rejoindre en mode écoute',
  register_to_contact: 'Seuls les participants inscrits peuvent entrer en conversation',
  minimize_call: 'Minimiser',
  room_opening_today_at: 'La salle virtuelle ouvre à {time} {timezone}',
  room_opening_on: 'La salle virtuelle ouvre le {date}',
  sign_in_to_contact: 'Se connecter pour contacter',
  hide_conversation: 'Cacher la conversation',
  stream_preview: {
    title: 'Vous allez rejoindre un appel vidéo!',
    reminders: {
      intro: 'Voici quelques bonnes pratiques:',
      wear_headphones: 'Portez des écouteurs.',
      mute_while_not_talking: 'Fermez votre micro si vous ne parlez pas.',
      browser_access_mic_and_cam: 'Votre caméra ou micro ne fonctionne pas?',
      browser_access_mic_and_cam_link:
        'https://help.fourwaves.com/fr/un-message-indique-que-je-dois-autoriser-lacc%C3%A8s-%C3%A0-la-cam%C3%A9ra-et-au-microphone.-que-devrais-je-faire',
      browser_screen_share_permission: 'Pour partager votre écran sur Mac, assurez-vous de donner accès à votre OS.',
      browser_screen_share_permission_link:
        'https://help.fourwaves.com/fr/comment-autoriser-votre-navigateur-%C3%A0-partager-l%C3%A9cran',
      extro: `La salle est vide lorsque vous entrez? Donnez un peu de temps au présentateur pour joindre à l'appel!`,
      learn_how: 'En savoir plus',
    },
    let_me_in: 'Je suis prêt, laissez-moi entrer!',
  },
  errors: {
    session_error: `Une erreur inattendue s'est produite en tentant de rejoindre la salle virtuelle.`,
    access_denied: `Veuillez autoriser l'accès à la caméra et au microphone et réessayer.`,
    not_connected: `La publication de votre vidéo a échoué. Vous n'êtes pas connecté à Internet.`,
    connection_failed: 'La publication de votre vidéo a échoué. Cela peut être dû à un pare-feu restrictif.',
    publishing_error: 'La publication de votre vidéo a échoué. Veuillez réessayer.',
    media_stopped: `Vous avez perdu l'accès à la caméra ou au microphone.`,
    screen_sharing_unsupported: `Votre navigateur ne prend pas en charge le partage d'écran.`,
    device_in_use: 'Votre caméra ou microphone est déjà utilisé par une autre application.',
  },
  settings: {
    title: 'Paramètres',
    microphone: 'Microphone',
    camera: 'Caméra',
    no_audio_device: `Aucun appareil détecté. Assurez-vous que votre microphone ou casque d'écoute est correctement connecté à votre appareil.`,
    no_video_device:
      'Aucun appareil détecté. Assurez-vous que votre caméra est correctement connectée à votre appareil.',
    blur_background: 'Flouer mon arrière-plan',
    blur_background_limitation: "Cette fonctionnalité n'est prise en charge que par Chrome version 94 et supérieure.",
  },
};
