
import { Vue, Component, Prop, Watch, Ref } from 'nuxt-property-decorator';
import SimpleBar from 'simplebar';
import ActionSpinner from '../action/ActionSpinner.vue';
import Hint from '../Hint.vue';
import { Size } from '../../types';
import ProgressSteps from '../design-system/molecules/ProgressSteps.vue';
import { rem } from '../../helpers';

@Component({
  components: {
    ActionSpinner,
    Hint,
    ProgressSteps,
  },
})
export default class FullPageModal extends Vue {
  @Prop({ type: String, required: true }) readonly title!: string;
  @Prop({ type: String, default: '' }) readonly subtitle!: string;
  @Prop({ type: String, default: '' }) readonly subtitleCaption!: string;
  @Prop({ type: String, default: 'sm' }) readonly size!: string;
  @Prop({ type: Array, default: Array }) readonly steps!: string[];
  @Prop({ type: Number, default: 0 }) readonly stepIndex!: number;
  @Prop({ type: String, default: null }) readonly awaitMessage!: string;
  @Prop(Boolean) readonly secondLastSubmit!: boolean;
  @Prop(Boolean) readonly disabled!: boolean;
  @Prop(Boolean) readonly nextDisabled!: boolean;
  @Prop(Boolean) readonly hideFooter!: boolean;
  @Prop(Boolean) readonly noGrow!: boolean;
  @Prop(Boolean) readonly isWaiting!: boolean;
  @Prop({ type: String, default: '' }) readonly disabledMessage!: string;
  @Prop({ type: String, default: null }) readonly actionButtonLabel!: string | null;

  @Ref() readonly bodyScroll?: HTMLElement;
  @Ref() readonly stepsScroll?: HTMLElement;
  @Ref() readonly hiddenHandle?: HTMLButtonElement;

  Sizes = Size;
  showModal = false;
  simplebarInstance: SimpleBar | null = null;
  callBackFocusElement: HTMLButtonElement | null = null;

  get sizeMap() {
    return {
      sm: {
        '--max-width': rem(812),
      },
      md: {
        '--max-width': rem(812),
      },
      lg: {
        '--max-width': rem(1013),
      },
    };
  }

  get stepCount() {
    return this.steps.length;
  }

  get actionLabel() {
    return this.actionButtonLabel || this.$t('shared.modal.save');
  }

  get isStepModal() {
    return !!this.stepCount;
  }

  get isSubmitStep() {
    if (!this.isStepModal) return true;
    if (this.secondLastSubmit && this.stepIndex + 1 === this.stepCount - 1) return true;
    return this.stepIndex + 1 === this.stepCount;
  }

  get showCancelButton() {
    if (!this.isStepModal) return true;
    return this.stepIndex === 0;
  }

  get rootClassNames() {
    const baseModifiers = {
      '-active': this.showModal,
      '-steps': this.isStepModal,
    };
    return [baseModifiers, `-${this.Sizes[this.size]}`];
  }

  get showProgressSteps() {
    return !!this.steps && this.steps.length > 0;
  }

  @Watch('showModal')
  async onShowModalChange() {
    await this.$nextTick();
    if (this.showModal) {
      this.$emit('open');
      if (this.bodyScroll) {
        this.simplebarInstance = new SimpleBar(this.bodyScroll);
        this.bodyScroll?.querySelector('[tabindex="0"]')?.removeAttribute('tabindex');
      }
      this.hiddenHandle?.focus();
    } else {
      this.$emit('close');
      this.simplebarInstance?.unMount();
      this.callBackFocusElement?.focus();
      this.callBackFocusElement = null;
    }
  }

  @Watch('stepIndex')
  onStepIndexChange() {
    this.$nextTick(() => {
      if (!this.stepsScroll) return;
      const activeStepElement = this.stepsScroll.querySelector('.js-active-step');
      if (!activeStepElement) return;
      if (this.isElementFullyVisible(activeStepElement, this.stepsScroll.clientWidth - 16)) return;
      activeStepElement.scrollIntoView({ behavior: 'smooth', inline: 'start' });
    });
  }

  public isElementFullyVisible(element: Element, containerWidth: number): boolean {
    const rects = element.getClientRects() || [];
    if (!rects.length) return false;
    const width = rects[0].width;
    const aPosition = rects[0].x;
    const bposition = aPosition + width + 16;

    const isLeftOffScreen = aPosition < 16;
    const isRightOffScreen = bposition > containerWidth;
    const isElementVisible = !isLeftOffScreen && !isRightOffScreen;
    return isElementVisible;
  }

  public open(targetElement?: HTMLButtonElement | EventTarget | null) {
    if (targetElement instanceof HTMLButtonElement) this.callBackFocusElement = targetElement;
    this.showModal = true;
  }

  public close() {
    this.showModal = false;
  }

  protected save(): void {
    this.$emit('save');
  }
}
