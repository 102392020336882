
import { Component, Prop, Ref, Watch, Vue } from 'nuxt-property-decorator';
import UserActionButton from '../blocks/UserActionButton.vue';
import { FormDto, FormEntryStatus } from '../../services';
import { Color, LayoutModes } from '../../types';
import { UserProfilePicture } from '../users';
import { PresentationElement } from './types';
import Badge from '../Badge.vue';

@Component({
  components: {
    UserProfilePicture,
    UserActionButton,
    Badge,
  },
})
export default class PresentationCard extends Vue {
  @Prop({ required: true }) readonly presentation!: PresentationElement;
  @Prop({ required: true }) readonly linkName!: string;
  @Prop({ type: String, default: null }) readonly layoutMode!: LayoutModes | null;
  @Prop({ type: Object, default: null }) readonly form!: FormDto | null;
  @Prop(Boolean) readonly isParticipant!: boolean;
  @Prop(Boolean) readonly isOrganizer!: boolean;

  @Ref() contentRef?: HTMLElement;

  readonly LayoutModes = LayoutModes;
  readonly FormEntryStatus = FormEntryStatus;
  readonly Color = Color;

  excerpt = '';
  rteContent = '';

  get hasUserActions() {
    return this.isParticipant || this.isOrganizer;
  }

  get bookmarkAriaLabel() {
    return this.presentation.isBookmarkedByCurrentUser
      ? `${this.$t('shared.global.aria_unbookmark')} ${this.presentation.title}`
      : `${this.$t('shared.global.aria_bookmark')} ${this.presentation.title}`;
  }

  get upvoteAriaLabel() {
    return this.presentation.isUpvotedByCurrentUser
      ? `${this.$t('shared.presentations.unvote')} ${this.presentation.title}`
      : `${this.$tc('shared.presentations.upvote', 1)} ${this.presentation.title}`;
  }

  get commentLink() {
    return this.localePath({
      name: this.linkName,
      params: { id: this.presentation.id },
      query: { scrollToComments: true },
    });
  }

  get isListLayoutMode() {
    return this.layoutMode === LayoutModes.list && this.$device.laptop;
  }

  get contentMaxHeight() {
    return this.isListLayoutMode ? 120 : 440;
  }

  get presenterInfo() {
    return {
      fullName: `${this.presentation.presenterFirstName} ${this.presentation.presenterLastName}`,
      affiliation: this.presentation.affiliation,
    };
  }

  get strippedAbstract() {
    return this.stripHtml(this.rteContent);
  }

  @Watch('contentMaxHeight')
  async onContentMaxHeightChange() {
    await this.$nextTick();
    this.generateExcerpt();
  }

  created() {
    if (!this.presentation || !this.form) return;
    this.rteContent = this.getRteContent(this.getAbstract(this.presentation, this.form));
  }

  async mounted() {
    await this.$nextTick();
    this.generateExcerpt();
  }

  public getPresentationImage({ thumbnail }: PresentationElement) {
    return thumbnail && `url(${thumbnail.replace(/\?.+$/, '')}?auto=format&fit=max&w=600&h=600)`;
  }

  public generateExcerpt() {
    if (!this.form || !this.contentRef) return;
    this.excerpt = '';
    const wordList = this.strippedAbstract.split(' ');

    const addWordToExcerpt = async () => {
      const word = wordList.shift();
      if (!word) return;

      const newExcerpt = !this.excerpt ? word : `${this.excerpt} ${word}`;
      this.excerpt = newExcerpt;
      await this.$nextTick();
      if (this.contentRef && this.contentRef.clientHeight < this.contentMaxHeight) return addWordToExcerpt();

      this.excerpt = `${this.excerpt.slice(0, (word.length + 1) * -1)}`;
      if (wordList.length && this.excerpt.length) this.excerpt += '...';
      await this.$nextTick();

      if (this.contentRef && this.contentRef.clientHeight > this.contentMaxHeight) {
        this.excerpt = `${this.excerpt.split(' ').slice(0, -1).join(' ')}...`;
      }

      this.excerpt = this.excerpt.replace(/,?\.{3,}$/, '...');
    };

    addWordToExcerpt();
  }
}
