import { CivicAddressDto } from './BillingInfoDto';

export enum MarketSegment {
  UniversityGroup = 'UniversityGroup',
  FundedResearchNetwork = 'FundedResearchNetwork',
  InformalResearchNetwork = 'InformalResearchNetwork',
  Association = 'Association',
  ContinuingEducation = 'ContinuingEducation',
  Private = 'Private',
  Other = 'Other',
}

export interface CreateOrganizationModel {
  name: string;
  marketSegment: MarketSegment | string;
  civicAddress: CivicAddressDto;

  emailAddresses?: string[];
  eventIds?: string[];
}
