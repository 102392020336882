
import { Component, Vue, Prop } from 'nuxt-property-decorator';
import { VueperSlides, VueperSlide } from 'vueperslides';

@Component({
  components: {
    VueperSlides,
    VueperSlide,
  },
})
export default class Lightbox extends Vue {
  @Prop({ type: Array, default: Array }) readonly images!: { title?: string; description?: string; url: string }[];

  bg = false;
  currentImage = 0;
  count = true;
  caption = true;
}
