
import { mixins, Component, Prop } from 'nuxt-property-decorator';
import { debounce } from 'lodash-es';
import InputTextSearch from '../design-system/input/input-text/InputTextSearch.vue';
import FilterMixin from './FilterMixin';

@Component({
  components: { InputTextSearch },
})
export default class FilterSearch extends mixins(FilterMixin) {
  @Prop({ default: 500 }) readonly debounceTime!: number;
  @Prop({ default: '' }) readonly placeholder!: string;
  @Prop(Boolean) readonly replace!: boolean;
  @Prop({ type: Number, default: null }) readonly resultsCount!: number;

  debouncedUpdateQuery: (searchTerm: string) => void = () => {};

  created() {
    this.debouncedUpdateQuery = debounce((searchTerm: string) => {
      if (searchTerm === this.$route.query[this.queryKey]) return;
      this.updateQuery(searchTerm, !searchTerm.length, this.replace);
    }, this.debounceTime);
  }

  get searchTerm() {
    return (this.$route.query[this.queryKey] as string) || '';
  }

  set searchTerm(searchTerm: string) {
    this.debouncedUpdateQuery(searchTerm.trim());
  }
}
