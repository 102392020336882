export interface FormEntryIdWithType {
  formEntryId: string;
  formType: number;
}

export enum Role {
  Organizer = 'ORGANIZER',
  Chair = 'CHAIR',
  Reviewer = 'REVIEWER',
  Participant = 'PARTICIPANT',
  Submitter = 'SUBMITTER',
  Author = 'AUTHOR',
  Administrator = 'ADMINISTRATOR',
}

export interface EventUserRoles {
  id: string;
  roles: Array<Role.Author | Role.Chair | Role.Organizer | Role.Participant | Role.Reviewer | Role.Submitter>;
}

export interface OrganizationUserRoles {
  id: string;
  roles: Array<Role.Administrator>;
}

export interface UserRolesDto {
  events: EventUserRoles[];
  organizations: OrganizationUserRoles[];
}

export type EventOrganizationMapping = Record<string, string | null>;

export interface SettingsDto {
  id: string;
  firstName: string | null;
  lastName: string | null;
  email: string;
  isEmailConfirmed: boolean;

  affiliation: string | null;
  bio: string | null;
  websiteUrl: string | null;
  twitterUrl: string | null;
  linkedInUrl: string | null;
  profilePictureUrl: string | null;

  eventWebsiteUrl: string;

  userRoles: UserRolesDto;
  eventOrganizationMapping: EventOrganizationMapping;
  permittedFormEntryIds: string[];
}
