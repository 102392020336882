export interface ConferencingState {
  isConversationOpen: boolean;
  isConversationCollapsed: boolean;
  isSettingsModalOpen: boolean;
  isPreviewApproved: boolean;
  isSessionSignalReady: boolean;
}

export const getDefaultConferencingState = (): ConferencingState => ({
  isConversationOpen: false,
  isConversationCollapsed: false,
  isSettingsModalOpen: false,
  isPreviewApproved: false,
  isSessionSignalReady: false,
});
