import * as signalR from '@microsoft/signalr';

export async function initiateWebSocket(
  apiUrl: string,
  accessToken: string,
  eventId: string,
): Promise<signalR.HubConnection> {
  const connection = new signalR.HubConnectionBuilder()
    .withUrl(`${apiUrl}/hubs/realtime?eventId=${eventId}`, {
      accessTokenFactory: () => accessToken.replace('Bearer ', ''),
    })
    .withAutomaticReconnect()
    .configureLogging(signalR.LogLevel.Warning)
    .build();

  async function startListening() {
    try {
      await connection.start();
    } catch (err) {
      console.error(err);
      setTimeout(() => startListening(), 5000);
    }
  }

  await startListening();
  return connection;
}
