
import { Component, Vue, Prop, Ref, Emit } from 'nuxt-property-decorator';
import UserProfilePicture from '../users/UserProfilePicture.vue';
import Badge from '../Badge.vue';
import { isMemberOfAnyOrganization } from '../../services/fourwaves/helpers';
import ContextualMenu from './ContextualMenu.vue';

@Component({
  components: {
    UserProfilePicture,
    ContextualMenu,
    Badge,
  },
})
export default class UserDropdown extends Vue {
  @Prop(Boolean) readonly externalUse!: boolean;

  @Ref() contextualMenu?: ContextualMenu;

  @Emit() openSplashScreen() {}

  userDropdownActive = false;

  get hasOrganizations() {
    return this.$auth.user ? isMemberOfAnyOrganization(this.$auth.user) : false;
  }

  get menuItems() {
    const menuItems = {
      dashboard: {
        label: this.$t('shared.global.navigation.dashboard'),
        externalUrl: `${this.$config.API_URL}/event-redirect/user-dashboard`,
        internalUrl: { name: 'index' },
        icon: 'grid',
        dataTestId: 'user-dashboard',
        hasBadge: false,
      },
      profile: {
        label: this.$t('shared.global.navigation.profile'),
        externalUrl: `${this.$config.API_URL}/event-redirect/account`,
        internalUrl: { name: 'account-profile' },
        icon: 'user',
        dataTestId: 'user-profile',
        hasBadge: false,
      },
    };

    const organizations = {
      label: this.$t('shared.global.navigation.organizations'),
      externalUrl: `${this.$config.API_URL}/event-redirect/organizations`,
      internalUrl: { name: 'organizations' },
      icon: 'organization',
      dataTestId: 'user-organizations',
      hasBadge: true,
    };

    return this.hasOrganizations ? { ...menuItems, organizations } : menuItems;
  }

  get fullName() {
    return this.$auth.user?.firstName && this.$auth.user?.lastName
      ? `${this.$auth.user.firstName} ${this.$auth.user.lastName}`
      : null;
  }

  get email() {
    return this.$auth.user.email;
  }

  public closeTray() {
    this.contextualMenu?.closeTray();
  }

  public async logout() {
    await this.$auth.logout();
    this.closeTray();
    window.location.reload();
  }
}
