
import { mixins, Prop, Component } from 'nuxt-property-decorator';
import { LayoutModes } from '../../types';
import FilterMixin from './FilterMixin';

@Component
export default class FilterLayoutSwitch extends mixins(FilterMixin) {
  @Prop({ type: String, default: LayoutModes.grid }) readonly defaultValue!: string;

  readonly LayoutModes = LayoutModes;

  get layoutMode(): LayoutModes {
    const queryLayout = this.$route.query[this.queryKey] as LayoutModes | undefined;
    return queryLayout || LayoutModes.grid;
  }
}
