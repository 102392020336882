
import { Component, Prop, Vue, Ref, Watch } from 'nuxt-property-decorator';
import { v4 as uuid } from 'uuid';
import SimpleBar from 'simplebar';
import ActionSpinner from '../action/ActionSpinner.vue';
import FeaturedIcon from '../design-system/molecules/FeaturedIcon.vue';

@Component({ components: { FeaturedIcon, ActionSpinner } })
export default class Modal extends Vue {
  @Prop({ type: String, default: null }) readonly actionLabel!: string;
  @Prop({ type: String, default: null }) readonly cancelLabel!: string;
  @Prop({ type: String, default: null }) readonly title!: string | null;
  @Prop({ type: String, default: null }) readonly subtitle!: string | null;
  @Prop({ type: String, default: null }) readonly icon!: string | null;
  @Prop({ type: [Boolean, String], default: false }) readonly form!: boolean | string;
  @Prop({ type: String, default: null }) readonly awaitMessage!: string;
  @Prop(Boolean) readonly destructive!: boolean;
  @Prop(Boolean) readonly disableClickOutside!: boolean;
  @Prop(Boolean) readonly scrollable!: boolean;
  @Prop(Boolean) readonly actionLess!: boolean;
  @Prop(Boolean) readonly isWaiting!: boolean;
  @Ref() readonly hiddenHandle?: HTMLButtonElement;
  @Ref() readonly modalScroll?: HTMLElement;

  showModal = false;
  formId: string | null = null;
  callBackFocusElement: HTMLButtonElement | null = null;
  simplebarInstance: SimpleBar | null = null;
  hasThirdAction = false;

  get mainActionLabel() {
    return this.actionLabel || this.$t('shared.modal.save');
  }

  get secondaryActionLabel() {
    return this.cancelLabel || this.$t('shared.modal.cancel');
  }

  get isMainButtonSubmit() {
    return !!this.form;
  }

  @Watch('showModal')
  async onShowModalChange() {
    await this.$nextTick();
    if (this.showModal) {
      if (this.modalScroll && this.scrollable) {
        this.simplebarInstance = new SimpleBar(this.modalScroll);
        this.modalScroll?.querySelector('[tabindex="0"]')?.removeAttribute('tabindex');
      }
      this.hiddenHandle?.focus();
      this.$emit('open');
    } else {
      this.$emit('close');
      this.simplebarInstance?.unMount();
      this.callBackFocusElement?.focus();
      this.callBackFocusElement = null;
    }
  }

  created() {
    this.detectSlotContent();
    if (!this.form) return;
    this.formId = typeof this.form === 'string' ? this.form : uuid();
  }

  beforeUpdate() {
    this.detectSlotContent();
  }

  public open(targetElement?: HTMLButtonElement | EventTarget | null) {
    if (targetElement instanceof HTMLButtonElement) this.callBackFocusElement = targetElement;
    this.showModal = true;
  }

  public close() {
    this.showModal = false;
  }

  protected save(event: PointerEvent | SubmitEvent) {
    event.preventDefault();
    this.$emit('save');
  }

  protected detectSlotContent() {
    this.hasThirdAction = !!this.$slots.thirdAction?.length;
  }

  protected onClickOutside() {
    if (this.disableClickOutside || this.isWaiting) return;
    this.close();
  }

  /**
   * @deprecated Use `open` or `close` function instead of this method.
   */
  public triggerVisibility(targetElement?: HTMLButtonElement | EventTarget | null) {
    if (targetElement instanceof HTMLButtonElement) this.callBackFocusElement = targetElement;
    this.showModal = !this.showModal;
  }
}
