import { Vue } from 'nuxt-property-decorator';
import type { DirectiveBinding } from 'vue';

type AppearOptions = Partial<DirectiveBinding<{ delay?: number; distance?: string }>>;

const classNames = {
  base: 'transition-appear',
  active: '-appear-active',
  from: '-appear-from',
  to: '-appear-to',
  directions: {
    up: '-up',
    right: '-right',
    down: '-down',
    left: '-left',
  },
};

Vue.directive('appear', {
  bind(el, { arg = 'up', value = {} }: AppearOptions) {
    const { delay, distance } = value;
    el.classList.add(classNames.base, classNames.from, classNames.directions[arg]);
    if (delay) el.style.setProperty('--transition-delay', `${delay}ms`);

    if (distance) {
      const translateBy = arg === 'right' || arg === 'down' ? `-${distance}` : distance;
      el.style.setProperty('--translate-by', translateBy);
    }
  },
  async inserted(el, { arg = 'up' }: AppearOptions) {
    el.addEventListener(
      'transitionend',
      () => {
        if (!el || !el.classList) return;
        el.classList.remove(classNames.base, classNames.active, classNames.to, classNames.directions[arg] || 'na');
        el.style.removeProperty('--transition-delay');
        el.style.removeProperty('--translate-by');
      },
      { once: true },
    );

    await Vue.nextTick();
    el.classList.add(classNames.active);
    await Vue.nextTick();
    setTimeout(() => el.classList.replace(classNames.from, classNames.to), 25);
  },
});
