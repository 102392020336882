
import { Component, Prop, Vue } from 'nuxt-property-decorator';
import { omit } from 'lodash-es';
import { normalizeQuery } from '../../helpers';

@Component
export default class FilterPagination extends Vue {
  @Prop({ type: Number, required: true }) readonly totalPages!: number;

  pageClass = 'filter-pagination__page';

  get isPreviousButtonDisabled() {
    return this.page === 1;
  }

  get isNextButtonDisabled() {
    return this.totalPages === 0 || this.page === this.totalPages;
  }

  get page() {
    return Number(this.$route.query.page) || 1;
  }

  set page(page: number) {
    if (this.page === page) return;

    const query =
      page === 1 ? normalizeQuery(omit(this.$route.query, ['page'])) : normalizeQuery({ ...this.$route.query, page });

    this.$router.push({ query });
    this.$emit('query-changed', page, 'page');
  }

  get pages() {
    if (this.totalPages <= 7) {
      return this.firstChunk;
    }

    if (this.page > 4 && this.page < this.totalPages - 3) {
      return [1, null, this.page - 1, this.page, this.page + 1, null, this.totalPages];
    }

    return this.page <= 4
      ? [...this.firstChunk.slice(0, 5), null, this.totalPages]
      : [1, null, ...this.lastChunk.slice(2, 7)];
  }

  get firstChunk(): number[] {
    return Array.from({ length: Math.min(this.totalPages, 7) }, (_, i) => i + 1);
  }

  get lastChunk(): number[] {
    return Array.from({ length: Math.min(this.totalPages, 7) }, (_, i) => this.totalPages - i).reverse();
  }
}
