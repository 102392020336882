
import { Editor } from '@tiptap/vue-2';
import { Component, Prop, Ref, Vue } from 'nuxt-property-decorator';
import { TiptapExtension, TiptapMenuButton } from '../../types';
import TiptapLinkButton from './TiptapLinkButton.vue';
import TiptapVideoButton from './TiptapVideoButton.vue';
import TiptapFormatDropdown from './TiptapFormatDropdown.vue';
import Tooltip from '../Tooltip.vue';

@Component({
  components: {
    TiptapFormatDropdown,
    TiptapLinkButton,
    TiptapVideoButton,
    Tooltip,
  },
})
export default class TiptapMenu extends Vue {
  @Prop({ required: true }) editor!: Editor;
  @Prop({ type: Array, default: () => [] }) readonly extensions!: TiptapExtension[];

  @Ref() readonly buttonElements?: HTMLElement[];

  readonly TiptapExtension = TiptapExtension;

  isOverflowMenuOpened = false;
  overflowedButtons: TiptapMenuButton[] = [];

  get buttonSections(): TiptapMenuButton[][] {
    return [
      [
        {
          id: 'bold',
          extension: TiptapExtension.Bold,
          label: this.$t('shared.tiptap.bold'),
          ariaLabel: this.$t('shared.tiptap.aria_bold'),
          icon: 'bold',
          action: 'toggleBold',
        },
        {
          id: 'italic',
          extension: TiptapExtension.Italic,
          label: this.$t('shared.tiptap.italic'),
          ariaLabel: this.$t('shared.tiptap.aria_italic'),
          icon: 'italic',
          action: 'toggleItalic',
        },
        {
          id: 'underline',
          extension: TiptapExtension.Underline,
          label: this.$t('shared.tiptap.underline'),
          ariaLabel: this.$t('shared.tiptap.aria_underline'),
          icon: 'underline',
          action: 'toggleUnderline',
        },
        {
          id: 'subscript',
          extension: TiptapExtension.Subscript,
          label: this.$t('shared.tiptap.subscript'),
          ariaLabel: this.$t('shared.tiptap.aria_subscript'),
          icon: 'subscript',
          action: 'toggleSubscript',
        },
        {
          id: 'superscript',
          extension: TiptapExtension.Superscript,
          label: this.$t('shared.tiptap.superscript'),
          ariaLabel: this.$t('shared.tiptap.aria_superscript'),
          icon: 'superscript',
          action: 'toggleSuperscript',
        },
        {
          id: 'link',
          extension: TiptapExtension.Link,
          label: this.$t('shared.tiptap.link'),
          ariaLabel: this.$t('shared.tiptap.aria_link'),
          icon: 'link',
          action: '',
        },
      ],
      [
        {
          id: 'align-left',
          extension: TiptapExtension.TextAlign,
          label: this.$t('shared.tiptap.alignLeft'),
          ariaLabel: this.$t('shared.tiptap.aria_alignLeft'),
          icon: 'align-left',
          action: 'setTextAlign',
          params: 'left',
        },
        {
          id: 'align-center',
          extension: TiptapExtension.TextAlign,
          label: this.$t('shared.tiptap.alignCenter'),
          ariaLabel: this.$t('shared.tiptap.aria_alignCenter'),
          icon: 'align-center',
          action: 'setTextAlign',
          params: 'center',
        },
      ],
      [
        {
          id: 'video',
          extension: TiptapExtension.Video,
          label: this.$t('shared.tiptap.video'),
          ariaLabel: this.$t('shared.tiptap.aria_video'),
          icon: 'play-square',
          action: '',
        },
      ],
      [
        {
          id: 'horizontal-line',
          extension: TiptapExtension.HorizontalLine,
          label: this.$t('shared.tiptap.horizontalLine'),
          ariaLabel: this.$t('shared.tiptap.aria_horizontalLine'),
          icon: 'horizontal-line',
          action: 'setHorizontalRule',
        },
        {
          id: 'bullet-list',
          extension: TiptapExtension.BulletList,
          label: this.$t('shared.tiptap.bulletList'),
          ariaLabel: this.$t('shared.tiptap.aria_bulletList'),
          icon: 'list',
          action: 'toggleBulletList',
        },
        {
          id: 'ordered-list',
          extension: TiptapExtension.OrderedList,
          label: this.$t('shared.tiptap.orderedList'),
          ariaLabel: this.$t('shared.tiptap.aria_orderedList'),
          icon: 'numbered-list',
          action: 'toggleOrderedList',
        },
      ],
    ];
  }

  get enabledButtonSections() {
    return this.buttonSections
      .map(buttons => buttons.filter(({ extension }) => this.isExtensionEnabled(extension)))
      .filter(buttons => buttons.length);
  }

  get isAnyButtonEnabled() {
    return this.enabledButtonSections.length > 0;
  }

  get isDisabled() {
    return !this.editor.isEditable;
  }

  mounted() {
    if (this.isAnyButtonEnabled) {
      window.addEventListener('resize', this.onResize);
      this.$nextTick(() => this.onResize());
      this.onResize();
    }
  }

  beforeDestroy() {
    if (this.isAnyButtonEnabled) window.removeEventListener('resize', this.onResize);
  }

  public isExtensionEnabled(extension: TiptapExtension) {
    return this.extensions?.includes(extension);
  }

  public isActive(extension: TiptapExtension, params?: string) {
    if (params) return this.editor.isActive({ [extension]: params });
    return this.editor.isActive(extension);
  }

  public isLastSection(sectionIndex: number) {
    return sectionIndex !== this.enabledButtonSections.length - 1;
  }

  public handleAction(action: string, params?: string) {
    if (params) return this.editor.chain().focus()[action](params).run();
    this.editor.chain().focus()[action]().run();
  }

  public onResize() {
    requestAnimationFrame(() => {
      if (!this.buttonElements) return;

      const firstButtonElement = this.buttonElements[0];
      if (!firstButtonElement) return;

      const itemDefaultTop = firstButtonElement.getBoundingClientRect().top;

      const overflowButtonIds = this.buttonElements
        .filter(el => el.getBoundingClientRect().top > itemDefaultTop)
        .map(el => el.dataset.id);

      this.overflowedButtons = this.enabledButtonSections
        .flat()
        .filter(button => overflowButtonIds.includes(button.id));
    });
  }
}
