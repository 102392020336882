export interface FileMixins {
  formatFileSize(size: number): string;
}

export const fileMixins: FileMixins = {
  formatFileSize(bytes?: number | string | null) {
    if (bytes == null) return '0 B';

    const formatFileSize = (rawBytes: number | string) => {
      const numBytes = Number(rawBytes);
      const maxUnit = this.$t('shared.global.gb');

      const parsers = [
        { unit: this.$t('shared.global.gb'), multiple: 1e9 },
        { unit: this.$t('shared.global.mb'), multiple: 1e6 },
        { unit: this.$t('shared.global.kb'), multiple: 1e3 },
      ];

      const { unit = 'B', multiple = 1 } = parsers.find(({ multiple }) => numBytes >= multiple) || {};
      const size = Number((numBytes / multiple).toFixed(1));
      return size >= 1000 && unit !== maxUnit ? formatFileSize(size * multiple) : `${size} ${unit}`;
    };

    return formatFileSize(bytes);
  },
};
