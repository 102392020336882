
import { mixins, Component, Prop, Ref } from 'nuxt-property-decorator';
import Icon from '../Icon.vue';
import ActionMixin from './ActionMixin';

@Component({
  components: {
    Icon,
  },
})
export default class ActionButton extends mixins(ActionMixin) {
  @Prop(Boolean) readonly submit!: boolean;
  @Ref() buttonElement?: HTMLButtonElement;

  get buttonClassNames() {
    return [this.elementClassNames, `-${this.hierarchy} -${this.Sizes[this.size]}`];
  }

  get buttonType() {
    return this.submit ? 'submit' : 'button';
  }

  public focus() {
    this.buttonElement?.focus();
  }
}
