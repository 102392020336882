export default {
  file_upload: {
    modal_title: 'Edit File',
    drop_file: 'Drop file or',
    browser: 'browse your computer to add',
    instructions: 'File size must be 100MB or less',
    instructions_slides: 'File size must be 10MB or less',
    instructions_video: 'File size must be 1GB or less',
    uploading: 'Uploading files...',
    caption_title: 'Title',
    description_placeholder: 'Please enter a brief description ...',
    missing_description: 'No description. Click Edit to update.',
  },
  hidden_field: 'Hidden field',
  unavailable: 'Unavailable',
  choice: {
    has_quantity: 'Limit quantity',
    cost: 'Cost',
  },
  authors: {
    add: 'Add',
    empty: 'No one was added to this submission yet',
    label: 'Authors',
  },
};
