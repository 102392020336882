
import { Vue, Component, Watch, Prop, Ref } from 'nuxt-property-decorator';
import SimpleBar from 'simplebar';
import { GlobalDropdownEvents } from '../../types';
import { TranslateResult } from '../../types/i18n';
import { LocationWithRawQuery } from '../../helpers/router';
import Collapsible from './Collapsible.vue';

interface NavigationSelectOption {
  route: LocationWithRawQuery;
  label: string | TranslateResult;
  active?: boolean;
}

@Component({
  components: {
    Collapsible,
  },
})
export default class NavigationSelect extends Vue {
  @Prop({ default: Array }) readonly options!: NavigationSelectOption[];
  @Prop(Boolean) readonly isMobile!: boolean;
  @Prop(Boolean) readonly replace!: boolean;

  @Ref() readonly optionsMenu!: HTMLMenuElement;

  isOpen = this.isMobile;
  simplebarInstance: SimpleBar | null = null;
  scrollElement: HTMLElement | null = null;
  selected = '';

  get selectedLabel() {
    const normalizedRouteName = this.$route.name?.split('___')[0];
    const selectedOption = this.options.find(({ route }) => route.name === normalizedRouteName);

    if (!selectedOption) return '';

    return selectedOption.label;
  }

  @Watch('$route')
  onRouteChanged() {
    this.isOpen = false;
  }

  @Watch('isOpen')
  onIsOpenChange(isOpen: boolean, wasOpen: boolean) {
    if (isOpen === wasOpen) return;

    if (isOpen) {
      document.body.addEventListener('click', this.closeMenu);
      this.$nuxt.$on(GlobalDropdownEvents.Close, this.closeMenu);
    } else {
      document.body.removeEventListener('click', this.closeMenu);
      this.$nuxt.$off(GlobalDropdownEvents.Close, this.closeMenu);
    }
  }

  async mounted() {
    await this.$nextTick();
    if (!this.optionsMenu) return;
    this.simplebarInstance = new SimpleBar(this.optionsMenu);
    await this.$nextTick();
    this.scrollElement = this.simplebarInstance.getScrollElement();
  }

  beforeDestroy() {
    document.body.removeEventListener('click', this.closeMenu);
    this.$nuxt.$off(GlobalDropdownEvents.Close, this.closeMenu);
  }

  public isOptionSelected(id: string | number) {
    return this.selected.includes(String(id));
  }

  public onClickLabel() {
    this.isOpen = !this.isOpen;
    this.$nuxt.$emit(GlobalDropdownEvents.Close);
  }

  public closeMenu() {
    this.isOpen = false;
  }

  public onMenuClose() {
    if (!this.scrollElement) return;
    this.scrollElement.scrollTop = 0;
  }
}
