
import { Component, Vue, Prop } from 'nuxt-property-decorator';
import { RawHtml } from './layout';

@Component({
  components: {
    RawHtml,
  },
})
export default class Tooltip extends Vue {
  @Prop(String) readonly content!: string;
  @Prop(String) readonly position!: string;
  @Prop({ type: Number, default: 8 }) readonly distance!: number;
  @Prop({ type: Number, default: 25 }) readonly showDelay!: number;
  @Prop({ type: Number, default: 125 }) readonly hideDelay!: number;
  @Prop(Boolean) readonly disabled!: boolean;

  isOpen = false;
}
