import { ConferencingState } from './state';

export const conferencingMutations = {
  setConferencingOpen(state: ConferencingState, isConversationOpen: boolean): void {
    state.isConversationOpen = isConversationOpen;
  },
  setConversationCollapsed(state: ConferencingState, isConversationCollapsed: boolean): void {
    state.isConversationCollapsed = isConversationCollapsed;
  },
  setSettingsModalOpen(state: ConferencingState, isSettingsModalOpen: boolean): void {
    state.isSettingsModalOpen = isSettingsModalOpen;
  },
  setPreviewApproved(state: ConferencingState, isPreviewApproved: boolean): void {
    state.isPreviewApproved = isPreviewApproved;
  },
  setSessionSignalReady(state: ConferencingState, isSessionSignalReady: boolean): void {
    state.isSessionSignalReady = isSessionSignalReady;
  },
};
