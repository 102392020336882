export interface AuthMixins {
  getLoginUrl(email?: string, redirect?: string): string;
}

export const authMixins: AuthMixins = {
  getLoginUrl(email = '', redirect = '') {
    if (!redirect) {
      redirect = process.client
        ? encodeURI(window.location.href)
        : encodeURI(`${this.$config.EVENT_WEBSITE_URL}${this.$route?.fullPath}`);
    }

    const querystring = new URLSearchParams();
    if (email) {
      querystring.append('email', encodeURI(email));
    }
    if (redirect.includes('/401')) {
      const fullRedirect = decodeURIComponent(redirect).split('redirect=')[1];
      const returnTo = decodeURIComponent(fullRedirect).split('returnTo=')[1];
      const redirectUrl = decodeURIComponent(returnTo);
      querystring.append('returnTo', decodeURIComponent(redirectUrl));
    } else {
      querystring.append('returnTo', redirect);
    }
    return `${this.$config.API_URL}/event-redirect/login?${querystring.toString()}`;
  },
};
