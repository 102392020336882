import { Component, Prop, Vue } from 'nuxt-property-decorator';
import { omit } from 'lodash-es';
import { QueryValue, normalizeQuery } from '../../helpers';

@Component
export default class FilterMixin extends Vue {
  @Prop({ required: true }) readonly queryKey!: string;

  public updateQuery(value: QueryValue, removeKey?: boolean, replace?: boolean) {
    const pageAdjustedQuery = omit(this.$route.query, ['page']);

    const query = removeKey
      ? normalizeQuery(omit(pageAdjustedQuery, [this.queryKey]))
      : normalizeQuery({ ...pageAdjustedQuery, [this.queryKey]: value });

    if (replace) this.$router.replace({ query });
    else this.$router.push({ query });
    this.$emit('query-changed', value, this.queryKey);
  }
}
