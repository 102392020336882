import { Vue } from 'nuxt-property-decorator';

enum TabTrapSelector {
  First = '[data-tabtrap="first"]',
  Last = '[data-tabtrap="last"]',
}

Vue.directive('tabtrap', {
  inserted(el) {
    el.addEventListener('keydown', event => {
      const { target, key, shiftKey } = event;
      if (key !== 'Tab') return;
      const [firstTrapNode] = Array.from<HTMLElement>(el.querySelectorAll(TabTrapSelector.First));
      const [lastTrapNode] = Array.from<HTMLElement>(el.querySelectorAll(TabTrapSelector.Last)).slice(-1);

      if (shiftKey && firstTrapNode && target === firstTrapNode) {
        event.preventDefault();
        lastTrapNode.focus();
      } else if (!shiftKey && lastTrapNode && target === lastTrapNode) {
        event.preventDefault();
        firstTrapNode.focus();
      }
    });
  },
});
