import { isObject } from './typeAssertions';

export function looseEqual(a: any, b: any) {
  return a === b || (isObject(a) && isObject(b) ? JSON.stringify(a) === JSON.stringify(b) : false);
}

export function closest(el: HTMLElement | null, selector: string) {
  let retval: HTMLElement | null = null;
  while (el) {
    if (el.classList.contains(selector)) {
      retval = el;
      break;
    }
    el = el.parentElement;
  }
  return retval;
}
