
import { Component, Vue, Prop, namespace } from 'nuxt-property-decorator';
import {
  FileUploadDto,
  FormDto,
  FormEntryDto,
  FormFieldDto,
  FormFieldType,
  getFormEntryValue,
  PageDto,
  PageType,
  TranslatableField,
  transl8,
} from '../../../services';
import { PresentationMedia } from '../../../types';

const EventsModule = namespace('events');

@Component
export default class PresentationDetailsOtherInformation extends Vue {
  @EventsModule.State pages!: PageDto[];
  @Prop({ required: true }) formEntry!: FormEntryDto;
  @Prop({ required: true }) form!: FormDto;
  @Prop({ required: true }) pageType!: PageType;
  @Prop({ required: true }) presentationMedia!: PresentationMedia;
  @Prop({ required: true }) posterIndexRouteName!: string;

  readonly FormFieldType = FormFieldType;

  fields: FormFieldDto[] = [];
  uploadedFiles: FileUploadDto[] = [];
  selectionFieldTypes = [FormFieldType.Checkboxes, FormFieldType.Dropdown, FormFieldType.Radios];

  get hasContent() {
    return this.fields.length > 0 || this.mediaFields.length > 0 || this.fileUploadFields.length > 0;
  }

  get selectionFields() {
    return this.fields.filter(({ fieldType }) => this.selectionFieldTypes.includes(fieldType as FormFieldType));
  }

  get mediaFields() {
    const { video, slides, figures } = this.presentationMedia;
    const fields: { label: TranslatableField; icon: string; route: string }[] = [];
    if (video) fields.push({ label: video.field.label, icon: 'video', route: 'video' });
    if (slides) fields.push({ label: slides.field.label, icon: 'slides', route: 'slides' });
    if (figures) fields.push({ label: figures.field.label, icon: 'figure', route: 'figures' });
    return fields;
  }

  get fileUploadFields() {
    return this.fields.filter(({ fieldType }) => fieldType === FormFieldType.File);
  }

  get otherFields() {
    return this.fields.filter(
      ({ fieldType }) =>
        !this.selectionFieldTypes.includes(fieldType as FormFieldType) && fieldType !== FormFieldType.File,
    );
  }

  async created() {
    const presentationPage = this.pages.find(({ type }) => type === this.pageType);
    const baseFields = presentationPage ? presentationPage.formFields : [];
    const formSectionFields = this.form.formSections.flatMap(fs => fs.formFields);

    const filters = [
      ({ fieldType }) => fieldType !== FormFieldType.Poster,
      ({ fieldType }) => fieldType !== FormFieldType.Video,
      ({ fieldType }) => fieldType !== FormFieldType.Slides,
      ({ fieldType }) => fieldType !== FormFieldType.Images,
      ({ id }) => this.formEntry.formEntryValues.some(({ formFieldId }) => formFieldId === id),
      ({ id }) => formSectionFields.some(field => field.id === id),
    ];

    this.fields = baseFields.filter(field => filters.every(filter => filter(field)));

    // Preload all files
    this.uploadedFiles = await Promise.all(
      this.fields
        .filter(({ fieldType }) => fieldType === FormFieldType.File)
        .map(async field => await this.$api.getFile(getFormEntryValue(this.formEntry, this.form, field.id))),
    );
  }

  public getTextValue(field: FormFieldDto): string {
    return getFormEntryValue(this.formEntry, this.form, field.id);
  }

  public getMultipleChoices(field: FormFieldDto): string {
    const formEntryValue = getFormEntryValue(this.formEntry, this.form, field.id);

    const { formFieldChoices } = this.form.formSections
      .flatMap(({ formFields }) => formFields)
      .find(({ id }) => id === field.id)!;

    return formEntryValue
      .split(',')
      .map(choiceId => formFieldChoices.find(({ id }) => id === choiceId))
      .map(choice => transl8(choice!.shortLabel))
      .join(', ');
  }

  public getFileUrls(field: FormFieldDto): FileUploadDto[] {
    return getFormEntryValue(this.formEntry, this.form, field.id)
      .split(',')
      .filter(fileId => this.uploadedFiles.find(({ id }) => id === fileId))
      .map(fileId => this.uploadedFiles.find(({ id }) => id === fileId)!);
  }

  public isSelectionField({ fieldType }: FormFieldDto) {
    return (
      fieldType === FormFieldType.Checkboxes ||
      fieldType === FormFieldType.Dropdown ||
      fieldType === FormFieldType.Radios
    );
  }
}
