
import { Component, Vue, Prop, Watch } from 'nuxt-property-decorator';

@Component({})
export default class QuitListenModeButton extends Vue {
  @Prop({ required: true, type: Boolean }) isConversationFull!: boolean;

  isTooltipShown = false;
  isTooltipDisabled = false;

  @Watch('isConversationFull', { immediate: true })
  onIsConversationFullChange(isFull: boolean) {
    // ignore state changes when tooltip has been dismissed
    if (this.isTooltipDisabled) {
      return;
    }

    this.isTooltipShown = !isFull;
  }

  public closeTooltip() {
    this.isTooltipShown = false;
    this.isTooltipDisabled = true;
  }
}
