import moment from 'moment-timezone';
import { Auth } from '@nuxtjs/auth-next';
import { hasPermission, isVPSOpen, Role, ScheduleDto, SettingsDto } from '../../services';
import { EventsBaseState } from './EventsBaseState';

export interface RootState {
  auth: Auth & { user: SettingsDto };
}

const rolesGetters = {
  isParticipant: (_: EventsBaseState, getters): boolean => {
    return getters.hasRole(Role.Participant);
  },
  isSubmitter: (_: EventsBaseState, getters): boolean => {
    return getters.hasRole(Role.Submitter);
  },
  isOrganizer: (_: EventsBaseState, getters): boolean => {
    return getters.hasRole(Role.Organizer);
  },
  isChair: (_: EventsBaseState, getters): boolean => {
    return getters.hasRole(Role.Chair);
  },
  isReviewer: (_: EventsBaseState, getters): boolean => {
    return getters.hasRole(Role.Reviewer) || getters.hasRole(Role.Chair);
  },
  isAllowedToVirtualCall: (_: EventsBaseState, getters): boolean => {
    return getters.hasRole(Role.Participant) || getters.hasRole(Role.Organizer);
  },
  isVpsOpen: (state: EventsBaseState): boolean => {
    return !state.currentEvent ? false : isVPSOpen(state.sessions, state.currentTime);
  },
  hasRole:
    (state: EventsBaseState, _: any, rootState: RootState) =>
    (role: Role): boolean => {
      return rootState.auth && rootState.auth.user && state.currentEvent
        ? hasPermission(rootState.auth.user as SettingsDto, state.currentEvent.id, role)
        : false;
    },
};

const sessionsGetters = {
  liveSessions: (state: EventsBaseState): ScheduleDto[] => {
    const now = moment(state.currentTime);
    return state.sessions.filter(({ startDate, endDate }) =>
      now.isBetween(moment(startDate), moment(endDate), undefined, '[]'),
    );
  },
  nextRelevantSessionDate: (state: EventsBaseState): string | null => {
    if (!state.sessions.length) return null;
    const today = moment(state.currentTime).startOf('day');
    const nextSession = state.sessions.find(({ startDate }) => moment(startDate).startOf('day').isSameOrAfter(today));
    return nextSession ? nextSession.startDate : state.sessions[0].startDate;
  },
  nextBookmarkedSessionDate: (state: EventsBaseState): string | null => {
    const bookmarkedSessions = state.sessions.filter(({ isBookmarkedByCurrentUser }) => isBookmarkedByCurrentUser);
    if (!bookmarkedSessions.length) return null;
    const today = moment(state.currentTime).startOf('day');
    const nextBookmarkedSession = bookmarkedSessions.find(({ startDate }) =>
      moment(startDate).startOf('day').isSameOrAfter(today),
    );
    return nextBookmarkedSession ? nextBookmarkedSession.startDate : bookmarkedSessions[0].startDate;
  },
};

const langGetters = {
  isEventMultiLanguage: (state: EventsBaseState): boolean => {
    return state.language === 'browser';
  },
};

export const getters = {
  ...rolesGetters,
  ...sessionsGetters,
  ...langGetters,
};
