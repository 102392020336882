import moment from 'moment-timezone';

export const SERVER_FORMAT = 'YYYY-MM-DDTHH:mm:ss';

export function isUtcNowInRange(
  startDateTime: string | null | undefined,
  endDateTime: string | null | undefined,
  inclusivity: '[]' | '()' | '[)' | '(]' | undefined = '[]',
  datetime = '',
): boolean {
  startDateTime = startDateTime || '0001-01-01';
  endDateTime = endDateTime || '2060-01-01';

  const now = datetime ? moment.utc(datetime) : moment.utc();
  return now.isBetween(
    moment.utc(startDateTime!),
    moment.utc(endDateTime!),
    undefined,
    // inclusivity is really important
    // https://momentjscom.readthedocs.io/en/latest/moment/05-query/06-is-between/
    inclusivity,
  );
}

/**
 * @deprecated Please use `moment.toISOString()` instead of this method. Migrate existing code carefully.
 */
export function toPlainString(providedMoment: moment.Moment): string {
  return providedMoment.format('YYYY-MM-DD[T]HH:mm') + 'Z';
}

/**
 * @deprecated Please use `this.$moment(date)` / `moment(date)` instead of this method. Migrate existing code carefully.
 */
export function parseDateTime(datetime: string): moment.Moment {
  return moment(new Date(datetime), SERVER_FORMAT);
}

export function isValidDate(date: unknown): date is Date {
  return date instanceof Date && isFinite(Number(date));
}
