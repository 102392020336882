export default {
  join_conversation: 'Join conversation',
  enter_conversation: 'Enter conversation room',
  listen_conversation: 'Listen to video conversation',
  call_presenter: 'Call presenter',
  title: 'Video conversation',
  presenter: 'Presenter',
  screen_sharing: 'Screen share',
  toggle_fullscreen: 'Toggle full screen mode',
  turn_cam_on: 'Turn cam on',
  turn_cam_off: 'Turn cam off',
  start_screenshare: 'Present',
  stop_screenshare: 'Stop',
  mute_mic: 'Mute mic',
  unmute_mic: 'Unmute mic',
  open_settings: 'Settings',
  chat: 'Chat',
  close_call: 'Leave call',
  restore: 'Restore call window',
  video: 'Video',
  video_call: 'Video call',
  hide_conversation: 'Hide conversation',
  browser_limitation: `The browser you're using ({browser}) is not compatible.`,
  browser_doc: 'https://help.fourwaves.com/en/what-browsers-and-devices-are-supported-for-virtual-events',
  zoom_browser_limitation:
    'Mobile browsers are not fully supported for zoom calls and might result in a poor experience.',
  access_denied_doc: 'https://help.fourwaves.com/en/i-need-to-allow-access-to-the-camera-and-microphone-to-my-browser',
  join_video: 'A place is now available. Join video conversation.',
  micro: 'Microphone',
  camera: 'Camera',
  leave: 'Leave',
  listening: 'Currently listening',
  join_listen_mode: 'Join in listen mode',
  register_to_contact: 'Only registered participants can enter conversations',
  minimize_call: 'Minimize',
  room_opening_today_at: 'The virtual room opens at {time} {timezone}',
  room_opening_on: 'The virtual room opens {date}',
  stream_preview: {
    title: `You're about to join a video call!`,
    reminders: {
      intro: 'Here are some best practices:',
      wear_headphones: 'Wear headphones.',
      mute_while_not_talking: 'Mute yourself when not talking.',
      browser_access_mic_and_cam: `Your camera or microphone don't work?`,
      browser_access_mic_and_cam_link:
        'https://help.fourwaves.com/en/i-need-to-allow-access-to-the-camera-and-microphone-to-my-browser',
      browser_screen_share_permission: 'To share your screen on Mac OS, give your OS permission.',
      browser_screen_share_permission_link:
        'https://help.fourwaves.com/how-to-give-your-browser-permission-to-share-screen-os',
      extro: 'The room is empty when you enter? Give the presenter some time to join you.',
      learn_how: 'Learn how',
    },
    let_me_in: `I'm ready, let me in!`,
  },
  errors: {
    session_error: 'An unexpected error has occurred while attempting to join the virtual conversation.',
    access_denied: 'Please allow access to the Camera and Microphone and try again.',
    not_connected: 'Publishing your video failed. You are not connected to the internet.',
    connection_failed: 'Publishing your video failed. This could be due to a restrictive firewall.',
    publishing_error: 'Publishing your video failed. Please try again.',
    media_stopped: 'You lost access to the Camera or Microphone.',
    screen_sharing_unsupported: 'Your browser does not support screen sharing.',
    device_in_use: 'Your camera or microphone is already in use by another application.',
  },
  settings: {
    title: 'Settings',
    microphone: 'Microphone',
    camera: 'Camera',
    no_audio_device:
      'No device detected. Make sure that your microphone or headset is connected correctly to your device.',
    no_video_device: 'No device detected. Make sure that your camera is connected correctly to your device.',
    blur_background: 'Blur my background',
    blur_background_limitation: 'This feature is only supported on Chrome version 94 and above.',
  },
};
