
import { Component, Vue, namespace, Ref, Watch } from 'nuxt-property-decorator';
import { buildNestedPages, EventDto, NestedPage, PageDto, PageType } from 'fourwaves-shared';
import { throttle } from 'lodash-es';
import MenuDropdown from './MenuDropdown.vue';
import SecondaryNavBar from './SecondaryNavBar.vue';
import MobileNav from '~/components/layout/MobileNav.vue';
import MoreDropdown from '~/components/layout/MoreDropdown.vue';

const EventsModule = namespace('events');
const HeaderModule = namespace('header');

@Component({
  components: {
    MenuDropdown,
    MobileNav,
    MoreDropdown,
    SecondaryNavBar,
  },
})
export default class Menu extends Vue {
  @EventsModule.State pages!: PageDto[];
  @EventsModule.Getter currentEvent!: EventDto;
  @EventsModule.Getter isLiveAppOpen!: boolean;
  @HeaderModule.State isHeaderVisible!: boolean;

  @Ref() readonly navItems!: HTMLElement[];

  @Watch('$i18n.locale', { immediate: true })
  onLocaleChange() {
    this.$nextTick(() => this.onResize());
  }

  isActive = false;
  scrollTop = 0;
  lastScrollTop = 0;
  hideOnScroll = false;
  showMenuBackground = false;
  navigationOverflowItems: NestedPage[] = [];
  throttledWatchScroll = throttle(this.updateMenu, 50);

  get nestedPages(): NestedPage[] {
    const pages = this.pages
      .filter(p => p.isVisible)
      .filter(p => p.type !== PageType.Folder || this.pages.some(sp => sp.parentId === p.id));
    return buildNestedPages(pages);
  }

  get rootClassNames() {
    return {
      '-dark': this.currentEvent.isDarkMode,
      '-w-bg': this.showMenuBackground || this.isActive,
      '-hidden': this.hideOnScroll && !this.isActive,
      '-sticky': !this.isLiveAppOpen,
    };
  }

  mounted() {
    window.addEventListener('scroll', this.throttledWatchScroll);
    window.addEventListener('resize', this.onResize);
    this.$nextTick(() => this.onResize());
  }

  beforeDestroy() {
    window.removeEventListener('scroll', this.throttledWatchScroll);
    window.removeEventListener('resize', this.onResize);
  }

  public onResize() {
    if (!process.client) return;
    requestAnimationFrame(() => {
      this.updateOverflow();
      this.updateMenu();
    });
  }

  public updateOverflow() {
    if (!this.$device.tablet) return;
    if (!this.navItems?.length) return;

    this.toggleItemVisibility(this.navItems, false);

    const itemDefaultTop = this.navItems[0].getBoundingClientRect().top;
    const overflowItems = this.navItems.filter(el => el.getBoundingClientRect().top > itemDefaultTop);

    this.toggleItemVisibility(overflowItems, true);

    if (!overflowItems) return;

    this.navigationOverflowItems = overflowItems
      .filter(({ dataset }) => this.nestedPages.some(item => item.id === dataset.id))
      .map(({ dataset }) => this.nestedPages.find((item: PageDto) => item.id === dataset.id)!);
  }

  public toggleItemVisibility(items: HTMLElement[], hide = true) {
    if (!this.$device.tablet) return;
    if (!items.length) return;

    for (const item of items) {
      const anchorTag: HTMLAnchorElement | null = item.querySelector('.nav__list-link');
      if (anchorTag) {
        item.setAttribute('aria-hidden', hide ? 'true' : 'false');
        anchorTag.tabIndex = hide ? -1 : 0;
      }
    }
  }

  public updateMenu() {
    this.scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    this.showMenuBackground = this.scrollTop > 30;

    // Hide nav on scroll down
    if (!this.isHeaderVisible && this.scrollTop > 30) {
      this.hideOnScroll = this.scrollTop > this.lastScrollTop;
      this.lastScrollTop = this.scrollTop;
    } else {
      this.hideOnScroll = false;
    }
  }

  public isNativeLink({ type }: PageDto) {
    // We need to force a fresh page load to clear the form's state
    const isConfirmationRoute = this.$route.path.endsWith('/confirmation');
    const isRegistration = this.$route.name?.includes('registration') && type === PageType.Registration;
    const isSubmission = this.$route.name?.includes('submission') && type === PageType.Submission;
    return isConfirmationRoute && (isRegistration || isSubmission);
  }
}
