import { Session } from '@opentok/client';

export enum OTSessionSignalEvent {
  RegisterSignal = 'ot-signal:register-signal',
  EmitPosterTracking = 'ot-signal:emit-poster-tracking',
  ReceivedPosterTracking = 'ot-signal:received-poster-tracking',
}

export enum SessionSignalType {
  PosterTracking = 'poster-tracking',
}

export type SessionSignal = Parameters<Session['signal']>[0] & { type: SessionSignalType };

export interface SignalHandlerOptions {
  emitEvent: OTSessionSignalEvent;
  receivedEvent: OTSessionSignalEvent;
  signalType: SessionSignalType;
  retryAfterReconnect?: boolean;
}
