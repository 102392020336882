export const conferencingActions = {
  setConferencingOpen({ commit }, isConversationOpen: boolean) {
    commit('setConferencingOpen', isConversationOpen);
  },
  setConversationCollapsed({ commit }, isConversationCollapsed: boolean) {
    commit('setConversationCollapsed', isConversationCollapsed);
  },
  setSettingsModalOpen({ commit }, isSettingsModalOpen: boolean) {
    commit('setSettingsModalOpen', isSettingsModalOpen);
  },
  setPreviewApproved({ commit }, isPreviewApproved: boolean) {
    commit('setPreviewApproved', isPreviewApproved);
  },
  setSessionSignalReady({ commit }, isSessionSignalReady: boolean) {
    commit('setSessionSignalReady', isSessionSignalReady);
  },
};
