
import { Vue, Component, Prop } from 'vue-property-decorator';
import { NuxtError } from '@nuxt/types';
import { normalizeQuery } from 'fourwaves-shared';

@Component({})
export default class Error extends Vue {
  @Prop({ required: true }) error!: NuxtError;

  // this is no layout, but the dynamic component rendered by nuxt's validate() method
  // https://nuxtjs.org/docs/directory-structure/layouts/

  created() {
    const redirect = this.error.statusCode === 401 ? this.getLoginUrl() : '';
    const query = normalizeQuery({ redirect });
    return this.$router.replace(
      this.localePath({
        name: `slug-errors-code`,
        params: { code: this.error.statusCode },
        query,
      }),
    );
  }
}
