export enum AmountType {
  Fixed = 'fixed',
  Percentage = 'percentage',
}

export interface CouponCodeDto {
  id: string;
  code: string;
  amount: number;
  amountType: AmountType;
  maxUsage: number | null;
  usageCount: number | null;
}
