
import { Vue, Component, Prop, Ref } from 'nuxt-property-decorator';
import { UserProfilePicture } from '../users';
import { FormEntryDto, UserProfileDto } from '../../services';
import { UserPresentationElement } from './types';
import ContactParticipantModal from './ContactParticipantModal.vue';

@Component({
  components: {
    UserProfilePicture,
    ContactParticipantModal,
  },
})
export default class ParticipantHeader extends Vue {
  @Prop({ required: true }) user!: UserProfileDto;
  @Prop({ required: true }) presentations!: UserPresentationElement[];
  @Prop({ required: true }) formEntry!: FormEntryDto;
  @Prop(Boolean) isOrganizer!: boolean;
  @Prop(Boolean) isParticipant!: boolean;
  @Ref() contactModal!: ContactParticipantModal;

  public openModal(event: PointerEvent) {
    this.contactModal?.triggerVisibility(event.currentTarget);
  }
}
