import { cloneDeep } from 'lodash-es';
import { TranslatableField } from './TranslatableField';
import { FormFieldDto } from './FormDto';

export enum PageScope {
  Everyone = 'everyone',
  Participants = 'participants',
}

export enum ContentSize {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
}

export enum ImageAlignment {
  Left = 'left',
  Right = 'right',
}

export enum SubmissionScope {
  Accepted = 'accepted',
  Submitted = 'submitted',
}

export enum PageType {
  Abstracts = 'abstracts',
  Home = 'home',
  Participants = 'participants',
  Registration = 'registration',
  Submission = 'submission',
  Content = 'content',
  Schedule = 'schedule',
  Folder = 'folder',
}

export enum ContentType {
  LegacyRichTextEditor = 'legacyrichtexteditor',
  RichTextEditor = 'richtexteditor',
  TextImage = 'textimage',
  Images = 'images',
  File = 'file',
  Speakers = 'speakers',
  Logos = 'logos',
  Accordion = 'accordion',
}

export interface ContentDto {
  id: string;
  type: ContentType;
  title: TranslatableField;
  description: TranslatableField;
  data: TranslatableField;
  order: number;
  size: ContentSize | null;
}

export interface TextContentDto {
  newData: {
    text: TranslatableField;
  };
  settings: {
    showButton: boolean;
  };
}

export interface ContentPageDto {
  id: string;
  contentBlocks: (ContentDto | TextContentDto)[];
}

export interface PageDto {
  id: string;
  name: TranslatableField;
  order: number;
  type: PageType;
  contentPage: ContentPageDto | null;
  lastUpdateDate: string | null;
  isVisible: boolean;
  scope: PageScope;
  submissionScope: SubmissionScope | null;
  formFields: FormFieldDto[];
  parentId: string | null;
}

export interface NestedPage extends PageDto {
  children: PageDto[];
}

export interface JsonFileContentData {
  id: string;
  originalFileName: string;
}

export interface JsonSpeakerContentData {
  id: string | null;
  name: string;
  affiliation: string;
  bio: string;
  fileUploadId: string;
  originalFileName: string;
}

export interface LogoContent {
  id: string;
  name: string;
  link: string;
  path: string;
}

export interface AccordionContent {
  id: string;
  title: string;
  description: string;
}

export interface TextImageModel {
  fileUpload: string;
  alignment: ImageAlignment;
}

export interface FileModel {
  fileUploadIds: string[];
}

export function getAvailableContentTypes(): ContentType[] {
  return Object.values(ContentType).filter(contentType => contentType !== ContentType.LegacyRichTextEditor);
}

export function buildNestedPages(pages: PageDto[]): NestedPage[] {
  const pagesCopy: NestedPage[] = cloneDeep(pages).map(page => ({ ...page, children: [] }));
  const nestedPages: NestedPage[] = [];

  for (const page of pagesCopy.filter(p => !p.parentId)) {
    page.children = pagesCopy.filter(p => p.parentId === page.id);
    nestedPages.push(page);
  }

  return nestedPages;
}

export function flattenNestedPages(pages: NestedPage[]): PageDto[] {
  const flattenPages: PageDto[] = [];

  for (const page of cloneDeep(pages)) {
    page.parentId = null;
    flattenPages.push(page);

    for (const child of page.children) {
      child.parentId = page.id;
      flattenPages.push(child);
    }
  }

  return flattenPages;
}
