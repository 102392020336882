
import { Vue, Component, Prop, Watch, Model, Emit } from 'nuxt-property-decorator';
import { union, debounce } from 'lodash-es';
import { InputOptionWithLink } from '../../../types';
import InputTextSearch from '../input/input-text/InputTextSearch.vue';
import InputCheckboxGroup from '../input/input-checkbox/InputCheckboxGroup.vue';
import InputCheckbox from '../input/input-checkbox/InputCheckbox.vue';

@Component({
  components: {
    InputTextSearch,
    InputCheckboxGroup,
    InputCheckbox,
  },
})
export default class CheckboxSelectionManager extends Vue {
  @Prop({ type: Array, required: true }) readonly options!: InputOptionWithLink[];
  @Prop({ type: String, required: true }) readonly groupId!: string;
  @Prop({ type: String, default: null }) readonly selectAllLabel!: string;
  @Prop(Boolean) readonly required!: boolean;
  @Prop(Boolean) readonly disabled!: boolean;

  @Model('change', { type: Array, default: Array }) readonly value!: string[];

  @Emit()
  change(_value: string[]) {}

  searchTerm = '';
  filteredOptions: InputOptionWithLink[] = [];

  get innerValue() {
    return this.value;
  }

  set innerValue(value: string[]) {
    this.change(value);
  }

  get selectAllLabelValue() {
    return this.selectAllLabel || this.$t('shared.global.select_all');
  }

  get isAllFilteredItemsSelected() {
    return this.filteredOptions.every(({ id }) => this.value.includes(String(id)));
  }

  get isAnyFilteredItemsSelected() {
    return this.filteredOptions.some(({ id }) => this.value.includes(String(id)));
  }

  set isAnyFilteredItemsSelected(isAnyFilteredItemsSelected: boolean) {
    let selectedItems = this.value;
    if (isAnyFilteredItemsSelected) {
      if (selectedItems.length) {
        selectedItems = union(
          selectedItems,
          this.filteredOptions.map(({ id }) => String(id)),
        );
      } else {
        selectedItems = this.filteredOptions.map(({ id }) => String(id));
      }
    } else {
      selectedItems = selectedItems.filter(
        item => !this.filteredOptions.map(({ id }) => String(id)).includes(String(item)),
      );
    }
    this.change(selectedItems);
  }

  @Watch('searchTerm', { immediate: true })
  onSearchTermChange(newValue: string, oldValue: string) {
    if (newValue === oldValue) return;
    this.filterOptions();
  }

  created() {
    this.filterOptions = debounce(this.filterOptions, 600);
  }

  public filterOptions() {
    this.filteredOptions = this.options.filter(({ label }) =>
      this.searchTerm ? String(label).toLowerCase().includes(this.searchTerm.toLowerCase()) : true,
    );
  }
}
