
import { Component, Vue, Prop, Ref } from 'nuxt-property-decorator';
import { TranslateResult } from '../../types/i18n';
import { isSuccess, isValidationError, MessageDto, MessageThreadType } from '../../services';
import Spinner from '../../components/Spinner.vue';
import { ActionButton } from '../action';

@Component({
  $_veeValidate: {
    validator: 'new',
  },
  components: {
    Spinner,
  },
})
export default class CommentInput extends Vue {
  @Prop({ required: true }) formEntryId!: string;
  @Prop({ default: () => undefined }) commentParent?: MessageDto;
  @Prop(Boolean) isCompact!: boolean;

  @Ref() cancelButton?: ActionButton;
  @Ref() submitButton?: ActionButton;

  messageText = '';
  isActive = false;
  collapsed = false;
  uniqueSpinnerId = String(Math.random());

  get placeholder(): TranslateResult {
    return this.commentParent
      ? this.$t('shared.qna.textarea_reply_placeholder')
      : this.$t('shared.qna.textarea_message_placeholder');
  }

  created() {
    if (this.commentParent) {
      this.collapsed = true;
    }
  }

  beforeDestroy() {
    document.removeEventListener('click', this.clickOutside);
  }

  cleanMessage() {
    this.messageText = '';
  }

  public async createMessage() {
    if (!(await this.$validator.validateAll())) return;

    this.$wait.start(this.uniqueSpinnerId);
    let thread = await this.$api.getThreadByToken(this.formEntryId, MessageThreadType.QuestionsAndAnswers);
    if (!thread) {
      const threadResponse = await this.$api.createObjectThread(
        MessageThreadType.QuestionsAndAnswers,
        this.formEntryId,
      );
      if (isSuccess(threadResponse)) thread = threadResponse;
    }

    if (thread) {
      const response = await this.$api.createMessage(
        thread.id,
        this.messageText,
        this.commentParent ? this.commentParent.id : undefined,
      );

      if (isSuccess(response)) {
        this.$toast.success(this.$t('shared.qna.success_message.added') as string);
        this.reset();
      } else if (isValidationError(response)) {
        this.feedErrorBag(response);
      }
    }

    this.$wait.end(this.uniqueSpinnerId);
  }

  public clickOutside(event) {
    if (!this.$el.contains(event.target)) {
      this.collapse();
    }
  }

  public expand() {
    if (this.commentParent) {
      this.collapsed = false;
      setTimeout(() => document.addEventListener('click', this.clickOutside), 50);
    }

    this.$nextTick(() => {
      if (!this.$refs.textarea) return;

      this.isActive = true;
      (this.$refs.textarea as HTMLElement).focus();
    });
  }

  public collapse() {
    this.isActive = false;
    this.$validator.reset();
    if (this.commentParent) {
      this.collapsed = true;
      document.removeEventListener('click', this.clickOutside);
    }
  }

  public reset() {
    this.cleanMessage();
    this.collapse();
    if (!this.$refs.textarea || !this.submitButton) return;
    (this.$refs.textarea as HTMLElement).blur();
    (this.submitButton?.$el as HTMLElement).blur();
    (this.cancelButton?.$el as HTMLElement)?.blur();

    document.removeEventListener('click', this.clickOutside);
  }

  public async handleCmdEnter(event: KeyboardEvent) {
    if ((event.metaKey || event.ctrlKey) && event.key === 'Enter') {
      await this.createMessage();
    }
  }

  public async handleEnter(event: KeyboardEvent) {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      await this.createMessage();
    }
  }
}
