export default {
  virtual: 'Virtual',
  live: 'Live now',
  presentation: {
    title: 'Presentations',
    show_all_in_presentations: 'View all in the presentations page',
    no_results: 'There are no presentations in this session.',
    presenting_now: 'Happening now',
    presented_by: 'Presented by ',
  },
  bookmark: 'Bookmark',
  unbookmark: 'Remove bookmark',
  bookmarked: 'Bookmarked',
  filter_bookmark: 'Bookmarked',
  search_placeholder: 'Search by session title, topic, or other',
  session_count: 'No sessions | {count} session | {count} sessions',
  tracks_filter: 'Tracks',
  minute_countdown: 'Starts in {count} minute | Starts in {count} minutes',
  less_than_a_minute: 'Starts in less than a minute',
  presentation_count_title: 'Featuring {count} presentation | Featuring {count} presentations',
  poster_count_title: 'Featuring {count} poster | Featuring {count} posters',
  cta_poster: 'Go to Poster Hall',
  cta_livestream: 'Join',
  watch_video: 'Watch video',
  prev_date_label: 'Show previous date',
  next_date_label: 'Show next date',
};
