
import { Component, Prop, Vue } from 'nuxt-property-decorator';
import FilterSearch from '../filters/FilterSearch.vue';
import FilterLayoutSwitch from '../filters/FilterLayoutSwitch.vue';
import FilterClear from '../filters/FilterClear.vue';
import Collapsible from './Collapsible.vue';

@Component({
  components: {
    Collapsible,
    FilterLayoutSwitch,
    FilterSearch,
    FilterClear,
  },
})
export default class FilterBar extends Vue {
  @Prop({ type: String, default: null }) readonly totalCount!: string | null;
  @Prop(Boolean) readonly areFiltersCollapsed!: boolean;
  @Prop(Boolean) readonly useLayoutSwitch!: boolean;
  @Prop(Boolean) readonly hasAppliedFilters!: boolean;
  @Prop({ type: String, default: null }) readonly searchPlaceholder!: string | null;

  showHiddenFilters = false;

  get searchPlaceholderWithFallback() {
    return this.searchPlaceholder || this.$t('shared.global.default_search_placeholder');
  }

  get hasMoreFilters() {
    return this.$slots['more-filters'] && this.$slots['more-filters'].length > 0;
  }

  get hasDesktopFilters() {
    return this.$slots['desktop-filters'] && this.$slots['desktop-filters'].length > 0;
  }

  get hasHiddenFilters() {
    return this.hasMoreFilters || (this.hasDesktopFilters && !this.$device.laptopLg);
  }
}
