
import { Component, Vue, namespace } from 'nuxt-property-decorator';
import { EventDto } from 'fourwaves-shared';
import WavePath from './WavePath.vue';

const EventsModule = namespace('events');

@Component({
  components: { WavePath },
})
export default class LiveBanner extends Vue {
  @EventsModule.State currentEvent!: EventDto;
  @EventsModule.Getter isLiveAppOpen!: boolean;
}
