
import { Prop, Component, Watch, Vue, namespace } from 'nuxt-property-decorator';
import { debounce, isEqual } from 'lodash-es';
import wheel from 'wheel';
import { PanZoom, Transform } from 'panzoom';
import { createThrottledHandler, EventHandler } from '../../helpers';
import {
  ConferencingEvent,
  OTSessionSignalEvent,
  SessionSignal,
  SessionSignalType,
  SignalHandlerOptions,
} from '../../types/events';
import Spinner from '../Spinner.vue';

type PointerCoordinates = { cx: string; cy: string };

interface NonStandardMouseEvent extends MouseEvent {
  layerX: number;
  layerY: number;
}

interface StreamSignalPayload {
  isActive: boolean;
  transform?: Transform;
  pointer?: PointerCoordinates | null;
  presenterName?: string | null;
}

const ConferencingModule = namespace('conferencing');

const basePointerSize = 6;
const baseShadowSize = 4;

@Component({
  components: {
    Spinner,
  },
})
export default class PresentationPoster extends Vue {
  @ConferencingModule.State isConversationOpen!: boolean;
  @ConferencingModule.State isSessionSignalReady!: boolean;

  @Prop({ required: true }) readonly posterUrl!: string;
  @Prop({ required: true, type: Boolean }) readonly isPresenter!: boolean;

  $refs!: {
    container: HTMLElement;
    posterViewbox: SVGElement;
    posterLoader: HTMLImageElement;
  };

  panZoomInstance: PanZoom | null = null;
  isTrackingEnabled = false;
  isFollowingEnabled = false;
  isReceivingTracking = false;
  isPointerOutside = true;
  currentPresenterName: string | null = null;
  initialTransform: Transform = { scale: 1, x: 0, y: 0 };
  currentTransform: Transform = { scale: 1, x: 0, y: 0 };
  lastReceivedTransform: Transform | null = null;
  currentTrackingPosition: Transform = { scale: 1, x: 0, y: 0 };
  currentPointerPosition: PointerCoordinates | null = null;
  imageRatio: [width: number, height: number] | null = null;
  panZoomOptions = { maxZoom: 8, minZoom: 0.2, bounds: false };
  isPosterReady = false;
  pointerCoordinatesBuffer: [x: number, y: number] | null = null;
  idleSignalInterval: ReturnType<typeof setTimeout> | null = null;

  onViewerEvent = () => {};
  onPanZoom: (event: PanZoom) => void = () => {};
  onMouseMove: (event: NonStandardMouseEvent) => void = () => {};

  get userFullName(): string | null {
    if (!this.$auth.loggedIn) return null;
    return `${this.$auth.user.firstName} ${this.$auth.user.lastName}`;
  }

  get canEmitTracking(): boolean {
    return this.isPresenter && this.isTrackingEnabled;
  }

  get imageProps(): { width: string; height: string; x: string; y: string } | null {
    if (!this.imageRatio) return null;
    const [width, height] = this.imageRatio.map(value => `${value * 100}%`);
    const [x, y] = this.imageRatio.map(value => `${(1 - value) * 50}%`);
    return { width, height, x, y };
  }

  get laserProps(): (PointerCoordinates & { r: number }) | null {
    if (!this.currentPointerPosition) return null;

    return {
      ...this.currentPointerPosition,
      r: basePointerSize * (1 / this.currentTransform.scale),
    };
  }

  get laserShadowSize(): number {
    if (!this.currentPointerPosition) return 0;
    return baseShadowSize * (1 / this.currentTransform.scale);
  }

  get streamSignalPayload(): StreamSignalPayload | null {
    if (!this.canEmitTracking) return null;

    return {
      isActive: true,
      transform: this.currentTrackingPosition,
      pointer: this.currentPointerPosition,
      presenterName: this.userFullName,
    };
  }

  @Watch('isReceivingTracking')
  onActiveTrackingStateChange(isReceivingTracking: boolean, wasReceivingTracking: boolean) {
    if (isReceivingTracking === wasReceivingTracking) return;
    this.isFollowingEnabled = isReceivingTracking;
  }

  @Watch('isFollowingEnabled')
  onFollowingStateChange(isFollowingEnabled: boolean, wasFollowingEnabled: boolean) {
    if (!isFollowingEnabled || wasFollowingEnabled || !this.lastReceivedTransform) return;
    this.panZoomTo(this.lastReceivedTransform);
  }

  @Watch('streamSignalPayload')
  onStreamSignalPayloadChange(payload: StreamSignalPayload | null) {
    const signalPayload = payload || { isActive: false, pointer: null };

    const signal: SessionSignal = {
      type: SessionSignalType.PosterTracking,
      data: JSON.stringify(signalPayload),
    };

    this.$nuxt.$emit(OTSessionSignalEvent.EmitPosterTracking, signal);

    if (this.idleSignalInterval) {
      clearInterval(this.idleSignalInterval);
      this.idleSignalInterval = null;
    }

    if (signalPayload.isActive) {
      this.idleSignalInterval = setInterval(
        () => this.$nuxt.$emit(OTSessionSignalEvent.EmitPosterTracking, signal),
        1000,
      );
    }
  }

  @Watch('isSessionSignalReady', { immediate: true })
  async onSessionSignalStateChange(isSessionSignalReady: boolean) {
    if (!isSessionSignalReady) {
      this.isTrackingEnabled = false;
      this.isFollowingEnabled = false;
      this.isReceivingTracking = false;
      this.currentPresenterName = null;
      return;
    }

    const signalConfig: SignalHandlerOptions = {
      emitEvent: OTSessionSignalEvent.EmitPosterTracking,
      receivedEvent: OTSessionSignalEvent.ReceivedPosterTracking,
      signalType: SessionSignalType.PosterTracking,
    };

    await this.$nextTick();
    this.$nuxt.$emit(OTSessionSignalEvent.RegisterSignal, signalConfig);
  }

  async mounted() {
    await this.$nextTick();
    if (this.isPresenter) this.createPresenterEventListeners();
    else this.createViewerEventListeners();
  }

  beforeDestroy() {
    this.panZoomInstance = null;
    this.$nuxt.$off(OTSessionSignalEvent.ReceivedPosterTracking);
    this.$nuxt.$off(ConferencingEvent.PresenterAttendanceChanged);
    wheel.removeWheelListener(this.$refs.container, this.onViewerEvent);
    if (this.idleSignalInterval) {
      clearInterval(this.idleSignalInterval);
      this.idleSignalInterval = null;
    }
  }

  public createViewerEventListeners() {
    this.$nuxt.$on(OTSessionSignalEvent.ReceivedPosterTracking, this.onTrackingReceived);

    this.$nuxt.$on(ConferencingEvent.PresenterAttendanceChanged, (isSomeonePresenter: boolean) => {
      if (isSomeonePresenter) return;
      this.isReceivingTracking = false;
      this.isFollowingEnabled = false;
      this.currentPointerPosition = null;
      this.currentPresenterName = null;
    });

    this.onViewerEvent = () => {
      if (this.isFollowingEnabled) this.isFollowingEnabled = false;
    };

    wheel.addWheelListener(this.$refs.container, this.onViewerEvent);
  }

  public createPresenterEventListeners() {
    this.onPanZoom = debounce<EventHandler<PanZoom>>(() => {
      this.currentTrackingPosition = {
        ...this.getRelativeCenter(),
        scale: this.currentTransform.scale - this.initialTransform.scale,
      };
    }, 175);

    this.onMouseMove = createThrottledHandler<NonStandardMouseEvent>(event => {
      if (!this.isTrackingEnabled || event.buttons || this.isPointerOutside) return;
      this.currentPointerPosition = this.getRelativeCoordinates(event);
    });
  }

  public async onPanZoomInit(panZoom: PanZoom) {
    this.panZoomInstance = panZoom;
    this.currentTransform = panZoom.getTransform();
    panZoom.zoomAbs(window.innerWidth * 0.5, window.innerHeight * 0.3, this.currentTransform.scale * 0.85);
    await this.$nextTick();
    this.initialTransform = { ...panZoom.getTransform() };
  }

  public onMouseEnter(event: NonStandardMouseEvent) {
    if (!this.canEmitTracking) return;
    this.isPointerOutside = false;
    this.currentPointerPosition = this.getRelativeCoordinates(event);
  }

  public onMouseLeave() {
    if (!this.canEmitTracking) return;
    this.isPointerOutside = true;
    if (this.currentPointerPosition) this.currentPointerPosition = null;
    if (this.pointerCoordinatesBuffer) this.pointerCoordinatesBuffer = null;
  }

  public onTrackingReceived({ isActive, transform, pointer = null, presenterName = null }: StreamSignalPayload) {
    const hasTransformChanged = !isEqual(transform, this.lastReceivedTransform);
    this.lastReceivedTransform = transform || null;
    this.isReceivingTracking = isActive;
    this.currentPointerPosition = pointer;
    this.currentPresenterName = presenterName;
    if (this.isFollowingEnabled && transform && hasTransformChanged) this.panZoomTo(transform);
  }

  public panZoomTo({ x, y, scale }: Transform) {
    if (!this.panZoomInstance || !this.$refs.container) return;
    const { clientWidth, clientHeight } = this.$refs.container;
    const aspectRatio = clientWidth / clientHeight;
    const aspectRatioMultiplier = Math.min(aspectRatio + (1 - aspectRatio) * 0.5, 1);
    const toScale = (this.initialTransform.scale + scale) * aspectRatioMultiplier;
    if (toScale === this.currentTransform.scale) return this.positionOnCenter(x, y, toScale);
    this.panZoomInstance.zoomAbs(window.innerWidth * 0.5, window.innerHeight * 0.5, toScale);
    this.$once('viewbox-transition-end', () => this.positionOnCenter(x, y, toScale));
  }

  public manualZoom(amount: number) {
    if (!this.panZoomInstance) return;
    if (this.isFollowingEnabled) this.isFollowingEnabled = false;
    const { clientWidth, clientHeight } = this.$refs.container;
    this.panZoomInstance.smoothZoom(clientWidth * 0.5, clientHeight * 0.5, 1 + 0.2 * amount);
  }

  public async positionOnCenter(x: number, y: number, scale: number) {
    await this.$nextTick();
    if (!this.panZoomInstance || !this.$refs.posterViewbox) return;
    const currentCenter = this.getRelativeCenter();
    const { clientWidth, clientHeight } = this.$refs.posterViewbox;
    const moveByX = clientWidth * (currentCenter.x - x) * scale;
    const moveByY = clientHeight * (currentCenter.y - y) * scale;
    if (moveByX || moveByY) this.panZoomInstance.moveBy(moveByX, moveByY, false);
  }

  public getRelativeCenter(): { x: number; y: number } {
    const { container, posterViewbox = document.body } = this.$refs;
    if (!container) return { x: 0.5, y: 0.5 };
    const { top, right, bottom, left } = posterViewbox.getBoundingClientRect();
    const { clientWidth, clientHeight } = container;
    const screenMidX = clientWidth * 0.5;
    const screenMidY = clientHeight * 0.5;
    const targetWidth = right - left;
    const targetHeight = bottom - top;

    return {
      x: Math.floor(((screenMidX - left) / targetWidth) * 1e5) / 1e5,
      y: Math.floor(((screenMidY - top) / targetHeight) * 1e5) / 1e5,
    };
  }

  public getRelativeCoordinates(event: NonStandardMouseEvent): PointerCoordinates | null {
    if (!this.$refs.posterViewbox) return { cx: '50%', cy: '50%' };
    if (this.$browserDetect.isFirefox) return this.getRelativeCoordinatesFirefox(event);
    const { offsetX, offsetY } = event;
    const { clientWidth, clientHeight } = this.$refs.posterViewbox;
    return { cx: `${(offsetX / clientWidth) * 100}%`, cy: `${(offsetY / clientHeight) * 100}%` };
  }

  public getRelativeCoordinatesFirefox({ layerX, layerY }: NonStandardMouseEvent): PointerCoordinates | null {
    const [lastX, lastY] = this.pointerCoordinatesBuffer || [layerX, layerY];
    const isLargeDiff = Math.abs(layerX - lastX) > 100 || Math.abs(layerY - lastY) > 100;
    if (layerX + layerY < 50 && isLargeDiff) return this.currentPointerPosition;
    this.pointerCoordinatesBuffer = [layerX, layerY];
    const { clientWidth, clientHeight } = this.$refs.posterViewbox;
    return { cx: `${(layerX / clientWidth) * 100}%`, cy: `${(layerY / clientHeight) * 100}%` };
  }

  public onPosterLoaded() {
    const { naturalWidth, naturalHeight } = this.$refs.posterLoader || {};
    const width = naturalWidth || 1;
    const height = naturalHeight || 1;
    const ratio = Math.min(width, height) / Math.max(width, height);
    this.imageRatio = width < height ? [ratio, 1] : [1, ratio];
    this.isPosterReady = true;
  }
}
