// https://v2.vuejs.org/v2/guide/typescript.html#Augmenting-Types-for-Use-with-Plugins
export type PossibleDate = Date | string | null;

export interface DateMixins {
  toDateRange(startDate: string | null, endDate?: string | null): string;
  showDate(this: Vue, date?: PossibleDate): string;
  showShortDate(this: Vue, date?: PossibleDate): string;
  toTimeRange(this: Vue, start: PossibleDate, end: PossibleDate): string;
  showUserFriendlyDateTime(this: Vue, date?: PossibleDate, withTime?: boolean): string;
  showAvailabilityDates(this: Vue, startDate: PossibleDate, endDate: PossibleDate): string;
  showDateRange(
    this: Vue,
    startDate?: PossibleDate,
    endDate?: PossibleDate,
    withTime?: boolean,
    withTimeLocale?: string,
  ): string;
}

export const dateMixins: DateMixins = {
  toDateRange(startDate: string | null, endDate?: string | null): string {
    if (!startDate && !endDate) return '';
    const term = this.$moment.locale() === 'fr' ? 'au' : 'to';
    const start = this.$moment(new Date(startDate as string));
    const end = this.$moment(new Date(endDate as string));

    // when no endDate or the same day of the same month
    if (!endDate || (start.month() === end.month() && start.day() === end.day())) {
      return this.$moment.locale() === 'fr' ? `${start.format('Do MMMM YYYY')}` : `${start.format('MMMM D, YYYY')}`;
    }

    // when year differs
    if (start.year() !== end.year()) {
      return this.$moment.locale() === 'fr'
        ? `${start.format('Do MMMM YYYY')} ${term} ${end.format('Do MMMM YYYY')}`
        : `${start.format('MMMM D YYYY')} ${term} ${end.format('MMMM D, YYYY')}`;
    }

    // when same month
    if (start.month() === end.month()) {
      return this.$moment.locale() === 'fr'
        ? `${start.format('Do')} ${term} ${end.format('Do MMMM YYYY')}`
        : `${start.format('MMMM D')}-${end.format('D, YYYY')}`;
    }

    return this.$moment.locale() === 'fr'
      ? `${start.format('Do MMMM')} ${term} ${end.format('Do MMMM YYYY')}`
      : `${start.format('MMMM D')} ${term} ${end.format('MMMM D, YYYY')}`;
  },
  showDate(date) {
    return date ? this.$moment(date).format('l, LT') : '---';
  },
  showShortDate(date) {
    return date ? this.$moment(date).format('L') : '---';
  },
  showDateRange(startDate, endDate, withTime = true, withTimeLocale = 'global.until') {
    return `${this.showUserFriendlyDateTime(startDate, withTime)} ${this.$t(
      withTimeLocale,
    )} ${this.showUserFriendlyDateTime(endDate, withTime)}`;
  },
  toTimeRange(start, end) {
    if (!start || !end) return '';
    const startMoment = this.$moment(start);
    const endMoment = this.$moment(end);
    return `${startMoment.isValid() ? startMoment.format('LT') : '---'} - ${
      endMoment.isValid() ? endMoment.format('LT') : '---'
    }`;
  },
  showUserFriendlyDateTime(date, withTime = true) {
    if (!date) return '---';
    const dateObject = date instanceof Date ? date : new Date(date);
    return this.$moment(dateObject).format(withTime ? 'LL - LT' : 'LL');
  },
  showAvailabilityDates(startDate, endDate) {
    const start = startDate ? this.$moment(startDate).format('LL - LT') : null;
    const end = endDate ? this.$moment(endDate).format('LL - LT') : null;

    if (!start && !end) {
      return '---';
    }

    if (start && end) {
      const from = this.$moment.locale() === 'fr' ? 'Du' : 'From';
      const to = this.$moment.locale() === 'fr' ? 'au' : 'to';
      return `${from} ${start} ${to} ${end}`;
    }

    if (start) {
      const from = this.$moment.locale() === 'fr' ? 'À partir du' : 'From';
      return `${from} ${start}`;
    }

    const until = this.$moment.locale() === 'fr' ? "Jusqu'au" : 'Until';
    return `${until} ${end}`;
  },
};
