
import { Component, Vue, Prop, Ref } from 'nuxt-property-decorator';
import InputText from '../design-system/input/input-text/InputText.vue';
import InputTextArea from '../design-system/input/input-text/InputTextArea.vue';
import FeaturedIcon from '../design-system/molecules/FeaturedIcon.vue';
import Modal from '../modals/Modal.vue';
import { isValidationError, isSuccess, FormEntryDto } from '../../services';

@Component({
  $_veeValidate: {
    validator: 'new',
  },
  components: { InputText, InputTextArea, FeaturedIcon, Modal },
})
export default class ContactParticipantModal extends Vue {
  @Prop({ required: true }) participant!: FormEntryDto;
  @Ref() modal?: Modal;

  subject = '';
  body = '';

  get isWaiting() {
    return this.$wait.is('send');
  }

  public triggerVisibility(eventCurrentTarget?: HTMLButtonElement | EventTarget | null) {
    this.modal?.triggerVisibility(eventCurrentTarget);
  }

  public async sendEmail() {
    if (!(await this.$validator.validateAll())) return;

    this.$wait.start('send');
    const response = await this.$api.contactParticipant(this.participant.id, this.subject, this.body);
    if (isSuccess(response)) {
      this.$toast.success(this.$t('shared.participants.modal_success_message') as string);
      this.modal?.triggerVisibility();
      this.subject = '';
      this.body = '';
    } else if (isValidationError(response)) {
      this.feedErrorBag(response);
    }
    this.$wait.end('send');
  }
}
